import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import{ GlobalVariables } from '../../../_common/global-variables';

@Component({
  selector: 'kt-teleasistencia',
  templateUrl: './teleasistencia.component.html',
  styleUrls: ['./teleasistencia.component.scss']
})
export class TeleasistenciaComponent implements OnInit {

  txt = {};

  constructor(
		private cdr: ChangeDetectorRef) {
    this.txt = GlobalVariables.textos;
    if(Object.keys(this.txt).length == 0) {
      setTimeout(function() {
        this.txt = GlobalVariables.textos;
        this.cdr.detectChanges();
        if(Object.keys(this.txt).length == 0) {
          setTimeout(function() {
          this.txt = GlobalVariables.textos;
          this.cdr.detectChanges();
          }.bind(this), 2000);
        }
      }.bind(this), 3000);
    }   
   }

  ngOnInit() {
  }

}
