import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation, ViewChild, NgZone } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';

import { first } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { DateTimeAdapter } from 'ng-pick-datetime';

import { UserService, AuthenticationService, StateService, CountryService, TarifaService, SuscriptionService, PaymentService, ServicioService, NotificacionService } from '../../../_services';

import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

import * as moment from 'moment';
declare var Stripe: any;

@Component({
  selector: 'kt-licitacion-editar',
  templateUrl: './licitacion-editar.component.html',
  styleUrls: ['./licitacion-editar.component.scss']
})
export class LicitacionEditarComponent implements OnInit {

  @ViewChild('confirmationEditSwal', {static: false}) private confirmationEditSwal: SwalComponent
  @ViewChild('errorLicitacionSwal', {static: false}) private errorLicitacionSwal: SwalComponent

  currentUser: any;
  loading = false;

  servicios = [];
  estados = [];
  estadosAll = [];
  estadosHash = {};

  paises = [];
  paisesHash = {};

  serviciosOtros = [
    {codigo: "otros_esquela-prensa", nombre: "Esquela en prensa", valor: false},
    {codigo: "otros_esquelas-murales", nombre: "Esquelas murales", valor: false},
    {codigo: "otros_esquela-radio", nombre: "Esquelas en radio", valor: false},
    {codigo: "otros_recordatorios", nombre: "Recordatorios", valor: false},
    {codigo: "otros_libro-firmas", nombre: "Libro de firmas", valor: false},
    {codigo: "otros_elementos-emocionales", nombre: "Elementos emocionales (Colgantes o joyas)", valor: false},
    {codigo: "otros_acomp-musical", nombre: "Acompañamiento musical", valor: false},
    {codigo: "otros_lapidas", nombre: "Lápidas granito o mármol", valor: false},
  ]
  
  private unsubscribe: Subject<any>; 

  constructor(private router: Router,
    private authenticationService: AuthenticationService,
    private fb: FormBuilder,
    private userService: UserService,
    private cdr: ChangeDetectorRef,
    private stateService: StateService,
    private ngZone: NgZone,
    private servicioService: ServicioService,
    private notificacionService: NotificacionService,
    private countryService: CountryService,
    private route: ActivatedRoute,
    dateTimeAdapter: DateTimeAdapter<any>) { 
      this.unsubscribe = new Subject();
      dateTimeAdapter.setLocale('es-ES');
    }

  editID = null;
  row = null;

  ngOnInit() {
    this.currentUser = this.authenticationService.currentUserValue;
    this.route.paramMap.subscribe(params => {
      this.editID = params.get("id");
      this.loadPaises();
    })
  }

  scroll(id) {
    let el = document.getElementById(id);
    el.scrollIntoView({behavior:"smooth"});
  }

  loadLicitacion() {
    var loadedLicitacion = function(resData){
      this.row = resData;

      if(this.row.creador.id != this.currentUser.id || (!this.row.abierta && !this.row.validada) || (!this.row.abierta && this.row.validada && this.row.licitacionElegida)) {
        this.ngZone.run(() => this.router.navigate(['/mislicitaciones'])).then();
        this.cdr.detectChanges();
        return;
      } else if(this.row.ultimaLicitacion){
        this.errorLicitacionSwal.fire();
        this.ngZone.run(() => this.router.navigate(['/mislicitaciones'])).then();
        this.cdr.detectChanges();
        return;
      } 

      this.referenciaCliente = this.row.referenciaCliente;
      if(this.row.pujas) this.tipo = "CON"
      else this.tipo = "SIN";

      this.fechaInicio = this.row.startDate;
      this.fechaFin = this.row.endDate;

      if(this.row.tipo == "TRASLADO") {
        this.traslado = true;
        this.tipoTraslado = this.row.tipoTraslado;
        this.km = this.row.km;
        this.importeTraslado = this.row.importeSalida;
        this.comentariosTraslado = this.row.comentarios;

        this.paisDestino = this.row.paisDestino.id;
        this.provinciaDestino = this.row.provinciaDestino.id;
        this.countryChangedDestinoNew();
        this.poblacionDestino = this.row.poblacionDestino;
      } else {
        this.traslado = false;
        this.serviciosMostrar = true;
        this.tipoTraslado = null;
        this.km = null;
        this.importeTraslado = null;
        this.comentariosTraslado = null;
        this.provinciaDestino = null;
        this.poblacionDestino = null;

        var completo = false;
        
        for(let serv of this.servicios) {
          serv.error = {importeSalida: false};
          serv.comentarios = null;
          serv.importeSalida = null;
          serv.val = false;
          for(let servLic of this.row.servicios) {
            if(serv.id == servLic.id) {
              serv.comentarios = servLic.comentarios;
              serv.importeSalida = servLic.importeSalida;
              serv.val = true;
              if(serv.abreviatura == "SVFUN") {
                completo = true;
                if(servLic.tipo && servLic.tipo == "CREMA") serv.crema = true
                else if(servLic.tipo && servLic.tipo == "DESTEMP") serv.destemp = true;
                serv.tipo = servLic.tipo;
              } else if(serv.abreviatura == "OTROS") {
                if(servLic.detalleOtros) this.serviciosOtros = servLic.detalleOtros;
              }
              break;
            }
          }          
        }

        if(completo) {
          for(let serv of this.servicios) {
            if(serv.abreviatura == "FLOR" || serv.abreviatura == "TANAT" || serv.abreviatura == "COCHE" || serv.abreviatura == "GEST" || serv.abreviatura == "CREMA" || serv.abreviatura == "DESTEMP") {
              serv.error = {importeSalida: false, comentarios: false};
              serv.comentarios = null;
              serv.importeSalida = null;
              serv.val = false;
              serv.completo = true;
            }
          }
        }
      }

      this.paisOrigen = this.row.paisOrigen.id;
      this.provinciaOrigen = this.row.provinciaOrigen.id;
      this.countryChangedOrigenNew();
      this.poblacionOrigen = this.row.poblacionOrigen;

      this.cdr.detectChanges();
    }
    self.io.socket.get('/licitacion/' + this.editID, loadedLicitacion.bind(this));
  }

  loadProvincias(){
		this.stateService.getAll()
	    .subscribe(estados => {
        this.estados = estados;
        for(let estado of estados) {
          this.estadosHash[estado.id+""] = estado;
        }
        this.loadServicios();
        this.cdr.detectChanges();
      });
  }

  loadPaises() {
		this.countryService.getAll()
	    .subscribe(paises => {
			this.paises = paises;
      for(let pais of paises) {
        this.paisesHash[pais.id+""] = pais;
      }
      this.loadProvincias();
      this.cdr.detectChanges();
		});
	}

  loadServicios() {
		this.servicioService.getAll()
	    .subscribe(servicios => {
			this.servicios = servicios;
      for(let serv of this.servicios) {
        serv.error = {importeSalida: false, comentarios: false, importeNegativo: false, importeIncorrecto: false, tipoCompleto: false};
        serv.comentarios = null;
        serv.importeSalida = null;
        serv.val = false;
        serv.detalleMostrar = false;
        serv.detalleMostrarCompleto = false;
        serv.completo = false;
        serv.crema = false;
        serv.destemp = false;
        serv.detalleCompleto = null;
        if(serv.abreviatura == "CREMA") {
          this.servicios[0].cremaDetalle = serv;
        } else if(serv.abreviatura == "DESTEMP") {
          this.servicios[0].destempDetalle = serv;
        }
      }
      this.loadLicitacion();
      this.cdr.detectChanges();
		});
	}

  referenciaCliente = null;
  tipo = null;
  traslado = false;
  serviciosMostrar = false;

  tipoTraslado = null;
  km = null;
  importeTraslado = null;
  comentariosTraslado = null;
  detalleTraslado = false;

  fechaInicio = null;
  fechaFin = null;
  error = {referencia: false, tipo: false, origen: false, destino: false, tipoTraslado: false, km: false, importeTraslado: false, importeNegativo: false, importeIncorrecto: false, comentariosTraslado: false, fechaInicio: false, fechaFin: false, fechaOrden: false, fechaHoy: false, servicios: false};

  paisOrigen = null;
  paisDestino = null;

  provinciaOrigen = null;
  poblacionOrigen = null;

  provinciaDestino = null;
  poblacionDestino = null;

  estadosOrigenNew = []
  estadosDestinoNew = [];

  countryChangedOrigenNew() {
    this.estadosOrigenNew = [];
    for(var i = 0; i < this.estados.length; i++) {
      if(this.estados[i].countryId == this.paisOrigen) this.estadosOrigenNew.push(this.estados[i]);
    }
    this.cdr.detectChanges();
  }

  countryChangedDestinoNew() {
    this.estadosDestinoNew = [];
    for(var i = 0; i < this.estados.length; i++) {
      if(this.estados[i].countryId == this.paisDestino) this.estadosDestinoNew.push(this.estados[i]);
    }
  }

  detectChanges(){
    this.cdr.detectChanges();
  }

  serviciosCompletados = 0;

  submitLicit() {
    //COMPROBAR ERRORES
    var errors = false;
    //error = {referencia: false, origen: false, fechaInicio: false, fechaFin: false, fechaOrden: false, fechaHoy: false, servicios: false};

    if(!this.referenciaCliente || this.referenciaCliente == ""){
      this.error.referencia = true;
      if(!errors) {
        KTUtil.scrollTop();
      }
      errors = true;
    } else {
      this.error.referencia = false;
    }
    if(!this.tipo){
      this.error.tipo = true;
      if(!errors) {
        KTUtil.scrollTop();
      }
      errors = true;
    } else {
      this.error.tipo = false;
    }
    if(!this.fechaInicio){
      this.error.fechaInicio = true;
      if(!errors) {
        KTUtil.scrollTop();
      }
      errors = true;
    } else {
      this.error.fechaInicio = false;
    }
    if(!this.fechaFin){
      this.error.fechaFin = true;
      if(!errors) {
        KTUtil.scrollTop();
      }
      errors = true;
    } else {
      this.error.fechaFin = false;
    }
    if(!this.paisOrigen || !this.provinciaOrigen || !this.poblacionOrigen){
      this.error.origen = true;
      if(!errors) {
        this.scroll("traslado");
      }
      errors = true;
    } else {
      this.error.origen = false;
    }
    if(this.traslado) {
      if(!this.paisDestino || !this.provinciaDestino || !this.poblacionDestino){
        this.error.destino = true;
        if(!errors) {
          this.scroll("traslado");
        }
        errors = true;
      } else {
        this.error.destino = false;
      }
    }
    if(!this.serviciosMostrar && !this.traslado) {
      this.error.servicios = true;
      if(!errors) {
        KTUtil.scrollTop();
      }
      errors = true;
    } else {
      this.error.servicios = false;
    }
    var servicioSel = false;
    for(var i = 0; i < this.servicios.length; i++){
      if(this.servicios[i].val) {
        servicioSel = true;
        break;
      }
    }
    if(!servicioSel && !this.traslado) {
      this.error.servicios = true;
      if(!errors) {
        this.scroll("servicios");
      }
      errors = true;
    } else {
      this.error.servicios = false;
    }
    if(moment(this.fechaInicio).diff() < 0){
      this.fechaInicio = new Date();
    }
    if(moment(this.fechaFin).diff(this.fechaInicio) < 0){
      this.error.fechaOrden = true;
      if(!errors) {
        KTUtil.scrollTop();
      }
      errors = true;
    } else {
      this.error.fechaOrden = false;
    }

    //COMPROBAR SERVICIOS
    if(this.traslado){
      if(!this.tipoTraslado){
        this.error.tipoTraslado = true;
        errors = true;
      } else {
        this.error.tipoTraslado = false;
      }
      if(!this.importeTraslado){
        this.error.importeTraslado = true;
        errors = true;
      } else {
        this.error.importeTraslado = false;
      }
      if((this.importeTraslado || this.importeTraslado == 0) && this.importeTraslado <= 0) {
        this.error.importeNegativo = true;
        errors = true;
      } else {
        this.error.importeNegativo = false;
      }
      if(this.importeTraslado && this.importeTraslado > 99999) {
        this.error.importeIncorrecto = true;
        errors = true;
      } else {
        this.error.importeIncorrecto = false;
      }
    }
    for(var i = 0; i < this.servicios.length; i++){
      if(this.servicios[i].val) {
        /*if(this.servicios[i].abreviatura == "OTROS") {
          if(this.servicios[i].comentarios && this.servicios[i].comentarios != "") {
            this.servicios[i].error.comentarios = false;
          } else {
            this.servicios[i].error.comentarios = true;
            errors = true;
          }
        } else */if(this.servicios[i].abreviatura == "SVFUN") {
          if(this.servicios[i].tipo) {
            this.servicios[i].error.tipoCompleto = false;
          } else {
            this.servicios[i].error.tipoCompleto = true;
            errors = true;
          }
        }
        if(!this.servicios[i].importeSalida){
          this.servicios[i].error.importeSalida = true;
          errors = true;
        } else {
          this.servicios[i].error.importeSalida = false;
        }
        if((this.servicios[i].importeSalida || this.servicios[i].importeSalida == 0) && this.servicios[i].importeSalida <= 0) {
          this.servicios[i].error.importeNegativo = true;
          errors = true;
        } else {
          this.servicios[i].error.importeNegativo = false;
        }
        if(this.servicios[i].importeSalida && this.servicios[i].importeSalida > 99999) {
          this.servicios[i].error.importeIncorrecto = true;
          errors = true;
        } else {
          this.servicios[i].error.importeIncorrecto = false;
        }
      }
    }
    if(errors) {
      this.cdr.detectChanges();
      return;
    }

    var loadedLicitacion = function(resData){
      //this.row = resData;

      //comprobamos que no hayan llegado licitaciones
      if(resData.ultimaLicitacion){ 
        this.errorLicitacionSwal.fire();
        this.ngZone.run(() => this.router.navigate(['/mislicitaciones'])).then();
        this.cdr.detectChanges();
        return;
      }

      this.loading = true;
      this.cdr.detectChanges();

      if(moment(this.fechaInicio).diff() < 0){
        this.fechaInicio = new Date();
      }

      if(this.tipo == "CON") {
        this.tipo = true;
      } else {
        this.tipo = false;
      }
      
      //GUARDAR
      if(this.traslado) {
        var licit = {
          referenciaCliente: this.referenciaCliente || "",
          startDate: this.fechaInicio,
          endDate: this.fechaFin,
          abierta: true,
          validada: false,
          pujas: this.tipo,
          importeSalida: this.importeTraslado || 0,
          poblacionOrigen: this.poblacionOrigen,
          provinciaOrigen: this.provinciaOrigen,
          paisOrigen: this.paisOrigen,
          comentarios: this.comentariosTraslado || "",
          poblacionDestino: this.poblacionDestino,
          provinciaDestino: this.provinciaDestino,
          paisDestino: this.paisDestino,
          km: this.km,
          tipoTraslado: this.tipoTraslado
        }
        var completado = function (resData){
          this.confirmationEditSwal.fire();
          this.ngZone.run(() => this.router.navigate(['/mislicitaciones'])).then();
          this.cdr.detectChanges();
        }
        self.io.socket.patch('/licitacion/' + this.row.id, licit, completado.bind(this));
      } else {
        var total = 0;
        var servicios = [];
        for(var i = 0; i < this.servicios.length; i++){
          if(this.servicios[i].val) {
            total += this.servicios[i].importeSalida;
            delete this.servicios[i].val;
            delete this.servicios[i].error;
            delete this.servicios[i].destemp;
            delete this.servicios[i].crema;
            delete this.servicios[i].cremaDetalle;
            delete this.servicios[i].destempDetalle;
            if(this.servicios[i].abreviatura == "OTROS") {
              this.servicios[i].detalleOtros = this.serviciosOtros;
            }
            servicios.push(this.servicios[i]);
          }
        }
        var poblacion = this.poblacionOrigen;
        var provincia = this.provinciaOrigen;
        var pais = this.paisOrigen;

        var licit2 = {
          referenciaCliente: this.referenciaCliente || "",
          startDate: this.fechaInicio,
          endDate: this.fechaFin,
          abierta: true,
          validada: false,
          pujas: this.tipo,
          importeSalida: total || 0,
          poblacionOrigen: poblacion,
          provinciaOrigen: provincia,
          paisOrigen: pais,
          comentarios: "",
          poblacionDestino: "",
          provinciaDestino: null,
          paisDestino: null,
          km: 0,
          tipoTraslado: "",
          servicios: servicios,
          tipo: "SERVICIO"
        }
        var completado = function (resData){
          this.confirmationEditSwal.fire();
          this.ngZone.run(() => this.router.navigate(['/mislicitaciones'])).then();
          this.cdr.detectChanges();
        }
        self.io.socket.patch('/licitacion/' + this.row.id, licit2, completado.bind(this));
      }  






    }
    self.io.socket.get('/licitacion/' + this.editID, loadedLicitacion.bind(this));

      
  }

  servicioPulsado(serv) {
    if(serv.abreviatura == "SVFUN" && !serv.val) {
      for(let serv of this.servicios) {
        if(serv.abreviatura == "FLOR" || serv.abreviatura == "TANAT" || serv.abreviatura == "COCHE" || serv.abreviatura == "GEST" || serv.abreviatura == "CREMA" || serv.abreviatura == "DESTEMP") {
          serv.error = {importeSalida: false, comentarios: false};
          serv.comentarios = null;
          serv.importeSalida = null;
          serv.val = false;
          serv.completo = true;
        }
      }
    } else if(serv.abreviatura == "SVFUN" && serv.val) {
      for(let serv of this.servicios) {
        if(serv.completo) {
          serv.error = {importeSalida: false, comentarios: false};
          serv.comentarios = null;
          serv.importeSalida = null;
          serv.val = false;
          serv.completo = false;
        }
      }
    } 
    this.cdr.detectChanges();
  }

  completoPulsado(serv, opcion) {
    serv.crema = false;
    serv.destemp = false;
    this.cdr.detectChanges();
    if(opcion == "CREMA") {
      serv.crema = true;
    } else {
      serv.destemp = true;
    }
    serv.tipo = opcion;
    this.cdr.detectChanges();
  }

  

}
