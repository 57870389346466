import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

import { AuthenticationService, StateService, CountryService, LicitaService } from '../../../_services';

import { DateTimeAdapter } from 'ng-pick-datetime';

import { interval, Subscription } from 'rxjs';

import * as moment from 'moment';
import { windowWhen } from 'rxjs/operators';

@Component({
  selector: 'kt-mislicitaciones',
  templateUrl: './mislicitaciones.component.html',
  styleUrls: ['./mislicitaciones.component.scss']
})
export class MislicitacionesComponent implements OnInit {

  my_messages = {
    'emptyMessage': 'Licitación Desierta',
    'totalMessage': 'total'
  };

  @ViewChild('confirmationSwal', {static: false}) private confirmationSwal: SwalComponent
  @ViewChild('content8', {static: false}) private modalLicit: NgbModal

  eventSusc: any;
  currentUser: any;
  servicios: any;

  participaciones = {};

  vista = "listado";

  rows = [];
  temp = [];

  rowsLicit = [];

  subscription: Subscription;

  paises: any;
  estados: any;
  estadosOrigen = []
  estadosDestino = [];

  page = 1;

  constructor(private modalService: NgbModal,
    private authenticationService: AuthenticationService,
    private cdr: ChangeDetectorRef,
    private stateService: StateService,
    private countryService: CountryService,
    private licitaService: LicitaService,
    dateTimeAdapter: DateTimeAdapter<any>) { 
      dateTimeAdapter.setLocale('es-ES');
      
    }

  ngOnInit() {
    console.log("INIT!!!!");
    this.currentUser = this.authenticationService.currentUserValue;
    
    console.log(self.io.socket);

    this.loadPaises();
    this.loadProvincias();

    this.getParticipaciones();
    
    var cbkServicios = function(res) {
      console.log(res);
      for(var i = 0; i < res.length; i++){
        res[i].val = false;
        res[i].comentarios = null;
        res[i].importeSalida = null;
        res[i].error = {importeSalida: false};
      }
      this.servicios = res;
    }

    var connectSails = function(){
      console.log("CONNECT");
      self.io.socket.get('/servicio', cbkServicios.bind(this));

      this.loadLicitaciones();

      const source = interval(1000);
      this.subscription = source.subscribe(val => this.procesoCountdown());
    }

    console.log("CONNECTED: " + self.io.socket.isConnected())
    if(self.io.socket.isConnected()) {
      var fn = connectSails.bind(this);
      fn();
    } else {
      self.io.socket.on('connect', connectSails.bind(this));
    }
  }

  getParticipaciones() {
    this.licitaService.getAllByUser(this.currentUser.id)
    .subscribe(licitas => {
      console.log(licitas);
      for(var i = 0; i < licitas.length; i++) {
        this.participaciones[licitas[i].licitacion+""] = true;
      }
			this.cdr.detectChanges();
		});
  }

  //emit value in sequence every 10 second
  

  procesoCountdown(){
    for(var i = 0; i < this.rows.length; i++) {
      var msStart = moment(this.rows[i].startDate).diff();

      if(msStart > 0){
        this.rows[i].estado = "ESPERA";
        var d = moment.duration(msStart);
        var hours = Math.floor(d.asHours());
        var hoursStr = "";
        if (hours < 10) hoursStr = "0" + hours
        else hoursStr = hours + "";
        var s = hoursStr + moment.utc(msStart).format(":mm:ss");
        this.rows[i].countdown = s;
      } else {
        var ms = moment(this.rows[i].endDate).diff();
      
        if(ms < 0){
          this.rows[i].estado = "CERRADA";
          this.rows[i].countdown = "00:00:00";
        } else {
          this.rows[i].estado = "ABIERTA";
          var d = moment.duration(ms);
          var hours = Math.floor(d.asHours());
          var hoursStr = "";
          if (hours < 10) hoursStr = "0" + hours
          else hoursStr = hours + "";
          var s = hoursStr + moment.utc(ms).format(":mm:ss");
          this.rows[i].countdown = s;
        }
      }


      
    }
    if(this.row){
      var msStart = moment(this.row.startDate).diff();

      if(msStart > 0){
        this.row.estado = "ESPERA";
        var d = moment.duration(msStart);
        var hours = Math.floor(d.asHours());
        var hoursStr = "";
        if (hours < 10) hoursStr = "0" + hours
        else hoursStr = hours + "";
        var s = hoursStr + moment.utc(msStart).format(":mm:ss");
        this.row.countdown = s;
      } else {
        var ms = moment(this.row.endDate).diff();
        
        if(ms < 0){
          this.row.estado = "CERRADA";
          this.row.countdown = "00:00:00";
          //this.countdownValue(s);
        } else {
          this.row.estado = "ABIERTA";
          var d = moment.duration(ms);
          var hours = Math.floor(d.asHours());
          var hoursStr = "";
          if (hours < 10) hoursStr = "0" + hours
          else hoursStr = hours + "";
          var s = hoursStr + moment.utc(ms).format(":mm:ss");
          this.row.countdown = s;
        }
      }
    }
    this.cdr.detectChanges();
  }

  loadLicitaciones() {
    // - EVENTOS -
    this.eventSusc = function(msg) {
      if (this.cdr['destroyed']) {
        return;
      }
      console.log(msg);
      if(msg.verb == "updated"){
        if(msg.data.ultimaLicitacion){
          var loadLicit = function(res){
            for(var i = 0; i < this.rows.length; i++){
              if(this.rows[i].id == msg.data.id){
                this.rows[i].precio = res.importe;
                this.rows[i].ultimaLicitacion = res;
                break;
              }
            }
          }
          self.io.socket.get('/licita/'+msg.data.ultimaLicitacion, loadLicit.bind(this));
        }
      }
    }
    var loadedLicitaciones = function(resData){
      console.log(resData);
      this.rows = resData;
      for(var i = 0; i < this.rows.length; i++) {
        this.rows[i].estado = "ABIERTA";
        this.rows[i].fecha = moment(this.rows[i].startDate).format("DD/MM/YYYY HH:mm");
        if(this.rows[i].ultimaLicitacion){
          this.rows[i].precio = this.rows[i].ultimaLicitacion.importe;
        } else {
          this.rows[i].precio = this.rows[i].importeSalida;
        }
      }
      this.cdr.detectChanges();
    }
    self.io.socket.get('/licitacion?creador=' + this.currentUser.id, loadedLicitaciones.bind(this));

    self.io.socket.on('licitacion', this.eventSusc.bind(this));
  }

  loadProvincias(){
    console.log("loadProvincias()");
		this.stateService.getAll()
	    .subscribe(estados => {
        this.estados = estados;
        this.cdr.detectChanges();
      });
  }

  loadPaises() {
		console.log("loadPaises()");
		this.countryService.getAll()
	    .subscribe(paises => {
			this.paises = paises;
      this.cdr.detectChanges();
		});
	}

  ngOnDestroy(){
    console.log("DESTROY!!");
    if(this.subscription) this.subscription.unsubscribe();
    self.io.socket.off('licitacion', this.eventSusc);
  }

  // ------- MODAL -------

  referenciaCliente = null;
  traslado = false;

  tipoTraslado = null;
  km = null;
  importeTraslado = null;
  comentariosTraslado = null;

  fechaInicio: any;
  fechaFin: any;
  error = {referencia: false, origen: false, destino: false, tipoTraslado: false, km: false, importeTraslado: false, comentariosTraslado: false, fechaInicio: false, fechaFin: false, fechaOrden: false, fechaHoy: false, servicios: false};

  paisOrigen = null;
  provinciaOrigen = null;
  poblacionOrigen = null;

  paisDestino = null;
  provinciaDestino = null;
  poblacionDestino = null;

  openDialog(content) {
    window.scrollTo(0,1);
    this.modalService.open(this.modalLicit, { centered: true, size: 'xl' } );
    window.scrollTo(0,0);
    this.page = 1;
  }

  cambiarPagina(page) {
    if(this.page == 1){
      //COMPROBAR ERRORES
      var errors = false;
      //error = {referencia: false, origen: false, fechaInicio: false, fechaFin: false, fechaOrden: false, fechaHoy: false, servicios: false};

      if(!this.referenciaCliente || this.referenciaCliente == ""){
        this.error.referencia = true;
        errors = true;
      } else {
        this.error.referencia = false;
      }
      if(!this.paisOrigen || !this.provinciaOrigen || !this.poblacionOrigen){
        this.error.origen = true;
        errors = true;
      } else {
        this.error.origen = false;
      }
      if(this.traslado) {
        if(!this.paisDestino || !this.provinciaDestino || !this.poblacionDestino){
          this.error.destino = true;
          errors = true;
        } else {
          this.error.destino = false;
        }
      }
      if(!this.fechaInicio){
        this.error.fechaInicio = true;
        errors = true;
      } else {
        this.error.fechaInicio = false;
      }
      if(!this.fechaFin){
        this.error.fechaFin = true;
        errors = true;
      } else {
        this.error.fechaFin = false;
      }
      var servicioSel = false;
      for(var i = 0; i < this.servicios.length; i++){
        if(this.servicios[i].val) {
          servicioSel = true;
          break;
        }
      }
      if(!servicioSel && !this.traslado) {
        this.error.servicios = true;
        errors = true;
      } else {
        this.error.servicios = false;
      }
      if(moment(this.fechaFin).diff(this.fechaInicio) < 0){
        this.error.fechaOrden = true;
        errors = true;
      } else {
        this.error.fechaOrden = false;
      }

      if(errors) {
        this.cdr.detectChanges();
        return;
      }
    }
    this.page = page;
  }

  countryChangedOrigen() {
    this.estadosOrigen = [];
    for(var i = 0; i < this.estados.length; i++) {
      if(this.estados[i].countryId == this.paisOrigen) this.estadosOrigen.push(this.estados[i]);
    }
    this.cdr.detectChanges();
  }

  countryChangedDestino(pais: any) {
    this.estadosDestino = [];
    for(var i = 0; i < this.estados.length; i++) {
      if(this.estados[i].countryId == pais) this.estadosDestino.push(this.estados[i]);
    }
    this.cdr.detectChanges();
  }

  serviciosCompletados = 0;

  loading = false;

  submitLicit() {
    console.log("SUBMIT");
    //COMPROBAR SERVICIOS
    //res[i].error = {importeSalida: false, pais: false, estado: false, poblacion: false, tipo: false, km: false}
    var errors = false;
    if(this.traslado){
      if(!this.tipoTraslado){
        this.error.tipoTraslado = true;
        errors = true;
      } else {
        this.error.tipoTraslado = false;
      }
      if(!this.km){
        this.error.km = true;
        errors = true;
      } else {
        this.error.km = false;
      }
      if(!this.importeTraslado){
        this.error.importeTraslado = true;
        errors = true;
      } else {
        this.error.importeTraslado = false;
      }
    }
    for(var i = 0; i < this.servicios.length; i++){
      if(this.servicios[i].val) {
        if(!this.servicios[i].importeSalida){
          this.servicios[i].error.importeSalida = true;
          errors = true;
        } else {
          this.servicios[i].error.importeSalida = false;
        }
      }
    }
    if(errors) {
      console.log(this.servicios)
      this.cdr.detectChanges();
      return;
    }

    this.loading = true;

    if(moment(this.fechaInicio).diff() < 0){
      this.fechaInicio = new Date();
    }

    var continuarGuardado = function () {
      console.log("CONTINUAR GUARDADO");
      var total = 0;
      var servicios = [];
      for(var i = 0; i < this.servicios.length; i++){
        if(this.servicios[i].val) {
          total += this.servicios[i].importeSalida;
          delete this.servicios[i].val;
          delete this.servicios[i].error;
          servicios.push(this.servicios[i]);
        }
      }
      var poblacion = "";
      var provincia = null;
      var pais = null;
      if(this.traslado) {
        poblacion = this.poblacionDestino;
        provincia = this.provinciaDestino;
        pais = this.paisDestino;
      } else {
        poblacion = this.poblacionOrigen;
        provincia = this.provinciaOrigen;
        pais = this.paisOrigen;
      }

      var licit = {
        referencia: this.currentUser.id + "" + moment().unix(),
        referenciaCliente: this.referenciaCliente || "",
        startDate: this.fechaInicio,
        endDate: this.fechaFin,
        abierta: true,
        importeSalida: total || 0,
        poblacionOrigen: poblacion,
        provinciaOrigen: provincia,
        paisOrigen: pais,
        comentarios: "",
        creador: this.currentUser.id,
        poblacionDestino: "",
        provinciaDestino: null,
        paisDestino: null,
        km: 0,
        tipoTraslado: "",
        servicios: servicios,
        tipo: "SERVICIO"
      }
      var completado = function (resData){
        console.log(resData);
        //Añadir al listado activo
        resData.precio = resData.importeSalida;
        resData.estado = "ABIERTA";
        resData.fecha = moment(resData.startDate).format("DD/MM/YYYY HH:mm");
        this.rows = [...this.rows, resData];
        this.cdr.detectChanges();

        console.log("COMPLETADO");
        this.loading = false;
        this.modalService.dismissAll();
        this.confirmationSwal.fire();
      }
      self.io.socket.post('/licitacion', licit, completado.bind(this));
    }
    
    //GUARDAR
    if(this.traslado) {
      console.log("TRASLADO SI");
      var licit = {
        referencia: this.currentUser.id + ""+ moment().unix(),
        referenciaCliente: this.referenciaCliente || "",
        startDate: this.fechaInicio,
        endDate: this.fechaFin,
        abierta: true,
        importeSalida: this.importeTraslado || 0,
        poblacionOrigen: this.poblacionOrigen,
        provinciaOrigen: this.provinciaOrigen,
        paisOrigen: this.paisOrigen,
        comentarios: this.comentariosTraslado || "",
        creador: this.currentUser.id,
        poblacionDestino: this.poblacionDestino,
        provinciaDestino: this.provinciaDestino,
        paisDestino: this.paisDestino,
        km: this.km,
        tipoTraslado: this.tipoTraslado,
        tipo: "TRASLADO"
      }
      var completado = function (resData){
        console.log(resData);
        //Añadir al listado activo
        resData.precio = resData.importeSalida;
        resData.estado = "ABIERTA";
        resData.fecha = moment(resData.startDate).format("DD/MM/YYYY HH:mm");
        this.rows = [...this.rows, resData];
        this.cdr.detectChanges();
        var fn = continuarGuardado.bind(this);
        fn();
      }
      self.io.socket.post('/licitacion', licit, completado.bind(this));
    } else {
      console.log("SERVICIO SOLO");
      var fn = continuarGuardado.bind(this);
      fn();
    }    
  }

  // ----- LICITAR ------

  row: any;

  onSelect(event: any) {
    if(event.type == "click"){
      event.cellElement.blur();
      console.log("licitar()", event.row);
      this.row = event.row;
      this.vista = 'detalle';
      this.cdr.detectChanges();
      KTUtil.scrollTop();
      this.loadLicita();
    }
  }

  loadLicita() {
    this.rowsLicit = [];
    this.licitaService.getAllByLicitacion(this.row.id)
    .subscribe(licitas => {
      console.log(licitas);
      this.rowsLicit = licitas;
      for(var i = 0; i < this.rowsLicit.length; i++) {
        this.rowsLicit[i].fecha = moment(this.rowsLicit[i].fecha).format("DD/MM/YYYY HH:mm");
        var ms = moment(this.rowsLicit[i].licitacion.endDate).diff();
      
        if(i != 0 || (i == 0 && ms > 0)){
          this.rowsLicit[i].empresa = this.rowsLicit[i].user.empresa.substring(0, 3) + "*****";
        } else if(i == 0 && ms < 0) {
          this.rowsLicit[i].empresa = this.rowsLicit[i].user.empresa;
          this.rowsLicit[i].fin = true;
        }
      }
			this.cdr.detectChanges();
		});
  }

}
