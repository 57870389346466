import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../_services';

@Injectable()
export class StartGuard implements CanActivate {
 
    constructor(private router: Router,
        private authenticationService: AuthenticationService) { }
 
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService.currentUserValue;

        if (currentUser.status != "FORMULARIO") {
            return true;
        }

        this.router.navigate(['/start']);
        
        return false;
    }
}