import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { CountryService, AuthenticationService, StateService, TarifaService, UserService, SuscriptionService, NotificacionService } from '../../../_services';
import { HttpClient } from '@angular/common/http';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

import { environment } from '../../../../environments/environment';

import * as moment from 'moment';
import { validateSpanishId } from 'spain-id'

@Component({
  selector: 'kt-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss']
})
export class StartComponent implements OnInit {

  @ViewChild('wizard', {static: true}) el: ElementRef;
  @ViewChild('errorSMSSwal', {static: false}) private errorSMSSwal: SwalComponent
  @ViewChild('errorTelegramSwal', {static: false}) private errorTelegramSwal: SwalComponent
  @ViewChild('errorFileSwal', {static: false}) private errorFileSwal: SwalComponent

  model: any = {};
  currentUser: any;

  tarifaPar = null;

  paises: any;
  paisesHash = {};
  estados: any;
  estadosHash = {};
  estadosAll: any;
  tarifas = [];
  tarifasHash = {};

  vistaTarifas = "mensual";

  wiz3Oculto = true;
  
  loading = false;

  wiz1Form: FormGroup;

  certificados = [
    {codigo: "ISO 9001", nombre: "Calidad", valor: false},
    {codigo: "ISO 14001", nombre: "Medio Ambiente", valor: false},
    {codigo: "ISO 45001", nombre: "Seguridad y Salud en el trabajo", valor: false},
    {codigo: "ISO 26001", nombre: "Responsabilidad Social Corporativa", valor: false},
    {codigo: "ISO 27001", nombre: "Seguridad de la información GDPR", valor: false},
    {codigo: "ISO 22000", nombre: "Seguridad Alimentaria, Análisis de Peligros y Puntos Críticos de Control (APPCC) Cafeterías", valor: false},
    {codigo: "ISO 55000", nombre: "Gestión de Activos", valor: false},
    {codigo: "ISO 20700", nombre: "Consultoría de Gestión", valor: false},
    {codigo: "ISO 10002", nombre: "Satisfacción del cliente Directrices para la resolución de conflictos de forma externa a las organizaciones", valor: false},
    {codigo: "ISO 10003", nombre: "Directrices para tratamiento de quejas en las organizaciones", valor: false},
  ]

  puntuaciones = [
    {codigo: "UNE 0069", valor: null},
    {codigo: "UNE 175001", valor: null},
    {codigo: "UNE 190001", valor: null},
    {codigo: "UNE 190002", valor: null},
  ]


	submittedWiz1 = false;

  constructor(private router: Router,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private notificacionService: NotificacionService,
    private userService: UserService,
    private stateService: StateService,
    private tarifaService: TarifaService,
    private countryService: CountryService,
    private suscriptionService: SuscriptionService,
		private route: ActivatedRoute,
    private http: HttpClient) {
	}

	ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.tarifaPar = params.get("tarifa");
      this.currentUser = this.authenticationService.currentUserValue;
      
      if(this.currentUser.status == "ACTIVO") {
        this.router.navigate(['/']);
      } else {
        this.initWiz1Form();
        this.loadProvinciasAll();
        this.loadPaises();
        //this.loadPais();
        this.loadTarifas();
        setTimeout(() => {
          KTUtil.scrollTop();
        }, 100);
      }
    })
    
  }

  scroll(id) {
    let el = document.getElementById(id);
    el.scrollIntoView({behavior:"smooth"});
  }

  volverTarifa(){
    this.wizard.goTo(1);
    this.wizard.goTo(1);
    setTimeout(() => {
      KTUtil.scrollTop();
    }, 500);
  }

  onChangePais(event){
    this.wiz1Form.controls["pais"].disable();
    this.wiz1Form.controls["provincia"].disable();
    this.loadProvincias(event.target.value);

    this.provinciasSel = [];
    this.paisesSel = [];
    var lista = [];
    for(let estado of this.estadosAll) {
      if(estado.countryId == this.wiz1Form.controls["pais"].value) {
        lista.push(estado);
      }
    }
    for(var i = 0; i < this.tarifa.provincias; i++) {
      this.provinciasSel.push({num: i+1, val: "", pais: this.wiz1Form.controls["pais"].value, lista: lista});
    }
    for(var i = 0; i < this.tarifa.paises; i++) {
      this.paisesSel.push({num: i+1, val: ""});
    }
  }

  loadProvincias(pais){
		this.stateService.getAllByCountry(pais)
	    .subscribe(estados => {
        this.estados = estados;
        this.wiz1Form.controls["pais"].enable();
        this.wiz1Form.controls["provincia"].enable();
        this.cdr.detectChanges();
      });
  }

  loadProvinciasAll(){
    console.log("loadProvinciasAll()");
		this.stateService.getAll()
	    .subscribe(estados => {
        console.log(estados);
        this.estadosAll = estados;
        this.cdr.detectChanges();
        for(var i = 0; i < estados.length; i++){
          this.estadosHash[estados[i].id+""] = estados[i];
        }
      });
  }

  loadPaises() {
		console.log("loadPaises()");
		this.countryService.getAll()
	    .subscribe(paises => {
			console.log(paises);
			this.paises = paises;
      this.cdr.detectChanges();
      for(var i = 0; i < paises.length; i++){
        this.paisesHash[paises[i].id+""] = paises[i];
      }
		});
	}

  loadPais(){
    this.countryService.getById(this.currentUser.pais)
    .subscribe(pais => {
      this.currentUser.paisStr = pais["name"];
      this.cdr.detectChanges();
    });
  }

  loadTarifas(){
		this.tarifaService.getAll()
	    .subscribe(tarifas => {
        this.tarifas = tarifas;
        this.cdr.detectChanges();
        var nombreTarifa = "";
        var frecTarifa = "";
        if(this.tarifaPar) {
          nombreTarifa = this.tarifaPar.split('-')[0];
          frecTarifa = this.tarifaPar.split('-')[1];
        }
        for(var i = 0; i < tarifas.length; i++){
          this.tarifasHash[tarifas[i].id+""] = tarifas[i];
          if(this.tarifaPar && tarifas[i].nombre == nombreTarifa) {
            this.tarifa = tarifas[i];

            this.step2.proveedorTarifa = tarifas[i].id;
            this.step2.proveedorPago = frecTarifa;
          }
        }
      });
  }

  cambiarVistaTarifas(vista: any) {
    this.scroll("tarifas");
    this.vistaTarifas = vista;
  }
  
  initWiz1Form() {
		this.wiz1Form = this.fb.group({
      cif: [this.currentUser.cif, Validators.compose([Validators.required])],
      licencia: [''],
      domicilio: ['', Validators.compose([Validators.required])],
      poblacion: ['', Validators.compose([Validators.required])],
      cp: ['', Validators.compose([Validators.required])],
      telefono: ['', Validators.compose([Validators.required, Validators.pattern("[0-9]{9,11}")])],
      telefono2: ['', [Validators.pattern("[0-9]{9,11}")]],
      pais: ['', Validators.compose([Validators.required])],
      provincia: ['', Validators.compose([Validators.required])],
      empresa: [this.currentUser.empresa, Validators.compose([Validators.required])],
      actividad: [this.currentUser.actividad, Validators.compose([Validators.required])],
      paginaweb: [''],
      notif_email: [false],
      notif_telegram: [false],
		});
  }

  error = {notificaciones: false, cif: false, sms: false, smsCode: false, telegram: false, telegramCode: false};
  wizard = null;

	ngAfterViewInit(): void {
		// Initialize form wizard
		this.wizard = new KTWizard(this.el.nativeElement, {
			startStep: 1,
			clickableSteps: false
		});

    const wizard = this.wizard;

		// Validation before going to next page
		wizard.on('beforeNext', (wizardObj) => {
      // validate the form and use below function to stop the wizard's step
      console.log("STEP: " + wizard.getStep());
      if(wizard.getStep() == 1) {
        console.log(this.wiz1Form.value);
        this.submittedWiz1 = true;

        if(!this.wiz1Form.value.notif_email && !this.wiz1Form.value.notif_telegram) {
          this.error.notificaciones = true;
        } else {
          this.error.notificaciones = false;
          this.cdr.detectChanges();
        }

        if(this.wiz1Form.value.pais == 207 && !validateSpanishId(this.wiz1Form.value.cif)) {
          this.error.cif = true;
        } else {
          this.error.cif = false;
          this.cdr.detectChanges();
        }

        if (this.wiz1Form.invalid || this.error.notificaciones || this.error.cif) {
          console.log(this.wiz1Form);
          const controls = this.wiz1Form.controls;
          Object.keys(controls).forEach(controlName =>
            controls[controlName].markAsTouched()
          );
          this.cdr.detectChanges();
          wizard.stop();
          return;
        } else {
          var pais = this.paisesHash[this.wiz1Form.value.pais];
          this.userService.confirmacionSMS({email: this.currentUser.email, telefono: pais.phonecode + "" + this.wiz1Form.value.telefono})
            .pipe(first())
            .subscribe(
              data => {
                console.log(data);
              },
              error => {
                console.log(error);
            });
        }

        if(this.wiz1Form.value.actividad != 'Funerarias') {
          this.step2.ofertante = true;
          this.step2.proveedor = false;
        } else {
          this.step2.ofertante = false;
          this.step2.proveedor = true;
        }
        this.cdr.detectChanges();
      } else if(wizard.getStep() == 2){
        var valid = true;
        this.error.telegramCode = false;
        if(this.wiz1Form.value.notif_telegram && (!this.stepMovil.telegram || this.stepMovil.telegram == "")) {
          this.error.telegram = true;
          valid = false;
        } else {
          this.error.telegram = false;
          this.cdr.detectChanges();
        }
        this.cdr.detectChanges();
        if (!valid) {
          wizard.stop();
        } else {
          this.loading = true;
          wizard.stop();
          this.cdr.detectChanges();
          this.userService.checkSMS({email: this.currentUser.email, code: this.stepMovil.sms})
            .pipe(first())
            .subscribe(
              data => {
                console.log(data);

                if(this.wiz1Form.value.notif_telegram) {
                  this.userService.checkTelegram({code: this.stepMovil.telegram})
                  .pipe(first())
                  .subscribe(
                    data => {
                      console.log(data);
                      this.loading = false;
                      wizard.start();
                      wizard.goTo(3);
                      wizard.goTo(3);
                      this.cdr.detectChanges();
                    },
                    error => {
                      this.loading = false;
                      this.error.telegramCode = true;
                      console.log(error);
                      this.errorTelegramSwal.fire();
                      this.cdr.detectChanges();
                  });
                  this.cdr.detectChanges();
                } else {
                  this.loading = false;
                  this.cdr.detectChanges();
                  wizard.goTo(3);
                  wizard.goTo(3);
                }
              },
              error => {
                this.loading = false;
                this.error.smsCode = true;
                console.log(error);
                this.errorSMSSwal.fire();
                this.cdr.detectChanges();
            });

        }
      } else if(wizard.getStep() == 3){
        //step2 = {ofertante: false, ofertanteConfirm: false, proveedor: false, proveedorTarifa: null, proveedorPago: null};
        //errors2 = {option: false, ofertanteTos: false, proveedorTarifa: false, proveedorPago: false};
        if(!this.step2.ofertante && !this.step2.proveedor) {
          this.errors2.option = true;
          wizard.stop();this.cdr.detectChanges();
          return;
        } else this.errors2.option = false;

        if(this.step2.ofertante && !this.step2.ofertanteConfirm) {
          this.errors2.ofertanteTos = true;
          wizard.stop();this.cdr.detectChanges();
          return;
        } else this.errors2.ofertanteTos = false;

        if(this.step2.proveedor && this.step2.proveedorTarifa == null) {
          this.errors2.proveedorTarifa = true;
          wizard.stop();this.cdr.detectChanges();
          return;
        } else this.errors2.proveedorTarifa = false;

        if(this.step2.proveedor && this.step2.proveedorPago == null) {
          this.errors2.proveedorPago = true;
          wizard.stop();this.cdr.detectChanges();
          return;
        } else this.errors2.proveedorPago = false;

        /*for(var i = 0; i < this.tarifas.length; i++) {
          if(this.tarifas[i].id == this.step2.proveedorTarifa){
            this.tarifa = this.tarifas[i];
            console.log("TARIFA", this.tarifa);
            break;
          }
        }*/

        if(this.step2.proveedor == false) {
          //this.wiz3Oculto = true;
          wizard.goTo(4);
          wizard.goTo(4);
        } else {
          //this.wiz3Oculto = false;
          var valid = true;
          console.log(this.provinciasSel);
          for(var i = 0; i < this.provinciasSel.length; i++) {
            if(!this.provinciasSel[i].val) {
              valid = false;
              this.provinciasSel[i].error = true;
            } else {
              this.provinciasSel[i].error = false;
            }
          }
          for(var i = 0; i < this.paisesSel.length; i++) {
            if(!this.paisesSel[i].val) {
              valid = false;
              this.paisesSel[i].error = true;
            } else {
              this.paisesSel[i].error = false;
            }
          }
          this.cdr.detectChanges();
          if (!valid) {
            wizard.stop();
          } else {
            wizard.goTo(4);
            wizard.goTo(4);
          }
        }
        this.cdr.detectChanges();
      } else if(wizard.getStep() == 4) {
        //Validar IBAN si no es Funeraria
        if(this.wiz1Form.value.actividad != 'Funerarias') {
          if(!this.step3.titular) {
            this.errors3.titular = true;
            wizard.stop();this.cdr.detectChanges();
            return;
          } else this.errors3.titular = false;
          if(this.ibanForm.invalid) {
            wizard.stop();this.cdr.detectChanges();
            return;
          }
        }
      }
		});

		wizard.on('beforePrev', (wizardObj) => {
			// wizardObj.stop();
			/*if(wizard.getStep() == 4 && !this.step2.proveedor) {
				wizard.goTo(2);
			}*/
		});

		// Change event
		wizard.on('change', () => {
			setTimeout(() => {
				KTUtil.scrollTop();
			}, 500);
		});
  }

  validarEmpresa() {
    console.log(this.wiz1Form.value);
    this.submittedWiz1 = true;

    if(!this.wiz1Form.value.notif_email && !this.wiz1Form.value.notif_telegram) {
      this.error.notificaciones = true;
    } else {
      this.error.notificaciones = false;
      this.cdr.detectChanges();
    }

    if(this.wiz1Form.value.pais == 207 && !validateSpanishId(this.wiz1Form.value.cif)) {
      this.error.cif = true;
    } else {
      this.error.cif = false;
      this.cdr.detectChanges();
    }

    var validLoc = true;
    if(this.wiz1Form.value.actividad == 'Funerarias') {
      //this.wiz3Oculto = false;
      console.log(this.provinciasSel);
      for(var i = 0; i < this.provinciasSel.length; i++) {
        if(!this.provinciasSel[i].val) {
          validLoc = false;
          this.provinciasSel[i].error = true;
        } else {
          this.provinciasSel[i].error = false;
        }
      }
      for(var i = 0; i < this.paisesSel.length; i++) {
        if(!this.paisesSel[i].val) {
          validLoc = false;
          this.paisesSel[i].error = true;
        } else {
          this.paisesSel[i].error = false;
        }
      }
      this.cdr.detectChanges();
    }

    if (this.wiz1Form.invalid || this.error.notificaciones || this.error.cif || !validLoc) {
      console.log(this.wiz1Form);
      const controls = this.wiz1Form.controls;
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      this.cdr.detectChanges();
      return;
    } else {
      var pais = this.paisesHash[this.wiz1Form.value.pais];
      this.mensajeReenviado = false;
      this.userService.confirmacionSMS({email: this.currentUser.email, telefono: pais.phonecode + "" + this.wiz1Form.value.telefono})
        .pipe(first())
        .subscribe(
          data => {
            console.log(data);
          },
          error => {
            console.log(error);
        });
        if(this.wiz1Form.value.notif_telegram) {
          this.userService.checkPhoneTelegram({telefono: this.wiz1Form.value.telefono})
          .pipe(first())
          .subscribe(
            data => {
            },
            error => {
          });
        }
    }

    if(this.wiz1Form.value.actividad != 'Funerarias') {
      this.step2.ofertante = true;
      this.step2.proveedor = false;
    } else {
      this.step2.ofertante = false;
      this.step2.proveedor = true;
    }

    if(this.tarifaPar) {
      this.wizard.goTo(2);
      this.wizard.goTo(2);
    } else {
      this.wizard.goTo(3);
      this.wizard.goTo(3);
    }
    setTimeout(() => {
      KTUtil.scrollTop();
    }, 500);
    this.cdr.detectChanges();
  }

  validarValidacion(){
    var valid = true;
    this.error.telegramCode = false;
    if(this.wiz1Form.value.notif_telegram && (!this.stepMovil.telegram || this.stepMovil.telegram == "")) {
      this.error.telegram = true;
      valid = false;
    } else {
      this.error.telegram = false;
      this.cdr.detectChanges();
    }
    this.cdr.detectChanges();
    if (!valid) {
      return;
    } else {
      this.loading = true;
      this.cdr.detectChanges();
      this.userService.checkSMS({email: this.currentUser.email, code: this.stepMovil.sms})
        .pipe(first())
        .subscribe(
          data => {
            console.log(data);

            if(this.wiz1Form.value.notif_telegram) {
              this.userService.checkTelegram({code: this.stepMovil.telegram})
              .pipe(first())
              .subscribe(
                data => {
                  console.log(data);
                  this.loading = false;
                  if(this.tarifaPar) {
                    this.wizard.goTo(3);
                    this.wizard.goTo(3);
                  } else {
                    this.wizard.goTo(4);
                    this.wizard.goTo(4);
                  }
                  setTimeout(() => {
                    KTUtil.scrollTop();
                  }, 500);
                  this.cdr.detectChanges();
                },
                error => {
                  this.loading = false;
                  this.error.telegramCode = true;
                  console.log(error);
                  this.errorTelegramSwal.fire();
                  this.cdr.detectChanges();
              });
              this.cdr.detectChanges();
            } else {
              this.loading = false;
              this.cdr.detectChanges();
              if(this.tarifaPar) {
                this.wizard.goTo(3);
                this.wizard.goTo(3);
              } else {
                this.wizard.goTo(4);
                this.wizard.goTo(4);
              }
              setTimeout(() => {
                KTUtil.scrollTop();
              }, 500);
            }
          },
          error => {
            this.loading = false;
            this.error.smsCode = true;
            console.log(error);
            this.errorSMSSwal.fire();
            this.cdr.detectChanges();
        });

    }
  }

  mensajeReenviado = false;
  reenviarSMS() {
    var pais = this.paisesHash[this.wiz1Form.value.pais];
    this.userService.confirmacionSMS({email: this.currentUser.email, telefono: pais.phonecode + "" + this.wiz1Form.value.telefono, reenvio: true})
        .pipe(first())
        .subscribe(
          data => {
            this.mensajeReenviado = true;
            this.cdr.detectChanges();
          },
          error => {
            console.log(error);
        });
  }

  validarCertPago(){
    if(this.wiz1Form.value.actividad != 'Funerarias') {
      if(!this.step3.titular) {
        this.errors3.titular = true;
        this.cdr.detectChanges();
        return;
      } else this.errors3.titular = false;
      if(this.ibanForm.invalid) {
        this.cdr.detectChanges();
        return;
      }
    }

    if(this.tarifaPar) {
      this.wizard.goTo(4);
      this.wizard.goTo(4);
    } else {
      this.wizard.goTo(5);
      this.wizard.goTo(5);
    }
    setTimeout(() => {
      KTUtil.scrollTop();
    }, 500);
  }

  volverValidacion(){
    if(this.tarifaPar) {
      this.wizard.goTo(1);
      this.wizard.goTo(1);
    } else {
      this.wizard.goTo(2);
      this.wizard.goTo(2);
    }
    setTimeout(() => {
      KTUtil.scrollTop();
    }, 500);
  }

  volverCertPago(){
    if(this.tarifaPar) {
      this.wizard.goTo(2);
      this.wizard.goTo(2);
    } else {
      this.wizard.goTo(3);
      this.wizard.goTo(3);
    }
    setTimeout(() => {
      KTUtil.scrollTop();
    }, 500);
  }

  volverConfirmacion(){
    if(this.tarifaPar) {
      this.wizard.goTo(3);
      this.wizard.goTo(3);
    } else {
      this.wizard.goTo(4);
      this.wizard.goTo(4);
    }
    setTimeout(() => {
      KTUtil.scrollTop();
    }, 500);
  }

  onChangeTarifa(tarifa){
    console.log("onChangeTarifa()");
    this.tarifa = tarifa;

    this.step2.proveedorTarifa = tarifa.id;
    this.step2.proveedorPago = this.vistaTarifas;
    /*this.provinciasSel = [];
    this.paisesSel = [];
    var lista = [];
    for(let estado of this.estadosAll) {
      if(estado.countryId == this.wiz1Form.value.pais) {
        lista.push(estado);
      }
    }
    for(var i = 0; i < this.tarifa.provincias; i++) {
      this.provinciasSel.push({num: i+1, val: "", pais: this.wiz1Form.value.pais, lista: lista});
    }
    for(var i = 0; i < this.tarifa.paises; i++) {
      this.paisesSel.push({num: i+1, val: ""});
    }*/

    this.wizard.goTo(2);
    this.wizard.goTo(2);

    setTimeout(() => {
      KTUtil.scrollTop();
    }, 500);
  }

  continuarAseguradora(){
    this.wizard.goTo(2);
    this.wizard.goTo(2);

    setTimeout(() => {
      KTUtil.scrollTop();
    }, 500);
  }
  
  modelChange() {
    this.cdr.detectChanges();
  }

  //Imagen de funeraria
  files: File[] = [];
  image: any;

  onSelectFile(event) {
    console.log(event);
    if(!event.addedFiles[0].type.includes("image")){
      this.errorFileSwal.fire();
      return;
    }
    this.files = [];
    this.files.push(...event.addedFiles);

    
    console.log(event.addedFiles[0]);
  }
  
  onRemove(event) {
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }


	/**
	 * Form Submit
	 */
	onSubmit() {
		if(this.loading) return;

		this.loading = true;
    this.cdr.detectChanges();

		var currentUser = this.authenticationService.currentUserValue;
		var form = this.wiz1Form.value;
		
		form.id = currentUser.id;
    form.status = "ACTIVO";

    form.ofertante = this.step2.ofertante;
    form.proveedor = this.step2.proveedor;
    form.codigo_telegram = this.stepMovil.telegram;
    form.avisos = "inmediato";
    if(this.step3.iban) form.iban = this.step3.iban;
    if(this.step3.titular) form.iban_titular = this.step3.titular;

    if(form.proveedor) {
      form.certificados = this.certificados;
      form.puntuaciones = this.puntuaciones;
    }
    
    console.log(form);

		this.userService.update(form)
      .pipe(first())
      .subscribe(
        data => {
          console.log("OK", data);
          currentUser.empresa = form.empresa;
          currentUser.actividad = form.actividad;
          currentUser.cif = form.cif;
          currentUser.cp = form.cp;
          currentUser.pais = form.pais;
          currentUser.domicilio = form.domicilio;
          currentUser.licencia = form.licencia;
          currentUser.ofertante = form.ofertante;
          currentUser.proveedor = form.proveedor;
          currentUser.poblacion = form.poblacion;
          currentUser.provincia = form.provincia;
          currentUser.status = form.status;
          currentUser.telefono2 = form.telefono2;

          if(form.proveedor) {
            var now = moment();
            var end = moment();
            var precio = null
            if(this.step2.proveedorPago == "anual") {
              end.add(1, 'years');
              precio = this.tarifasHash[this.step2.proveedorTarifa].precioAnual;
            } else if(this.step2.proveedorPago == "mensual") {
              end.add(1, 'months');
              precio = this.tarifasHash[this.step2.proveedorTarifa].precioMensual;
            }

            var provincias = [];
            var paises = [];

            for(var i = 0; i < this.provinciasSel.length; i++) {
              provincias.push(this.estadosHash[this.provinciasSel[i].val]);
            }
            for(var i = 0; i < this.paisesSel.length; i++) {
              paises.push(this.paisesHash[this.paisesSel[i].val]);
            }

            var alta = "";
            if(this.step2.ofertante) alta += "Ofertante";
            if(this.step2.ofertante && this.step2.proveedor) alta += " y ";
            if(this.step2.proveedor) alta += "Proveedor";
            alta += " de traslados y servicios funerarios";

            var tarifa = "";
            var tarifaStripe = null;
            if(this.step2.proveedor && this.step2.proveedorTarifa) {
              tarifa += this.tarifasHash[this.step2.proveedorTarifa].nombre;
              if(this.step2.proveedorPago == 'mensual') {
                tarifa += " (" + this.tarifasHash[this.step2.proveedorTarifa].precioMensual + " €/" + this.step2.proveedorPago + ")";
                tarifaStripe = this.tarifasHash[this.step2.proveedorTarifa].stripeMensual;
              } else {
                tarifa += " (" + this.tarifasHash[this.step2.proveedorTarifa].precioAnual + " €/" + this.step2.proveedorPago + ")";
                tarifaStripe = this.tarifasHash[this.step2.proveedorTarifa].stripeAnual;
              }
            } else {
              tarifa = "-";
            }

            var fecha = end.format("DD/MM/YYYY");

            /*this.http.post(`${environment.apiUrl}/mailregistro`, {destinatario: currentUser.email, empresa: form.empresa, actividad: form.actividad, alta: alta, tarifa: tarifa, fecha: fecha, hash: currentUser.hashUser})
            .pipe(first())
            .subscribe(
              data => {
                console.log("OK MAIL", data);
              },
              error => {
                console.error(error);
                this.loading = false;
              });*/

            
            if(this.wiz1Form.value.notif_telegram) {
              this.userService.confirmacionTelegram({code: this.stepMovil.telegram})
                .pipe(first())
                .subscribe(
                  data => {
                  },
                  error => {
                    console.log(error);
                });
            }
            
            // Crear Suscripción
            var susc = {
              startDate: now.toDate(),
              facturacion: this.step2.proveedorPago,
              renovable: true,
              provincias: JSON.stringify(provincias),
              paises: JSON.stringify(paises),
              tarifa: this.step2.proveedorTarifa,
              precio: precio,
              user: this.authenticationService.currentUserValue.id,
              pagado: false,
              stripeProd: tarifaStripe
            };
            console.log(susc);
            this.suscriptionService.post(susc)
            .pipe(first())
            .subscribe(
              data => {
                console.log("OK", data);
                currentUser.tarifa = data;
                this.authenticationService.changeUser(currentUser);
                this.router.navigate(['/confirmacion']);
              },
              error => {
                console.error(error);
                this.loading = false;
              });
          } else {
            var alta = "";
            if(this.step2.ofertante) alta += "Ofertante";
            if(this.step2.ofertante && this.step2.proveedor) alta += " y ";
            if(this.step2.proveedor) alta += "Proveedor";
            alta += " de traslados y servicios funerarios";
            /*this.http.post(`${environment.apiUrl}/mailregistro`, {destinatario: currentUser.email, empresa: form.empresa, actividad: form.actividad, alta: alta, tarifa: null, fecha: null, hash: currentUser.hashUser})
            .pipe(first())
            .subscribe(
              data => {
                console.log("OK MAIL", data);
              },
              error => {
                console.error(error);
                this.loading = false;
              });*/

              
            if(this.wiz1Form.value.notif_telegram) {
              this.userService.confirmacionTelegram({code: this.stepMovil.telegram})
                .pipe(first())
                .subscribe(
                  data => {
                  },
                  error => {
                    console.log(error);
                });
            }
            this.authenticationService.changeUser(currentUser);
            this.router.navigate(['/confirmacion']);
          }
        },
        error => {
          console.error(error);
          this.loading = false;
        });
	}

	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasErrorWiz1(controlName: string, validationType: string): boolean {
		const control = this.wiz1Form.controls[controlName];
		if (!control || !this.submittedWiz1) {
			return false;
		}

		if(!validationType) {
			const result = control.invalid && (control.dirty || control.touched);
			return result;
		} else {
			const result = control.hasError(validationType) && (control.dirty || control.touched);
			return result;
		}
  }
  
  //--------------- STEP VALIDACION MOVIL ---------------

  stepMovil = {sms: null, telegram: null};

  //--------------- STEP 2 ---------------

  step2 = {ofertante: false, ofertanteConfirm: true, proveedor: false, proveedorTarifa: null, proveedorPago: null};

  errors2 = {option: false, ofertanteTos: false, proveedorTarifa: false, proveedorPago: false};

  tarifa: any;

  //--------------- STEP 2b ---------------

  provinciasSel = [];
  paisesSel = [];

  //--------------- STEP 3 ----------------

  ibanForm = null;
  step3 = {titular: null, iban: null};
  errors3 = {titular: false, iban: false, ibanFormat: false};

  // ---------- AYUDA LLAMADA -------------

  ayudaEnviada = false;
  ayudaNombre = null;
  ayudaTelefono = null;

  enviarAyuda() {
    this.ayudaEnviada = true;
    this.cdr.detectChanges();
    this.notificacionService.sendAyuda({nombre: this.ayudaNombre, telefono: this.ayudaTelefono})
      .pipe(first())
      .subscribe(
        data => {
          console.log(data);
        },
        error => {
          console.error(error);
      });
  }
}
