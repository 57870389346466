import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { first } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import { UserService, AuthenticationService, PaymentService, SuscriptionService } from '../../../_services';

import { environment } from '../../../../environments/environment';

declare var Stripe: any;

@Component({
  selector: 'kt-pago',
  templateUrl: './pago.component.html',
  styleUrls: ['./pago.component.scss']
})
export class PagoComponent implements OnInit {

  //stripe;

  constructor(private route: ActivatedRoute,
    private paymentService: PaymentService,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private router: Router,
    private suscriptionService: SuscriptionService,
    private cdr: ChangeDetectorRef) { 
    }

  hash = null;
  email = null;
  loading = true;
  resultado = false;
  success = false;
  currentUser = null;
    

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.currentUser = this.authenticationService.currentUserValue;
      var estado = params.get("estado");
      if(estado) this.loadResultado(estado)
      else this.loadPago();
    });

    
  }

  loadResultado(estado: any) {
    this.resultado = true;
    if(estado == "success") {
      this.success = true;
      //Comprobamos si el sessionid es correcto
      this.route.queryParams.subscribe(params => {
        var session_id = params["session_id"];

        //Si existe la sesión...
        this.paymentService.getSession(session_id)
        .subscribe(
          session => {
            //Si existe la suscripción y está activa...
            this.paymentService.getSubscription(session["subscription"])
            .subscribe(
              subscription => {
                console.warn(subscription);
                if(subscription["status"] == "active") {
                  var suscrip = this.currentUser.suscripciones[this.currentUser.suscripciones.length-1];
                  var modif = {id: suscrip.id, pagado: true, activa: true, stripeSusc: subscription["id"], startDate: new Date()};
                  this.suscriptionService.update(modif)
                    .pipe(first())
                    .subscribe(
                      data => {
                        console.log("OK", data);
                        this.loading = false;
                        this.success = true;
                        this.cdr.detectChanges();
                      },
                      error => {
                        console.error(error);
                        this.loading = false;
                    });
                } else {
                  this.router.navigate(['/home']);
                }
              },
              error => {
                console.error(error);
                this.router.navigate(['/home']);
            });
            
          },
          error => {
            console.error(error);
            this.router.navigate(['/home']);
        });



        

      });


    } else {
      this.loading = false;
    }
  }

  loadPago() {
    this.userService.getById(this.currentUser.id)
    .subscribe(user => {
      this.currentUser = user;
      console.warn(this.currentUser);
      this.cdr.detectChanges();

      if(this.currentUser.proveedor && this.currentUser.suscripciones.length > 0 && !this.currentUser.suscripciones[this.currentUser.suscripciones.length-1].pagado) {
        //Tiene pago pendiente
        var suscrip = this.currentUser.suscripciones[this.currentUser.suscripciones.length-1];

        var stripe = Stripe(environment.stripe);

        this.paymentService.createCheckoutSession(suscrip.stripeProd, this.currentUser.stripeCustomer).then(function(data) {
          // Call Stripe.js method to redirect to the new Checkout page
          console.warn(data);
          stripe
            .redirectToCheckout({
              sessionId: data.sessionId
            })
            .then(function(res){
              console.warn(res);
            });
        });
      } else {
        this.router.navigate(['/home']);
      }

      
    });
  }


}
