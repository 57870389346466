import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';

import { environment } from '../../environments/environment';
 
@Injectable()
export class UserService {
    constructor(private http: HttpClient) { }

    register(user: any) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json'
          })
        };
        /*const body = new HttpParams()
            .set('email', user.email)
            .set('password', user.password)
            .set('empresa', user.empresa)
            .set('pais', user.pais)
            .set('lang', user.lang)
        return this.http.post(`${environment.apiUrl}/api/users/register`, body.toString(), httpOptions);*/
        return this.http.post(`${environment.apiUrl}/api/users/register`, user, httpOptions);
    }

    check(email: any) {
        return this.http.patch(`${environment.apiUrl}/api/users/check`, { email: email }, {observe: 'response'});
    }

    getById(id: number) {
        return this.http.get(`${environment.apiUrl}/Usuarios/${id}`);
    }

    update(user: any) {
        //console.log("UPDATE", `${environment.apiUrl}/api/users/${user.id}`, user);
        return this.http.patch(`${environment.apiUrl}/Usuarios/${user.id}`, user);
    }

    getBloqueosByUser(user: any) {
        return this.http.get<any>(`${environment.apiUrl}/user_bloqueo?user=${user}`);
    }

    postBloqueo(bloqueo: any) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json'
          })
        };
        return this.http.post(`${environment.apiUrl}/user_bloqueo`, bloqueo, httpOptions);
    }

    deleteBloqueo(id: any) {
        return this.http.delete(`${environment.apiUrl}/user_bloqueo/${id}`);
    }

    checkTelegram(info: any) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json'
          })
        };
        return this.http.post(`${environment.apiUrl}/telegram/registro`, info, httpOptions);
    }

    confirmacionTelegram(info: any) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json'
          })
        };
        return this.http.post(`${environment.apiUrl}/telegram/registro_confirmacion`, info, httpOptions);
    }

    checkPhoneTelegram(info: any) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };
      return this.http.post(`${environment.apiUrl}/telegram/checkPhone`, info, httpOptions);
    }

    confirmacionSMS(info: any) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json'
          })
        };
        return this.http.post(`${environment.apiUrl}/sms/confirmacion`, info, httpOptions);
    }

    checkSMS(info: any) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json'
          })
        };
        return this.http.post(`${environment.apiUrl}/sms/check_code`, info, httpOptions);
    }

    recovery(info: any) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };
      return this.http.post(`${environment.apiUrl}/api/users/recovery`, info, httpOptions);
    }

    recoveryCheck(info: any) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };
      return this.http.post(`${environment.apiUrl}/api/users/recoveryCheck`, info, httpOptions);
    }

    validar(info: any) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };
      return this.http.post(`${environment.apiUrl}/api/users/validar`, info, httpOptions);
    }

    getTextos(lang: any) {
        return this.http.get<any>(`${environment.apiUrl}/Traducciones?where={"CodigoIsoIdioma":"${lang}","CodigoTexto":{"!=":null}}`).toPromise();
    }

    getTextosAlarmas(lang: any, instalacion: any) {
        return this.http.get<any>(`${environment.apiUrl}/Traducciones?where={"CodigoIsoIdioma":"${lang}","InstalacionId":"${instalacion}","IdAlarma":{"!=":null}}`).toPromise();
    }

    getTextosTags(lang: any, instalacion: any) {
        return this.http.get<any>(`${environment.apiUrl}/Traducciones?where={"CodigoIsoIdioma":"${lang}","InstalacionId":"${instalacion}","IdTag":{"!=":null}}`).toPromise();
    }

    getTextosGraficos(lang: any, instalacion: any) {
        return this.http.get<any>(`${environment.apiUrl}/Traducciones?where={"CodigoIsoIdioma":"${lang}","InstalacionId":"${instalacion}","IdGrafico":{"!=":null}}`).toPromise();
    }
 
    /*getAll() {
        return this.http.get<any[]>(this.api + '/usuarios');
    }

    getByGroup(grupo: number) {
        return this.http.get<any[]>(this.api + '/usuariosgrupo/' + grupo);
    }
 
    getById(id: number) {
        return this.http.get<any>(this.api + '/usuario/' + id);
    }
 
    

    tos(user: User) {
        return this.http.put(this.api + '/api/users/' + user.id, {tos: true});
    }
 
    update(user: any) {
        return this.http.put(this.api + '/api/users/' + user.id, user);
    }
 
    delete(id: number) {
        return this.http.delete(this.api + '/api/users/' + id);
    }*/
}