import { Component, Input, OnInit, ViewChild, ViewChildren, ElementRef, ChangeDetectorRef, NgZone, HostListener } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { first } from 'rxjs/operators';

import { columnsTotalWidth, NgxDatatableModule } from '@swimlane/ngx-datatable';

import { AuthenticationService, StateService, SeccionService, MenuService, CountryService, LicitaService, UserService, TrazabilidadService, NotificacionService, EtapaService, InstalacionService } from '../../../_services';

import { DateTimeAdapter } from 'ng-pick-datetime';
import { HttpClient } from '@angular/common/http';

import { interval, Subscription, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

import * as moment from 'moment';
import{ GlobalVariables } from '../../../_common/global-variables';

@Component({
  selector: 'kt-notificaciones',
  templateUrl: './notificaciones.component.html',
  styleUrls: ['./notificaciones.component.scss']
})
export class NotificacionesComponent implements OnInit {

  currentUser = null;
  instalacion = null;
  loading = true;
  rowsAlarmas = [];
  rows = [];
  txt = {} as any;
  txtAlarma = {} as any;

  filtro = {inicio: null, inicioHora: null, fin: null, finHora: null, activa: false};

  constructor(
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private userService: UserService,
    private instalacionService: InstalacionService,
    private cdr: ChangeDetectorRef,
    dateTimeAdapter: DateTimeAdapter<any>) { 
      dateTimeAdapter.setLocale('es-ES');

      this.txt = GlobalVariables.textos;
      if(Object.keys(this.txt).length == 0) {
        setTimeout(function() {
          this.txt = GlobalVariables.textos;
          this.cdr.detectChanges();
          if(Object.keys(this.txt).length == 0) {
            setTimeout(function() {
            this.txt = GlobalVariables.textos;
            this.cdr.detectChanges();
            }.bind(this), 2000);
          }
        }.bind(this), 3000);
      }   
    }

  ngOnInit() {
    this.currentUser = this.authenticationService.currentUserValue;

    this.route.paramMap.subscribe(async params => {
      var instalacionId = params.get("id");
      this.instalacion = await this.instalacionService.getByIdAsync(instalacionId);
      /*for(let inst of this.currentUser.instalaciones){
        if(inst.ID == instalacionId) {
          this.instalacion = inst;
          break;
        }
      }*/
      if(Object.keys(this.txt).length == 0) {
        setTimeout(function() {
          this.loadAlarmas();
        }.bind(this), 3000);
      } else {
        this.loadAlarmas();
      }
    })
    this.cdr.detectChanges();
  }

  loadAlarmas() {
    this.instalacionService.getAlarmas(this.instalacion.BaseDatosWeb, 0)
      .subscribe(async alarmas => {
        if(alarmas) {
          for(let alarma of alarmas) {
            if(alarma.Importancia == 0) {
              alarma.tipo = this.txt.alarma_verde;
            } else if(alarma.Importancia == 1) {
              alarma.tipo = this.txt.alarma_naranja;
            } else if(alarma.Importancia == 2) {
              alarma.tipo = this.txt.alarma_rojo;
            }
            if(alarma.FechaRegistro) alarma.FechaRegistroStr = moment(alarma.FechaRegistro).format("DD/MM/YYYY HH:mm");
            if(alarma.FechaFinalizacion) alarma.FechaFinalizacionStr = moment(alarma.FechaFinalizacion).format("DD/MM/YYYY HH:mm");
  
            this.txtAlarma[alarma.AlarmaId + ""] = alarma.Descripcion;
          }
  
          let textosAlarma = await this.userService.getTextosAlarmas(this.currentUser.CodigoIsoIdioma, this.instalacion.ID);
          for(let texto of textosAlarma) {
            this.txtAlarma[texto.IdAlarma + ""] = texto.Traduccion;
          }
          this.rowsAlarmas = alarmas;
        }
        
        this.filterDatatable();

        this.loading = false;
        this.cdr.detectChanges();
      });
  }

  filterDatatable(){
    this.rows = [];
    this.cdr.detectChanges();

    this.rows = this.rowsAlarmas.filter(function(item){
      var mostrar = true;

      if(this.filtro.inicio && moment(item.FechaRegistro) < moment(this.filtro.inicio)) mostrar = false;
      if(this.filtro.fin && moment(item.FechaRegistro) > moment(this.filtro.fin)) mostrar = false;
      if(this.filtro.activa && item.FechaFinalizacion) mostrar = false;
      
      // iterate through each row's column data
      return mostrar;
    }.bind(this));
    
    var temp = [...this.rows];
    this.rows = [];
    this.rows = temp;
  
    this.cdr.detectChanges();
  }

  resetFilters() {
    this.filtro = {inicio: null, inicioHora: null, fin: null, finHora: null, activa: false};
    this.filterDatatable();
  }

}
