import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import{ GlobalVariables } from '../_common/global-variables';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    public currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<any>;

    constructor(private router: Router,
        private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
        //this.logout();
    }

    public get currentUserValue(): any {
        return this.currentUserSubject.value;
    }

    changeUser(user: any) {
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
    }

    login(email: string, password: string) {
        const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json'
            })
          };
        return this.http.patch<any>(`${environment.apiUrl}/api/users/login`, { email: email, password: password }, httpOptions)
            .pipe(map(async user => {
                if (user && user["token"]) {
                    localStorage.removeItem('filtros');
                    localStorage.removeItem('orden');
                    localStorage.removeItem('ordenMostrar');
                    GlobalVariables.filters = {};
                    GlobalVariables.orden = {};

                    localStorage.setItem('currentUser', JSON.stringify(user));
                    
                    this.currentUserSubject.next(user);
                    //this.router.navigate(['/home']);
                } else {
                    console.error("ERROR", user);
                }
 
                return user;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
        this.router.navigate(['/auth/login']);
    }
}