import { Component, Input, OnInit, ViewChild, ViewChildren, ElementRef, ChangeDetectorRef, NgZone, HostListener } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { first } from 'rxjs/operators';

import { columnsTotalWidth, NgxDatatableModule } from '@swimlane/ngx-datatable';

import { AuthenticationService, StateService, SeccionService, MenuService, CountryService, LicitaService, UserService, TrazabilidadService, NotificacionService, EtapaService, InstalacionService } from '../../../_services';

import { DateTimeAdapter } from 'ng-pick-datetime';
import { HttpClient } from '@angular/common/http';

import { interval, Subscription, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

import * as logoFile from './logo.js';
import * as moment from 'moment';
import{ GlobalVariables } from '../../../_common/global-variables';
import * as ExcelJS from "exceljs/dist/exceljs.min.js";

@Component({
  selector: 'kt-instalacion',
  templateUrl: './instalacion.component.html',
  styleUrls: ['./instalacion.component.scss']
})
export class InstalacionComponent implements OnInit {

  currentUser = null;
  rows = [];
  seccionId = null;
  instalacion = null;
  seccion = null;
  tags = [];
  graficos = [];
  grupos = [];
  secciones = [];
  loadingInit = true;
  loadingGrafica = false;
  loadingIndicadores = false;
  indicadoresCargados = false;
  txt = {} as any;
  txtTags = {} as any;
  txtGrafico = {} as any;

  fecha = {inicio: new Date(), inicioHora: null, fin: new Date(), finHora: null};
  fechaInd = {inicio: null, inicioHora: null, fin: null, finHora: null};

  vista = "listado";
  detalle = "monitorizacion";

  single = [
    {
      "name": "Item 1",
      "value": 8940000
    },
    {
      "name": "Item 2",
      "value": 5000000
    },
    {
      "name": "Item 3",
      "value": 7200000
    },
  ]

  // options
  gradient: boolean = false;
  showLegend: boolean = true;
  showLabels: boolean = false;
  isDoughnut: boolean = true;
  legendPosition: string = 'below';

  colorScheme = "forest";

  constructor(private router: Router,
    private modalService: NgbModal,
    private authenticationService: AuthenticationService,
    private cdr: ChangeDetectorRef,
    private seccionService: SeccionService,
    private etapaService: EtapaService,
    private userService: UserService,
    private ngZone: NgZone,
    private instalacionService: InstalacionService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    dateTimeAdapter: DateTimeAdapter<any>) {   
      dateTimeAdapter.setLocale('es-ES');

      this.txt = GlobalVariables.textos;
      if(Object.keys(this.txt).length == 0) {
        setTimeout(function() {
          this.txt = GlobalVariables.textos;
          this.cdr.detectChanges();
          if(Object.keys(this.txt).length == 0) {
            setTimeout(function() {
              this.txt = GlobalVariables.textos;
              this.cdr.detectChanges();
            }.bind(this), 2000);
          }
        }.bind(this), 3000);
      }  
    }

  _arrayBufferToBase64( buffer ) {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
  }

  ngOnInit() {
    this.currentUser = this.authenticationService.currentUserValue;

    this.route.paramMap.subscribe(params => {
      this.seccionId = params.get("seccion");
      this.loadingInit = true;
      this.rows = [];
      this.row = null;
      this.vista = "listado";
      this.detalle = "monitorizacion";
      this.cdr.detectChanges();
      this.loadSeccion();
    })
    this.cdr.detectChanges();
  }

  loadSeccion() {
    this.seccionService.getById(this.seccionId)
      .subscribe(seccion => {
        for(let texto of seccion.textos) {
          if(texto.CodigoIsoIdioma == this.currentUser.CodigoIsoIdioma) {
            seccion.Denominacion = texto.Traduccion;
            break;
          }
        }
        this.seccion = seccion;
        
        this.instalacion = this.seccion.InstalacionID;
        this.cdr.detectChanges();
        this.loadEtapas();
      });
  }

  loadEtapas() {
    this.etapaService.getAllBySeccion(this.seccionId)
    .subscribe(secciones => {
      this.rows = secciones;
      for(let etapa of this.rows) {
        for(let texto of etapa.textos) {
          if(texto.CodigoIsoIdioma == this.currentUser.CodigoIsoIdioma) {
            etapa.Nombre = texto.Traduccion;
            break;
          }
        }
      }
      if(this.rows.length == 1) {
        this.row = this.rows[0];
        this.vista = "detalle";
        this.detalle = "monitorizacion";
        this.cargarImagen();
        this.cargarTags();
        this.cargarIndicadores();
        this.cdr.detectChanges();
      }
      this.loadingInit = false;
      this.cdr.detectChanges();
    });
  }

  row: any;
  loadingRow = false;

  onSelect(event: any) {
    if(event.type == "click" && !this.loadingRow){
      event.cellElement.blur();
      this.row = event.row;
      this.vista = "detalle";
      this.detalle = "monitorizacion";
      this.cargarImagen();
      this.cargarTags();
      this.cargarIndicadores();
      this.cdr.detectChanges();
    }
  }

  selectMenu(menu: any) {
    this.detalle = menu;
    this.cdr.detectChanges();
  }

  cargarImagen() {
    this.instalacionService.getEsquema(this.row.Id, this.instalacion.BaseDatosWeb)
    .subscribe(esquema => {
      if(esquema.length > 0) {
        this.row.Esquema64 = this.sanitizer.bypassSecurityTrustUrl('data:image/jpg;base64, ' + esquema[0].Esquema);
        this.cdr.detectChanges();
      }
    });
  }

  async cargarTags() {
    this.tags = [];
    this.grupos = [];
    var grupos = {};
    this.secciones = [];
    var secciones = {};
    let textos = await this.userService.getTextosTags(this.currentUser.CodigoIsoIdioma, this.instalacion.ID);
    for(let texto of textos) {
      this.txtTags[texto.IdTag.Id + ""] = texto;
    }

    for(let tag of this.row.tags) {
      if(tag.VisibleWeb) {
        if(this.txtTags[tag.Id + ""] && this.txtTags[tag.Id + ""].TraduccionSeccion) tag.Seccion = this.txtTags[tag.Id + ""].TraduccionSeccion;
        if(this.txtTags[tag.Id + ""] && this.txtTags[tag.Id + ""].TraduccionGrupo) tag.Grupo = this.txtTags[tag.Id + ""].TraduccionGrupo;
        if(this.txtTags[tag.Id + ""] && this.txtTags[tag.Id + ""].Traduccion) tag.Etiqueta = this.txtTags[tag.Id + ""].Traduccion;


        this.tags.push(tag);
        if(!secciones[tag.Seccion]) {
          secciones[tag.Seccion] = {nombre: tag.Seccion, grupos: [], gruposHash: {}, OrdenSeccion: tag.OrdenSeccion};
        }
        //secciones[tag.Seccion].grupos.push(tag);

        if(!secciones[tag.Seccion].gruposHash[tag.Grupo]) {
          secciones[tag.Seccion].gruposHash[tag.Grupo] = {nombre: tag.Grupo, tags: [], OrdenGrupo: tag.OrdenGrupo};
        }
        secciones[tag.Seccion].gruposHash[tag.Grupo].tags.push(tag);
      }
    }
    for(let seccion in secciones) {
      for(let grupo in secciones[seccion].gruposHash) {
        secciones[seccion].grupos.push(secciones[seccion].gruposHash[grupo]);
      }
      this.secciones.push(secciones[seccion]);
    }
    this.cdr.detectChanges();
    this.instalacionService.getTagsLast(this.instalacion.TablaRemotaVariables, this.instalacion.BaseDatosWeb)
    .subscribe(tags1 => {
      if(tags1.length > 0) {
        var tags = tags1[0];

        for(let seccion of this.secciones) {
          for(let grupo of seccion.grupos) {
            for(let tag of grupo.tags) {
              tag.valor = tags[tag.TokenTAG];
            }
            grupo.tags.sort(function (a, b) {
              if (a.OrdenEtiqueta < b.OrdenEtiqueta) {
                return -1;
              } else if (a.OrdenEtiqueta > b.OrdenEtiqueta) {
                return 1;
              } else {
                return 0;
              }
            }.bind(this));
          }

          seccion.grupos.sort(function (a, b) {
            if (a.OrdenGrupo < b.OrdenGrupo) {
              return -1;
            } else if (a.OrdenGrupo > b.OrdenGrupo) {
              return 1;
            } else {
              return 0;
            }
          }.bind(this));
        }
        
        this.secciones.sort(function (a, b) {
          if (a.OrdenSeccion < b.OrdenSeccion) {
            return -1;
          } else if (a.OrdenSeccion > b.OrdenSeccion) {
            return 1;
          } else {
            return 0;
          }
        }.bind(this));
        this.cdr.detectChanges();
      }
    });
  }

  customizeLabel(arg) {
    return arg.argumentText + " (" + arg.percentText + ")";
  }

  medals: any[] = [{
    country: "USA",
    medals: 112
  }, {
      country: "China",
      medals: 40
  }]

  async cargarIndicadores(){
    this.graficos = [];
    this.tagsGraph = [];
    this.tagsGraph2 = [];
    this.tagsHash = {};
    this.resumen = {};
    this.resumenTabla = [];

    let textos = await this.userService.getTextosGraficos(this.currentUser.CodigoIsoIdioma, this.instalacion.ID);
    for(let texto of textos) {
      this.txtGrafico[texto.IdGrafico.Id + ""] = texto;
    }

    for(let grafico of this.row.graficos) {
      if(grafico.VisibleWeb) {
        if(this.txtGrafico[grafico.Id + ""]) grafico.Descripcion = this.txtGrafico[grafico.Id + ""].Traduccion;
        grafico.datos = [{label: "ON", valor: 0},{label: "OFF", valor: 0}];
        this.graficos.push(grafico);
      }
    }
    this.graficos.sort(function (a, b) {
      if (a.Orden < b.Orden) {
        return -1;
      } else if (a.Orden > b.Orden) {
        return 1;
      } else {
        return 0;
      }
    }.bind(this));
    this.cdr.detectChanges();
  }

  descargaIndicadores() {
    if(!this.fechaInd.inicio || !this.fechaInd.fin) return;
    this.loadingIndicadores = true;
    this.cdr.detectChanges();
    var columnas = "";
    
    for(let grafico of this.graficos) {
      var token = grafico.TokenTAG;
      token = token.replaceAll("[", "").replaceAll("]", "").replaceAll("IIF", "IF");
      columnas += token + ",";
      grafico.token = token;
      grafico.datos = [{label: "ON", valor: 0},{label: "OFF", valor: 0}];
    }
    columnas = columnas.slice(0, -1);
    var inicio = moment(this.fechaInd.inicio).format("YYYY-MM-DD");
    inicio += " " + moment(this.fechaInd.inicioHora || new Date()).utc().format("HH:mm:ss");
    var fin = moment(this.fechaInd.fin).format("YYYY-MM-DD");
    fin += " " + moment(this.fechaInd.finHora || new Date()).utc().format("HH:mm:ss");

    this.instalacionService.getTags(this.instalacion.TablaRemotaVariables, this.instalacion.BaseDatosWeb, inicio, fin, columnas)
    .subscribe(graficosRes => {
      for(let res of graficosRes) {
        for(let graf of this.graficos) {
          if(res[graf.token] == 1) graf.datos[0].valor++
          else graf.datos[1].valor++;
        }
      }

      var temp = [...this.graficos];
      this.graficos = [];
      this.graficos = temp;
      
      this.loadingIndicadores = false;
      this.indicadoresCargados = true;
      this.cdr.detectChanges();
    });
  }

  tagsHash = {};
  resumen = {};
  resumenTabla = [];
  etiquetasHash = {};
  etiquetasHashComp = {};
  valores = [];

  cargarRegistro() {
    if(!this.fecha.inicio || !this.fecha.fin) return;
    this.loadingGrafica = true;
    this.cdr.detectChanges();
    var columnas = "";
    var enc = false;
    this.tagsGraph = [];
    this.tagsGraph2 = [];
    this.valores = [];
    this.tagsHash = {};
    this.resumen = {};
    this.resumenTabla = [];
    
    for(let tag of this.tags) {
      if(tag.sel) {
        columnas += tag.TokenTAG + ",";
        enc = true;

        if(!tag.Unidades || tag.Unidades != "on/off") {
          this.tagsGraph.push({
            value: tag.TokenTAG,
            name: tag.Grupo + ". " + tag.Etiqueta + " (" + tag.Unidades + ")"
          });
        } else {
          this.tagsGraph2.push({
            value: tag.TokenTAG,
            name: tag.Grupo + ". " + tag.Etiqueta + " (" + tag.Unidades + ")"
          });
        }


        this.etiquetasHash[tag.TokenTAG] = tag.Etiqueta;
        this.etiquetasHashComp[tag.TokenTAG] = tag.Grupo + ". " + tag.Etiqueta + " (" + tag.Unidades + ")";

        this.resumen[tag.Etiqueta+""] = {
          nombre: tag.Etiqueta,
          uds: tag.Unidades,
          min: null,
          max: null,
          media: 0,
          count: 0,
          suma: 0,
          var: null,
          primer: null,
          ultimo: null
        }
      }
    }

    if(enc) {
      columnas = columnas.slice(0, -1);
      var inicio = moment(this.fecha.inicio).format("YYYY-MM-DD");
      console.warn(moment(this.fecha.inicioHora || new Date()).format("HH"));
      if((moment(this.fecha.inicioHora || new Date()).utc().format("HH") == "22" && moment(this.fecha.inicioHora || new Date()).format("HH") == "00") || moment(this.fecha.inicioHora || new Date()).utc().format("HH") == "23"){
        inicio = moment(this.fecha.inicio).subtract(1, 'days').format("YYYY-MM-DD");
        console.warn(inicio);
      }
      
      inicio += " " + moment(this.fecha.inicioHora || new Date()).utc().format("HH:mm:ss");
      var fin = moment(this.fecha.fin).format("YYYY-MM-DD");
      if((moment(this.fecha.finHora|| new Date()).utc().format("HH") == "22" && moment(this.fecha.finHora|| new Date()).format("HH") == "00") || moment(this.fecha.finHora || new Date()).utc().format("HH") == "23") 
        fin = moment(this.fecha.fin).subtract(1, 'days').format("YYYY-MM-DD");

      fin += " " + moment(this.fecha.finHora || new Date()).utc().format("HH:mm:ss");

      this.instalacionService.getTags(this.instalacion.TablaRemotaVariables, this.instalacion.BaseDatosWeb, inicio, fin, columnas)
      .subscribe(tags1 => {
        
        this.valuesGraph = [];

        for(let tag of tags1) {
          var obj = {fecha: moment(tag.DateTimeField).format("DD/MM/YYYY HH:mm")};
          if(!this.tagsHash[obj.fecha]) {
            this.tagsHash[obj.fecha] = true;
            var objAll = {} as any;
            objAll.fecha = obj.fecha;

            for(let item in tag) {
              if(item != "DateTimeField") {
                var etiqueta = this.etiquetasHash[item];
                obj[item] = tag[item];
                objAll[this.etiquetasHashComp[item]] = tag[item]; 

                if(this.resumen[etiqueta].uds == "on/off" || tag[item] != 0) {
                  if(this.resumen[etiqueta].primer == null)  this.resumen[etiqueta].primer = tag[item];
                
                  this.resumen[etiqueta].ultimo = tag[item];
  
                  if(this.resumen[etiqueta].min == null || this.resumen[etiqueta].min > tag[item]) this.resumen[etiqueta].min = tag[item];
                  if(this.resumen[etiqueta].max == null || this.resumen[etiqueta].max < tag[item]) this.resumen[etiqueta].max = tag[item];
                  
                  
                  this.resumen[etiqueta].count++;
                  this.resumen[etiqueta].suma += tag[item];
                }

                
              }
            }

            this.valores.push(objAll);

            this.valuesGraph.push(obj);
          }
          
        }

        for(let item in this.resumen){
          var valor = this.resumen[item];
          valor["media"] = valor.suma / valor.count;
          if(valor.primer != null && valor.ultimo != null) valor.var = valor.ultimo - valor.primer;
          this.resumenTabla.push(valor);
        }
        console.warn(this.resumen);

        
        this.loadingGrafica = false;
        this.cdr.detectChanges();
      });
    } else {
      this.loadingGrafica = false;
      this.cdr.detectChanges();
    }


  }

  //BAR

  // options
  showXAxis = true;
  showYAxis = true;
  gradient2 = false;
  showLegend2 = true;
  showXAxisLabel = true;
  xAxisLabel = 'Items A';
  showYAxisLabel = true;
  rotateXAxisTicks = true;
  yAxisLabel = 'Items B';
  timeline: boolean = true;

  colorScheme2 = {
    domain: ['#007A53']
  };

  tagsGraph: any[] = [];
  tagsGraph2: any[] = [];

  customizeTooltip(arg: any) {
    var items = arg.valueText.split("\n"),
      color = arg.point.getColor();
    items.forEach(function(item, index) {
      if(item.indexOf(arg.seriesName) === 0) {
        var element = document.createElement("span");

        element.textContent = item;
        element.style.color = color;
        element.className = "active";

        items[index] = element.outerHTML;
      }
    });
    return { text: "<b>" + arg.argumentText + "</b><br/>" + items.join("\n") };
  }

  valuesGraph: any[]  = [{
      country: "USA",
      hydro: 59.8,
      oil: 937.6,
      gas: 582,
      coal: 564.3,
      nuclear: 187.9
  }, {
      country: "China",
      hydro: 74.2,
      oil: 308.6,
      gas: 35.1,
      coal: 956.9,
      nuclear: 11.3
  }, {
      country: "Russia",
      hydro: 40,
      oil: 128.5,
      gas: 361.8,
      coal: 105,
      nuclear: 32.4
  }, {
      country: "Japan",
      hydro: 22.6,
      oil: 241.5,
      gas: 64.9,
      coal: 120.8,
      nuclear: 64.8
  }, {
      country: "India",
      hydro: 19,
      oil: 119.3,
      gas: 28.9,
      coal: 204.8,
      nuclear: 3.8
  }, {
      country: "Germany",
      hydro: 6.1,
      oil: 123.6,
      gas: 77.3,
      coal: 85.7,
      nuclear: 37.8
  }];

  loadingExcel = false;

  async exportar() {
    if(this.loadingExcel) return;
    this.loadingExcel = true;
    this.cdr.detectChanges();

    let workbook = new ExcelJS.Workbook();
    let worksheet = workbook.addWorksheet('Registro de Instalación');
    worksheet.getColumn(1).width = 20;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 15;
    worksheet.getColumn(4).width = 15;
    worksheet.getColumn(5).width = 20;
    worksheet.getColumn(6).width = 20;
    worksheet.getColumn(7).width = 20;
    worksheet.getColumn(8).width = 20;
    worksheet.getColumn(9).width = 20;
    worksheet.getColumn(10).width = 20;
    worksheet.getColumn(11).width = 20;

    let logo = workbook.addImage({
      base64: logoFile.logoBase64,
      extension: 'png',
    });
    var image = worksheet.addImage(logo, 'A1:B5');

    const cell = worksheet.getCell('D2');
    cell.value = 'Registro de Instalación';
    cell.font = { size: 16, bold: true };

    worksheet.getCell('F2').value = 'Fecha Informe: ';
    worksheet.getCell('F2').font = { bold: true };
    worksheet.getCell('G2').value = moment().format("DD/MM/YYYY HH:mm");

    var inicio = moment(this.fecha.inicio).format("DD/MM/YYYY");
    inicio += " " + moment(this.fecha.inicioHora || new Date()).format("HH:mm:ss");
    var fin = moment(this.fecha.fin).format("DD/MM/YYYY");
    fin += " " + moment(this.fecha.finHora || new Date()).format("HH:mm:ss");

    worksheet.getCell('D4').value = 'Fecha Inicio: ';
    worksheet.getCell('D4').font = { bold: true };
    worksheet.getCell('E4').value = inicio;
    worksheet.getCell('D5').value = 'Fecha Fin: ';
    worksheet.getCell('D5').font = { bold: true };
    worksheet.getCell('E5').value = inicio;

    worksheet.getCell('F4').value = 'Instalación: ';
    worksheet.getCell('F4').font = { bold: true };
    worksheet.getCell('G4').value = this.instalacion.NombreInstalacion;
    worksheet.getCell('F5').value = 'Sección: ';
    worksheet.getCell('F5').font = { bold: true };
    worksheet.getCell('G5').value = this.seccion.Denominacion;

    worksheet.addRow([]);
    worksheet.addRow([]);

    var columns = ['Fecha'];
    for(let item in this.valores[0]) {
      if(item != "fecha") {
        columns.push(item);
      }
    }

    let titleRow = worksheet.addRow(columns);
    // Set font, size and style in title row.
    titleRow.font = { bold: true };

    /*data.sort((a, b) => (moment(a.fechaIn) < moment(b.fechaIn)) ? 1 : -1);*/
    /*for(let item of this.resumenTabla) {
      worksheet.addRow([item.nombre, item.uds, item.min, item.max, item.media]);
    }*/
    
    for(let linea of this.valores) {
      let row = [linea.fecha];
      for(let item in linea) {
        if(item != "fecha") {
          row.push(linea[item]);
        }
      }
      worksheet.addRow(row);
    }


    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      var element = document.createElement('a');
      element.href = window.URL.createObjectURL(blob);
      element.setAttribute('download', 'Registro_' + this.instalacion.NombreInstalacion + '-' + this.seccion.Denominacion +  '_' + moment().format("DDMMYYYYHHmmSS") + '.xlsx');

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);

      this.loadingExcel = false;
      this.cdr.detectChanges();
    });

    
  }


}
