import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';

import { first } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';

import { UserService, AuthenticationService, StateService, CountryService, TarifaService, SuscriptionService, PaymentService } from '../../../_services';

import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

import * as moment from 'moment';
declare var Stripe: any;

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'kt-suscripcion',
  templateUrl: './suscripcion.component.html',
  styleUrls: ['./suscripcion.component.scss']
})
export class SuscripcionComponent implements OnInit {

  currentUser: any;
  loading = false;
  loadingPaymentMethod = false;
  submitted = false;
  pagosPendientes = false;
  pago = null;
  cambio = null;

  estados = [];
  bloqueos = [];
  estadosAll = [];
  estadosHash = {};

  paises = [];
  paisesHash = {};

  datosForm: FormGroup;

  @ViewChild('confirmationSwal', {static: false}) private confirmationSwal: SwalComponent
  @ViewChild('errorFileSwal', {static: false}) private errorFileSwal: SwalComponent
  @ViewChild('errorNotFileSwal', {static: false}) private errorNotFileSwal: SwalComponent
  @ViewChild('confirmationFileSwal', {static: false}) private confirmationFileSwal: SwalComponent
  @ViewChild('errorSwal', {static: false}) private errorSwal: SwalComponent
  @ViewChild('cancelarSuscripSwal', {static: false}) private cancelarSuscripSwal: SwalComponent
  @ViewChild('cancelarSuscripOkSwal', {static: false}) private cancelarSuscripOkSwal: SwalComponent
  @ViewChild('tarifaCambiadaSwal', {static: false}) private tarifaCambiadaSwal: SwalComponent
  @ViewChild('errorTelegramSwal', {static: false}) private errorTelegramSwal: SwalComponent
  @ViewChild('cambioLocSwal', {static: false}) private cambioLocSwal: SwalComponent
  @ViewChild('borrarCambioSwal', {static: false}) private borrarCambioSwal: SwalComponent

  vista = "datos";

  msg_bloqueo = {
    'emptyMessage': 'No hay bloqueos',
    'totalMessage': 'total'
  };
  msg_pagos = {
    'emptyMessage': 'No hay pagos',
    'totalMessage': 'total'
  };

  private unsubscribe: Subject<any>; 

  constructor(private authenticationService: AuthenticationService,
    private fb: FormBuilder,
    private userService: UserService,
    private cdr: ChangeDetectorRef,
	  private modalService: NgbModal,
    private stateService: StateService,
    private paymentService: PaymentService,
    private tarifaService: TarifaService,
    private suscriptionService: SuscriptionService,
    private route: ActivatedRoute,
    private countryService: CountryService) { 
      this.unsubscribe = new Subject();
    }

    openCentred(content) {
      this.modalService.open(content, { centered: true, size: 'lg' } ); 
    }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      if(params.get("tab")) this.vista = params.get("tab");
    });
    
    var token = this.authenticationService.currentUserValue.token;

    this.datosForm = this.fb.group({
      email: [null, Validators.compose([Validators.required])],
      cif: [null, Validators.compose([Validators.required])],
      licencia: [null],
      domicilio: [null, Validators.compose([Validators.required])],
      poblacion: [null, Validators.compose([Validators.required])],
      cp: [null, Validators.compose([Validators.required])],
      telefono: [null, Validators.compose([Validators.required])],
      telefono2: [null],
      pais: [null, Validators.compose([Validators.required])],
      provincia: [null, Validators.compose([Validators.required])],
      empresa: [null, Validators.compose([Validators.required])],
      actividad: [null, Validators.compose([Validators.required])],
      paginaweb: [null],
      notif_email: [null],
      notif_telegram: [null],
    });

    this.userService.getById(this.authenticationService.currentUserValue.id)
    .subscribe(user => {
      this.currentUser = user;
      this.currentUser.token = token;
      this.cdr.detectChanges();
      this.authenticationService.changeUser(this.currentUser);

      //this.currentUser = this.authenticationService.currentUserValue;
      console.warn(this.currentUser);
      if(this.currentUser.suscripciones)
        for(let suscrip of this.currentUser.suscripciones) {
          suscrip.startDateStr = moment(suscrip.startDate).format("DD/MM/YYYY");
          suscrip.endDateStr = moment(suscrip.endDate).format("DD/MM/YYYY");
        }
      this.initForm();
      this.loadProvinciasAll();
      this.loadPaises();
      this.loadBloqueos();
      this.loadTarifas();
      this.loadCertificaciones();
      this.loadCambios();

      this.route.queryParams.subscribe(params => {
        var session_id = params["session_id"];
        if(session_id) {  //Si hay session_id, cargamos los nuevos datos y los asociamos a la suscripcion
          this.loadingPaymentMethod = true;
          this.cdr.detectChanges();
  
          this.paymentService.getSession(session_id)
          .subscribe(
            session => {
              console.warn(session);
              var suscrip = this.currentUser.suscripciones[this.currentUser.suscripciones.length-1];
              var modif = {customer: this.currentUser.stripeCustomer, paymentMethod: session.setup_intent.payment_method, subscription: suscrip.stripeSusc};
              this.paymentService.setPaymentMethod(modif)
                .pipe(first())
                .subscribe(
                  data => {
                    console.log("OK", data);
                    this.loadSuscripcion();
                  },
                  error => {
                    console.error(error);
                    this.loading = false;
                });              
            },
            error => {
              console.error(error);
              this.loadingPaymentMethod = false;
              this.cdr.detectChanges();
          });
        } else {  //Sino, hacemos la carga
          this.loadSuscripcion();
        }
      });
    });
  }

  loadSuscripcion() {
    if(this.currentUser.proveedor && this.currentUser.suscripciones && this.currentUser.suscripciones.length > 0) {
      var suscrip = this.currentUser.suscripciones[this.currentUser.suscripciones.length-1];
      if(!suscrip.pagado) {
        this.pagosPendientes = true;
        this.cdr.detectChanges();
      }
      if(suscrip.stripeSusc) {
        this.paymentService.getSubscription(suscrip.stripeSusc)
          .subscribe(
            subscription => {
              console.warn(subscription);
              if(subscription.status == "active") {
                suscrip.startDateStr = moment(subscription.current_period_start*1000).format("DD/MM/YYYY");
                suscrip.endDateStr = moment(subscription.current_period_end*1000).format("DD/MM/YYYY");
                suscrip.suscObj = subscription;
                this.currentUser.suscripcion = suscrip;

                this.cdr.detectChanges();
                try {
                    if(this.currentUser.suscripcion.provincias) this.currentUser.suscripcion.provinciasObj = JSON.parse(this.currentUser.suscripcion.provincias);
                    if(this.currentUser.suscripcion.paises) this.currentUser.suscripcion.paisesObj = JSON.parse(this.currentUser.suscripcion.paises);
                  } catch (e) {
                }

                //Cogemos los datos de pago
                this.paymentService.getPaymentMethod(subscription.default_payment_method)
                .subscribe(
                  payment => {
                    console.warn(payment);
                    this.pago = payment;
                    this.loadingPaymentMethod = false;
                    this.cdr.detectChanges();
                  });
              }
            });
      }

      if(this.currentUser.stripeCustomer) {
        console.log("INVOICES")
        this.paymentService.getInvoices(this.currentUser.stripeCustomer)
        .subscribe(
          invoices => {
            console.warn(invoices);
            this.currentUser.pagos = invoices.data;
            for(let pago of this.currentUser.pagos) {
              pago.startDateStr = moment(pago.lines.data[0].period.start*1000).format("DD/MM/YYYY");
              pago.endDateStr = moment(pago.lines.data[0].period.end*1000).format("DD/MM/YYYY");
              
            }
          },error => {
            console.error(error);
            this.loadingPaymentMethod = false;
            this.cdr.detectChanges();
        });
      }

      
      
    }
  }

  loadCambios(){
    this.suscriptionService.getCambiosByUser(this.currentUser.id)
    .subscribe(cambios => {
      if(cambios.length > 0) {
        this.cambio = cambios[0];
        if(this.cambio.provincias) this.cambio.provinciasObj = JSON.parse(this.cambio.provincias);
        if(this.cambio.paises) this.cambio.paisesObj = JSON.parse(this.cambio.paises);
        this.cambio.fechaCambio = moment(this.cambio.fechaCambio).format("DD/MM/YYYY HH:00");
        this.cdr.detectChanges();
      }
    });
  }

  cambioVista(vista: any) {
    this.vista = vista;
  }

	/*
    * On destroy
    */
	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
  }

  onChangePais(event){
    this.datosForm.controls["pais"].disable();
    this.datosForm.controls["provincia"].disable();
    this.loadProvincias(event.target.value);
  }

  loadProvincias(pais){
		this.stateService.getAllByCountry(pais)
	    .subscribe(estados => {
        this.estados = estados;
        this.datosForm.controls["pais"].enable();
        this.datosForm.controls["provincia"].enable();
        this.cdr.detectChanges();
      });
  }

  loadProvinciasAll(){
		this.stateService.getAll()
	    .subscribe(estados => {
        this.estadosAll = estados;
        this.cdr.detectChanges();
        for(var i = 0; i < estados.length; i++){
          this.estadosHash[estados[i].id+""] = estados[i];
        }
      });
  }

  loadPaises() {
		this.countryService.getAll()
	    .subscribe(paises => {
			this.paises = paises;
      this.cdr.detectChanges();
      for(var i = 0; i < paises.length; i++){
        this.paisesHash[paises[i].id+""] = paises[i];
      }
		});
	}

  loadPais(){
    this.countryService.getById(this.currentUser.pais.id)
    .subscribe(pais => {
      this.currentUser.paisStr = pais["name"];
      this.cdr.detectChanges();
    });
  }

  tarifas = [];
  tarifasHash = {};
  tarifasHashStripe = {};

  loadTarifas(){
		this.tarifaService.getAll()
	    .subscribe(tarifas => {
        this.tarifas = tarifas;
        this.cdr.detectChanges();
        for(var i = 0; i < tarifas.length; i++){
          this.tarifasHash[tarifas[i].id+""] = tarifas[i];
          this.tarifasHashStripe[tarifas[i].stripeMensual+""] = tarifas[i];
          this.tarifasHashStripe[tarifas[i].stripeAnual+""] = tarifas[i];
        }
      });
  }
  
  initForm() {
    if(this.currentUser.status == "INVITADO") {
      this.datosForm = this.fb.group({
        email: [this.currentUser.email, Validators.compose([Validators.required])],
        cif: [this.currentUser.cif, Validators.compose([Validators.required])],
        empresa: [this.currentUser.empresa, Validators.compose([Validators.required])],
        actividad: [this.currentUser.actividad, Validators.compose([Validators.required])]
      });
    } else {
      this.datosForm = this.fb.group({
        email: [this.currentUser.email, Validators.compose([Validators.required])],
        cif: [this.currentUser.cif, Validators.compose([Validators.required])],
        licencia: [this.currentUser.licencia],
        domicilio: [this.currentUser.domicilio, Validators.compose([Validators.required])],
        poblacion: [this.currentUser.poblacion, Validators.compose([Validators.required])],
        cp: [this.currentUser.cp, Validators.compose([Validators.required])],
        telefono: [this.currentUser.telefono, Validators.compose([Validators.required, Validators.pattern("[0-9]{9,11}")])],
        telefono2: [this.currentUser.telefono2, [Validators.pattern("[0-9]{9,11}")]],
        pais: [this.currentUser.pais.id, Validators.compose([Validators.required])],
        provincia: [this.currentUser.provincia.id, Validators.compose([Validators.required])],
        empresa: [this.currentUser.empresa, Validators.compose([Validators.required])],
        actividad: [this.currentUser.actividad, Validators.compose([Validators.required])],
        paginaweb: [this.currentUser.paginaweb],
        notif_email: [this.currentUser.notif_email],
        notif_telegram: [this.currentUser.notif_telegram]
      });
    }
		
    if(this.currentUser.pais) this.loadProvincias(this.currentUser.pais.id);
  }

  isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.datosForm.controls[controlName];
		if (!control || !this.submitted) {
			return false;
		}

		if(!validationType) {
			const result = control.invalid && (control.dirty || control.touched);
			return result;
		} else {
			const result = control.hasError(validationType) && (control.dirty || control.touched);
			return result;
		}
  }

  error = {notificaciones: false};

  /**
	 * Form Submit
	 */
	onSubmit() {
    if(this.loading) return;

    this.submitted = true;
    
    const controls = this.datosForm.controls;
    // check form
		if (this.datosForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
    }

    this.loading = true;
    this.cdr.detectChanges();

    var continuarGuardado = function (){
      var currentUser = this.authenticationService.currentUserValue;
      var form = this.datosForm.value;
      
      form.id = currentUser.id;    
      
      this.userService.update(form)
        .pipe(first())
        .subscribe(
          data => {
            currentUser.email = form.email;
            if(form.empresa) currentUser.empresa = form.empresa;
            if(form.actividad) currentUser.actividad = form.actividad;
            if(form.cif) currentUser.cif = form.cif;
            if(form.cp) currentUser.cp = form.cp;
            if(form.pais) currentUser.pais = form.pais;
            if(form.domicilio) currentUser.domicilio = form.domicilio;
            if(form.licencia) currentUser.licencia = form.licencia;
            if(form.poblacion) currentUser.poblacion = form.poblacion;
            if(form.provincia) currentUser.provincia = form.provincia;
            if(form.telefono) currentUser.telefono = form.telefono;
  
            this.currentUser = currentUser;
  
            this.authenticationService.changeUser(currentUser);
  
            this.loading = false;
            this.cdr.detectChanges();
            this.confirmationSwal.fire();
          },
          error => {
            console.error(error);
            this.loading = false;
          });
    }.bind(this);
    
    if(this.currentUser.email != this.datosForm.value.email) {
      this.userService.check(this.datosForm.value.email)
        .pipe(first())
        .subscribe(
          data => {
            continuarGuardado();
            this.cdr.detectChanges();
          },
          error => {
            console.log(error);
            this.errorSwal.fire();
            this.loading = false;
            this.cdr.detectChanges();
        });
    } else {
      continuarGuardado();
    }

		
  }
  
  // ---- GESTIÓN TARIFA ----

  tarifaNueva = null;
  loadingCancelar = false;

  cancelarSuscripcion() {
    this.cancelarSuscripSwal.fire();
  }

  cancelarSuscripcion1() {
    this.loadingCancelar = true;
    this.cdr.detectChanges();
    var modif = {id: this.currentUser.suscripcion.stripeSusc, cancel_at_period_end: true};

    this.paymentService.updateSubscription(modif)
    .pipe(first())
      .subscribe(
        subscription => {
          if(subscription.status == "active") {
            this.currentUser.suscripcion.suscObj = subscription;
            this.authenticationService.changeUser(this.currentUser);
            
            this.loadingCancelar = false;
            this.cancelarSuscripOkSwal.fire();
            this.cdr.detectChanges();
          }
        },
        error => {
          console.error(error);
          this.loadingCancelar = false;
        });

    /*var modif = {id: this.currentUser.suscripcion.id, renovable: false};
    this.suscriptionService.update(modif)
      .pipe(first())
      .subscribe(
        data => {
          this.currentUser.suscripcion.renovable = false;

          this.currentUser.suscripciones[this.currentUser.suscripciones.length-1].renovable = false;
          this.authenticationService.changeUser(this.currentUser);

          this.loadingCancelar = false;
          this.cancelarSuscripOkSwal.fire();
          this.cdr.detectChanges();
        },
        error => {
          console.error(error);
          this.loadingCancelar = false;
        });*/
    
  }

  step2 = {ofertante: false, ofertanteConfirm: false, proveedor: false, proveedorTarifa: null, proveedorPago: null};

  errors2 = {option: false, ofertanteTos: false, proveedorTarifa: false, proveedorPago: false};

  tarifa = null;
  cambioFacturacion = null;
  cambioSuperior = false;

  provinciasSel = [];
  paisesSel = [];
  
  vistaTarifas = "mensual";

  scroll(id) {
    let el = document.getElementById(id);
    el.scrollIntoView({behavior:"smooth"});
  }

  cambiarVistaTarifas(vista: any) {
    this.scroll("tarifas");
    this.vistaTarifas = vista;
  }

  onChangePaisTarifa(provincia){
    this.provinciasSel[provincia.num-1].lista = [];
    this.provinciasSel[provincia.num-1].val = "";
    this.cdr.detectChanges();
    var lista = [];
    for(let estado of this.estadosAll) {
      if(estado.countryId == provincia.pais) {
        lista.push(estado);
      }
    }
    this.provinciasSel[provincia.num-1].lista = lista;
    this.cdr.detectChanges();
  }

  onChangeTarifa(tarifa){
    this.tarifa = tarifa;
    this.cambioFacturacion = this.vistaTarifas;
    this.provinciasSel = [];
    this.paisesSel = [];
    var lista = [];
    for(let estado of this.estadosAll) {
      if(estado.countryId == this.currentUser.pais.id) {
        lista.push(estado);
      }
    }
    for(var i = 0; i < this.tarifa.provincias; i++) {
      this.provinciasSel.push({num: i+1, val: "", pais: this.currentUser.pais.id, lista: lista});
    }
    for(var i = 0; i < this.tarifa.paises; i++) {
      this.paisesSel.push({num: i+1, val: ""});
    }
    this.scroll("localizacionTarifa");

    if(this.cambioFacturacion == "mensual") {
      if(this.tarifa.precioMensual > this.currentUser.suscripcion.precio) this.cambioSuperior = true
      else this.cambioSuperior = false;
    } else {
      if(this.tarifa.precioAnual > this.currentUser.suscripcion.precio) this.cambioSuperior = true
      else this.cambioSuperior = false;
    }
    this.cdr.detectChanges();
    this.calcularProrrateo();
  }
  
  modelChange() {
    this.cdr.detectChanges();
  }

  pagoProrrateo = null;
  prorrateoLimite = null;
  pagoDespues = null;
  calcularProrrateo() {
    this.pagoProrrateo = null;
    if(this.tarifa) {
      var tarifaStripe = null
      if(this.cambioFacturacion == 'mensual') {
        tarifaStripe = this.tarifa.stripeMensual;
        this.pagoDespues = this.tarifa.precioMensual;
      } else {
        tarifaStripe = this.tarifa.stripeAnual;
        this.pagoDespues = this.tarifa.precioAnual;
      }
      var suscrip = this.currentUser.suscripciones[this.currentUser.suscripciones.length-1];
      this.paymentService.getProrrationPreview(suscrip.stripeSusc, tarifaStripe, this.currentUser.stripeCustomer)
      .subscribe(
        resData => {
          console.warn(resData);   
          this.pagoProrrateo = resData.total / 100;
          this.prorrateoLimite = moment(resData.lines.data[1].period.end*1000).format("DD/MM/YYYY");
          this.cdr.detectChanges();
        },
        error => {
          console.error(error);
          this.loadingPaymentMethod = false;
          this.cdr.detectChanges();
      });
    }
  }

  changeTarifa() {
    if(this.loadingCambioLoc) return;
    this.cdr.detectChanges();

    var validLoc = true;
    for(var i = 0; i < this.provinciasSel.length; i++) {
      if(!this.provinciasSel[i].val) {
        validLoc = false;
        this.provinciasSel[i].error = true;
      } else {
        this.provinciasSel[i].error = false;
      }
    }
    for(var i = 0; i < this.paisesSel.length; i++) {
      if(!this.paisesSel[i].val) {
        validLoc = false;
        this.paisesSel[i].error = true;
      } else {
        this.paisesSel[i].error = false;
      }
    }

    if(!validLoc) {
      this.cdr.detectChanges();
      return;
    }

    this.loading = true;
    this.cdr.detectChanges();

    var tarifaStripe = null
    if(this.cambioFacturacion == 'mensual') {
      tarifaStripe = this.tarifa.stripeMensual;
    } else {
      tarifaStripe = this.tarifa.stripeAnual;
    }

    var provincias = [];
    var paises = [];

    for(var i = 0; i < this.provinciasSel.length; i++) {
      provincias.push(this.estadosHash[this.provinciasSel[i].val]);
    }
    for(var i = 0; i < this.paisesSel.length; i++) {
      paises.push(this.paisesHash[this.paisesSel[i].val]);
    }

    if(!this.cambioSuperior) {  //Si es una tarifa inferior, guardamos para el CRON.
      this.loadingCambioLoc = true;
      this.cdr.detectChanges();

      // Crear Suscripción
      var cambio = {
        user: this.authenticationService.currentUserValue.id,
        suscripcion: this.currentUser.suscripcion.id,
        fechaCambio: moment(this.currentUser.suscripcion.suscObj.current_period_end*1000),
        provincias: JSON.stringify(provincias),
        paises: JSON.stringify(paises),
        tarifa: this.tarifa.id,
        procesado: false,
        facturacion: this.cambioFacturacion,
        stripeProd: tarifaStripe,
        tipo: "TARIFA"
      };
      
      this.suscriptionService.postCambio(cambio)
      .pipe(first())
      .subscribe(
        data => {
          this.tarifaCambiadaSwal.text = "Su nueva tarifa se aplicará en la próxima renovación (" + this.currentUser.suscripcion.endDateStr + ").";
          this.tarifaCambiadaSwal.fire();
          this.vista = "suscripcion";
          this.loading = false;
          this.cdr.detectChanges();
          this.loadCambios();
        },
        error => {
          console.error(error);
          this.loading = false;
        });
    } else {
      var modif = {
        id: this.currentUser.suscripcion.stripeSusc,
        proration_behavior: 'always_invoice',
        billing_cycle_anchor: 'now',
        items: [{
          id: this.currentUser.suscripcion.suscObj.items.data[0].id,
          price: tarifaStripe, // Switch to new price
          quantity: 1
        }]
      }
      this.paymentService.updateSubscription(modif)
      .pipe(first())
        .subscribe(
          subscription => {
            if(subscription.status == "active") {
              this.currentUser.suscripcion.suscObj = subscription;
              this.authenticationService.changeUser(this.currentUser);

              //Ponemos la linea actual a finalizada. 
              var modif = {
                id: this.currentUser.suscripcion.id,
                activa: false,
                endDate: new Date(),
                razon: "tarifa_sup"
              };
              this.suscriptionService.update(modif)
                .pipe(first())
                .subscribe(
                  data => {
                    var precio = null
                    if(this.cambioFacturacion == "anual") {
                      precio = this.tarifa.precioAnual;
                    } else if(this.cambioFacturacion == "mensual") {
                      precio = this.tarifa.precioMensual;
                    }
                      
                    //Creamos una nueva línea de suscripción.
                    var susc = {
                      startDate: new Date(),
                      facturacion: this.cambioFacturacion,
                      provincias: JSON.stringify(provincias),
                      paises: JSON.stringify(paises),
                      precio: precio,
                      stripeProd: tarifaStripe,
                      stripeSusc: this.currentUser.suscripcion.stripeSusc,
                      tarifa: this.tarifa.id,
                      user: this.currentUser.id,
                      pagado: true,
                      activa: true,
                    };
                    
                    this.suscriptionService.post(susc)
                    .pipe(first())
                    .subscribe(
                      data1 => {
                        this.tarifaCambiadaSwal.text = "Su nueva tarifa se aplicará inmediatamente.";
                        this.tarifaCambiadaSwal.fire();
                        this.vista = "suscripcion";
                        this.loading = false;
                        this.cdr.detectChanges();
                        this.ngOnInit();
                      },
                      error => {
                        console.error(error);
                        this.loading = false;
                        this.cdr.detectChanges();
                      });
                    
                  },
                  error => {
                    console.error(error);
                    this.loading = false;
                    this.cdr.detectChanges();
                  });
              
            }
          },
          error => {
            console.error(error);
            this.loading = false;
            this.cdr.detectChanges();
          });
    }
  }

  //Cambio de modo de pago
  loadingCambio = false;
  cambiarPago(){
    this.loadingCambio = true;
    this.cdr.detectChanges();
    var stripe = Stripe(environment.stripe);

    this.paymentService.createSetupCheckoutSession(this.currentUser.stripeCustomer, this.currentUser.suscripcion.stripeSusc).then(function(data) {
      // Call Stripe.js method to redirect to the new Checkout page
      console.warn(data);
      stripe
        .redirectToCheckout({
          sessionId: data.sessionId
        })
        .then(function(res){
          console.warn(res);
        });
    });
  } 

  // METODO DE PAGO - ASEGURADORA

  ibanForm = null;
  step3 = {titular: null, iban: null};
  errors3 = {titular: false, iban: false, ibanFormat: false};

  /**
	 * Form Submit
	 */
	onSubmitMetPago() {
    if(this.loading) return;

    this.submitted = true;

    if(!this.step3.titular) {
      this.errors3.titular = true;
      this.cdr.detectChanges();
      return;
    } else this.errors3.titular = false;
    if(!this.ibanForm || this.ibanForm.invalid) {
      this.cdr.detectChanges();
      return;
    }

    this.loading = true;
    this.cdr.detectChanges();

    var currentUser = this.authenticationService.currentUserValue;
    var form = this.datosForm.value;
    
    form.id = currentUser.id;
    
    var update = {id: currentUser.id, iban_titular: this.step3.titular, iban: this.step3.iban}
    
    this.userService.update(update)
      .pipe(first())
      .subscribe(
        data => {
          currentUser.iban_titular = update.iban_titular;
          currentUser.iban = update.iban;

          this.currentUser = currentUser;

          this.authenticationService.changeUser(currentUser);

          this.loading = false;
          this.confirmationSwal.fire();
        },
        error => {
          console.error(error);
          this.loading = false;
        });

		
  }

  loadCambioLocalizacion() {
    //tarifasHash[currentUser.suscripcion.tarifa].paises != -1
    this.provinciasSel = [];
    this.paisesSel = [];
    var lista = [];
    for(let estado of this.estadosAll) {
      if(estado.countryId == this.currentUser.pais.id) {
        lista.push(estado);
      }
    }
    for(var i = 0; i < this.tarifasHash[this.currentUser.suscripcion.tarifa].provincias; i++) {
      this.provinciasSel.push({num: i+1, val: "", pais: this.currentUser.pais.id, lista: lista});
    }
    for(var i = 0; i < this.tarifasHash[this.currentUser.suscripcion.tarifa].paises; i++) {
      this.paisesSel.push({num: i+1, val: ""});
    }


    this.vista = 'cambio_loc';
    this.cdr.detectChanges();
  }

  loadingCambioLoc = false;

  changeLoc() {
    if(this.loadingCambioLoc) return;
    this.cdr.detectChanges();

    var validLoc = true;
    for(var i = 0; i < this.provinciasSel.length; i++) {
      if(!this.provinciasSel[i].val) {
        validLoc = false;
        this.provinciasSel[i].error = true;
      } else {
        this.provinciasSel[i].error = false;
      }
    }
    for(var i = 0; i < this.paisesSel.length; i++) {
      if(!this.paisesSel[i].val) {
        validLoc = false;
        this.paisesSel[i].error = true;
      } else {
        this.paisesSel[i].error = false;
      }
    }

    if(!validLoc) {
      this.cdr.detectChanges();
      return;
    }

    this.loadingCambioLoc = true;
    this.cdr.detectChanges();

    var provincias = [];
    var paises = [];

    for(var i = 0; i < this.provinciasSel.length; i++) {
      provincias.push(this.estadosHash[this.provinciasSel[i].val]);
    }
    for(var i = 0; i < this.paisesSel.length; i++) {
      paises.push(this.paisesHash[this.paisesSel[i].val]);
    }

    // Crear Suscripción
    var cambio = {
      user: this.authenticationService.currentUserValue.id,
      suscripcion: this.currentUser.suscripcion.id,
      fechaCambio: moment().add(2,'days'),
      provincias: JSON.stringify(provincias),
      paises: JSON.stringify(paises),
      tarifa: this.currentUser.suscripcion.tarifa,
      procesado: false,
      tipo: "LOC"
    };
    
    this.suscriptionService.postCambio(cambio)
    .pipe(first())
    .subscribe(
      data => {
        this.cambioLocSwal.fire();
        this.vista = "suscripcion";
        this.loadingCambioLoc = false;
        this.cdr.detectChanges();
        this.loadCambios();
      },
      error => {
        console.error(error);
        this.loadingCambioLoc = false;
      });


  }

  cancelarCambio(){
    this.loadingCambioLoc = true;
    this.cdr.detectChanges();
    this.suscriptionService.deleteCambio(this.cambio.id)
    .pipe(first())
    .subscribe(
      data => {
        this.borrarCambioSwal.fire();
        this.cambio = null;
        this.loadingCambioLoc = false;
        this.cdr.detectChanges();
      },
      error => {
          console.error(error);
          this.loading = false;
          this.cdr.detectChanges();
      });
  }

  // ---- BLOQUEOS ----

  loadBloqueos() {
    this.userService.getBloqueosByUser(this.currentUser.id)
	    .subscribe(bloqueos => {
      for(let bloqueo of bloqueos){
        bloqueo.fechaStr = moment(bloqueo.fecha).format("DD/MM/YYYY HH:mm")
      }
			this.bloqueos = bloqueos;
      this.cdr.detectChanges();
		});
  }

  @ViewChild('borrarSwal', {static: false}) private borrarSwal: SwalComponent
  @ViewChild('borrarConfirmSwal', {static: false}) private borrarConfirmSwal: SwalComponent

  bloqueoBorrar = null;

  borrarBloqueo(row: any) {
    this.bloqueoBorrar = row;
    this.borrarSwal.fire();
  }

  borrarBloqueo1(){

    this.userService.deleteBloqueo(this.bloqueoBorrar.id)
      .pipe(first())
      .subscribe(
        data => {
          this.borrarConfirmSwal.fire();
          for(var i = 0; i < this.bloqueos.length; i++) {
            if(this.bloqueos[i].id == this.bloqueoBorrar.id){
              this.bloqueos.splice(i, 1);
              var datos = [...this.bloqueos];
              this.bloqueos = [];
              this.bloqueos = datos;
              this.cdr.detectChanges();

              this.loadBloqueos();
              break;
            }
          }
        },
        error => {
            console.error(error);
            this.loading = false;
            this.cdr.detectChanges();
        });

    
  }

  // ---- ALERTAS ----

  //this.alertaEmail = this.currentUser.notif_email;
  //this.alertaTelegram = this.currentUser.notif_telegram;
  errors4 = {alerta: false, telegram: false, telegramCode: false};

  onSubmitAlertas() {
    this.errors4 = {alerta: false, telegram: false, telegramCode: false};
    if(!this.currentUser.notif_telegram && !this.currentUser.notif_email) {
      this.errors4.alerta = true;
      this.cdr.detectChanges();
      return;
    }
    if(this.currentUser.notif_telegram && (!this.currentUser.codigo_telegram || this.currentUser.codigo_telegram == "")) {
      this.errors4.telegram = true;
      this.cdr.detectChanges();
      return;
    }
    
    this.loading = true;
    this.cdr.detectChanges();

    var continuarGuardado = function (){
      var form = {id: this.currentUser.id, notif_telegram: this.currentUser.notif_telegram, codigo_telegram: this.currentUser.codigo_telegram, notif_email: this.currentUser.notif_email, avisos: this.currentUser.avisos};
      
      this.userService.update(form)
        .pipe(first())
        .subscribe(
          data => {
            console.warn(data);
            var currentUser = this.authenticationService.currentUserValue;
            if(form.notif_telegram) currentUser.notif_telegram = form.notif_telegram;
            if(form.codigo_telegram) currentUser.codigo_telegram = form.codigo_telegram;
            if(form.notif_email) currentUser.notif_email = form.notif_email;
            if(form.avisos) currentUser.avisos = form.avisos;
  
            this.currentUser = currentUser;
  
            this.authenticationService.changeUser(currentUser);
  
            this.loading = false;
            this.confirmationSwal.fire();
            this.cdr.detectChanges();
          },
          error => {
            console.error(error);
            this.loading = false;
            this.cdr.detectChanges();
          });
    }.bind(this);

    if(this.currentUser.notif_telegram) {
      this.userService.checkTelegram({code: this.currentUser.codigo_telegram})
      .pipe(first())
      .subscribe(
        data => {
          console.log(data);
          continuarGuardado();
        },
        error => {
          this.loading = false;
          this.errors4.telegramCode = true;
          console.log(error);
          this.errorTelegramSwal.fire();
          this.cdr.detectChanges();
      });
      this.cdr.detectChanges();
    } else {
      continuarGuardado();
    }

  }

  // ---- CERTIFICACIONES ----

  certificados = [
    {codigo: "ISO 9001", nombre: "Calidad", valor: false},
    {codigo: "ISO 14001", nombre: "Medio Ambiente", valor: false},
    {codigo: "ISO 45001", nombre: "Seguridad y Salud en el trabajo", valor: false},
    {codigo: "ISO 26001", nombre: "Responsabilidad Social Corporativa", valor: false},
    {codigo: "ISO 27001", nombre: "Seguridad de la información GDPR", valor: false},
    {codigo: "ISO 22000", nombre: "Seguridad Alimentaria, Análisis de Peligros y Puntos Críticos de Control (APPCC) Cafeterías", valor: false},
    {codigo: "ISO 55000", nombre: "Gestión de Activos", valor: false},
    {codigo: "ISO 20700", nombre: "Consultoría de Gestión", valor: false},
    {codigo: "ISO 10002", nombre: "Satisfacción del cliente Directrices para la resolución de conflictos de forma externa a las organizaciones", valor: false},
    {codigo: "ISO 10003", nombre: "Directrices para tratamiento de quejas en las organizaciones", valor: false},
  ]

  puntuaciones = [
    {codigo: "UNE 0069", valor: null},
    {codigo: "UNE 175001", valor: null},
    {codigo: "UNE 190001", valor: null},
    {codigo: "UNE 190002", valor: null},
  ]

  loadCertificaciones() {
    if(this.currentUser.certificados) {
      for(let cert of this.certificados) {
        for(let certUser of this.currentUser.certificados) {
          if(certUser.codigo == cert.codigo) {
            cert.valor = certUser.valor;
            break;
          }
        }
      }
    }
    if(this.currentUser.puntuaciones) {
      for(let punt of this.puntuaciones) {
        for(let puntUser of this.currentUser.puntuaciones) {
          if(puntUser.codigo == punt.codigo) {
            punt.valor = puntUser.valor;
            break;
          }
        }
      }
    }
    this.cdr.detectChanges();
  }

  onSubmitCertificaciones(){
    this.loading = true;
    this.cdr.detectChanges();
    var form = {id: this.currentUser.id, certificados: this.certificados, puntuaciones: this.puntuaciones};
    
    this.userService.update(form)
      .pipe(first())
      .subscribe(
        data => {
          var currentUser = this.authenticationService.currentUserValue;
          if(form.certificados) currentUser.certificados = form.certificados;
          if(form.puntuaciones) currentUser.puntuaciones = form.puntuaciones;

          this.currentUser = currentUser;

          this.authenticationService.changeUser(currentUser);

          this.loading = false;
          this.confirmationSwal.fire();
          this.cdr.detectChanges();
        },
        error => {
          console.error(error);
          this.loading = false;
          this.cdr.detectChanges();
        });
  }
  

}
