import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import{ GlobalVariables } from '../_common/global-variables';

@Injectable({ providedIn: 'root' })
export class MenuService {
    public menuSubject: BehaviorSubject<any>;
    public menu: Observable<any>;

    constructor() {
        this.menuSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('menu')));
        this.menu = this.menuSubject.asObservable();
        //this.logout();
    }

    public get menuValue(): any {
        return this.menuSubject.value;
    }

    changeMenu(menu: any) {
        localStorage.setItem('menu', JSON.stringify(menu));
        this.menuSubject.next(menu);
    }
}