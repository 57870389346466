import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';

import { environment } from '../../environments/environment';
 
@Injectable()
export class SuscriptionService {
    constructor(private http: HttpClient) { }

    post(susc: any) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json'
          })
        };
        return this.http.post(`${environment.apiUrl}/suscripcion`, susc, httpOptions);
    }

    update(suscrip: any) {
        //console.log("UPDATE", `${environment.apiUrl}/api/users/${user.id}`, user);
        return this.http.patch(`${environment.apiUrl}/suscripcion/${suscrip.id}`, suscrip);
    }

    postCambio(susc: any) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json'
          })
        };
        return this.http.post(`${environment.apiUrl}/cambio`, susc, httpOptions);
    }

    getCambiosByUser(user: any) {
        return this.http.get<any[]>(`${environment.apiUrl}/cambio?user=${user}&procesado=0`);
    }

    deleteCambio(id: any) {
      return this.http.delete(`${environment.apiUrl}/cambio/${id}`);
    }
 
    /*getAll() {
        return this.http.get<any[]>(this.api + '/usuarios');
    }

    getByGroup(grupo: number) {
        return this.http.get<any[]>(this.api + '/usuariosgrupo/' + grupo);
    }
 
    getById(id: number) {
        return this.http.get<any>(this.api + '/usuario/' + id);
    }
 
    

    tos(user: User) {
        return this.http.put(this.api + '/api/users/' + user.id, {tos: true});
    }
 
    update(user: any) {
        return this.http.put(this.api + '/api/users/' + user.id, user);
    }
 
    delete(id: number) {
        return this.http.delete(this.api + '/api/users/' + id);
    }*/
}