import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'kt-firststeps',
  templateUrl: './firststeps.component.html',
  styleUrls: ['./firststeps.component.scss']
})
export class FirststepsComponent implements OnInit, AfterViewInit {

  @ViewChild('wizard', {static: true}) el: ElementRef;

  model: any = {};
	submitted = false;

	ofertante = false;
	proveedor = false;

	constructor(private router: Router) {
    
	}

	ngOnInit() {
	}

	ngAfterViewInit(): void {
		// Initialize form wizard
		const wizard = new KTWizard(this.el.nativeElement, {
			startStep: 1,
			clickableSteps: false
		});

		// Validation before going to next page
		wizard.on('beforeNext', (wizardObj) => {
			// https://angular.io/guide/forms
			// https://angular.io/guide/form-validation

			// validate the form and use below function to stop the wizard's step
			
			// wizardObj.stop();
			if(wizard.getStep() == 2 && !this.proveedor) {
				wizard.goTo(4);
			}
		});

		wizard.on('beforePrev', (wizardObj) => {
			// wizardObj.stop();
			if(wizard.getStep() == 4 && !this.proveedor) {
				wizard.goTo(2);
			}
		});

		// Change event
		wizard.on('change', () => {
			setTimeout(() => {
				KTUtil.scrollTop();
			}, 500);
		});
	}

	onSubmit() {
    this.submitted = true;
    this.router.navigate(['/inicio']);
	}

}
