
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewEncapsulation, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { first, map } from 'rxjs/operators';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { CountryService, AuthenticationService, StateService, TarifaService, UserService, SuscriptionService, NotificacionService } from '../../../../_services';
import { ConfirmPasswordValidator } from './confirm-password.validator';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../../../environments/environment';
// Translate
import { TranslateService } from '@ngx-translate/core';

import { validateSpanishId } from 'spain-id';
import * as moment from 'moment';

@Component({
	selector: 'kt-register',
	templateUrl: './register.component.html',
	encapsulation: ViewEncapsulation.None,
	styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  @ViewChild('wizard', {static: true}) el: ElementRef;
  @ViewChild('errorSwal', {static: false}) private errorSwal: SwalComponent
  @ViewChild('errorSMSSwal', {static: false}) private errorSMSSwal: SwalComponent
  @ViewChild('errorTelegramSwal', {static: false}) private errorTelegramSwal: SwalComponent
  @ViewChild('errorFileSwal', {static: false}) private errorFileSwal: SwalComponent

  model: any = {};
  currentUser: any;

  paises: any;
  paisesHash = {};
  estados: any;
  estadosHash = {};
  estadosAll: any;
  tarifas = [];
  tarifasHash = {};

  wiz3Oculto = true;
  
  loading = false;

  wiz1Form: FormGroup;

  certificados = [
    {codigo: "ISO 9001", nombre: "Calidad", valor: false},
    {codigo: "ISO 14001", nombre: "Medio Ambiente", valor: false},
    {codigo: "ISO 45001", nombre: "Seguridad y Salud en el trabajo", valor: false},
    {codigo: "ISO 26001", nombre: "Responsabilidad Social Corporativa", valor: false},
    {codigo: "ISO 27001", nombre: "Seguridad de la información GDPR", valor: false},
    {codigo: "ISO 22000", nombre: "Seguridad Alimentaria, Análisis de Peligros y Puntos Críticos de Control (APPCC) Cafeterías", valor: false},
    {codigo: "ISO 55000", nombre: "Gestión de Activos", valor: false},
    {codigo: "ISO 20700", nombre: "Consultoría de Gestión", valor: false},
    {codigo: "ISO 10002", nombre: "Satisfacción del cliente Directrices para la resolución de conflictos de forma externa a las organizaciones", valor: false},
    {codigo: "ISO 10003", nombre: "Directrices para tratamiento de quejas en las organizaciones", valor: false},
  ]

  puntuaciones = [
    {codigo: "UNE 0069", valor: null},
    {codigo: "UNE 175001", valor: null},
    {codigo: "UNE 190001", valor: null},
    {codigo: "UNE 190002", valor: null},
  ]


	submittedWiz1 = false;

  constructor(private router: Router,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private stateService: StateService,
    private tarifaService: TarifaService,
    private translate: TranslateService,
    private notificacionService: NotificacionService,
    private countryService: CountryService,
    private suscriptionService: SuscriptionService,
    private http: HttpClient) {
    
	}

	ngOnInit() {
		//this.currentUser = this.authenticationService.currentUserValue;
		this.initWiz1Form();
		this.loadProvinciasAll();
		this.loadPaises();
		//this.loadPais();
		this.loadTarifas();
  }

  onChangePais(event){
    this.wiz1Form.controls["pais"].disable();
    this.wiz1Form.controls["provincia"].disable();
    this.loadProvincias(event.target.value);
  }

  loadProvincias(pais){
		this.stateService.getAllByCountry(pais)
	    .subscribe(estados => {
        this.estados = estados;
        this.wiz1Form.controls["pais"].enable();
        this.wiz1Form.controls["provincia"].enable();
        this.cdr.detectChanges();
      });
  }

  loadProvinciasAll(){
		this.stateService.getAll()
	    .subscribe(estados => {
        this.estadosAll = estados;
        this.cdr.detectChanges();
        for(var i = 0; i < estados.length; i++){
          this.estadosHash[estados[i].id+""] = estados[i];
        }
      });
  }

  loadPaises() {
		this.countryService.getAll()
	    .subscribe(paises => {
			this.paises = paises;
      this.cdr.detectChanges();
      for(var i = 0; i < paises.length; i++){
        this.paisesHash[paises[i].id+""] = paises[i];
      }
		});
  }

  loadPais(){
    this.countryService.getById(this.currentUser.pais)
    .subscribe(pais => {
      this.currentUser.paisStr = pais["name"];
      this.cdr.detectChanges();
    });
  }

  loadTarifas(){
		this.tarifaService.getAll()
	    .subscribe(tarifas => {
        this.tarifas = tarifas;
        this.cdr.detectChanges();
        for(var i = 0; i < tarifas.length; i++){
          this.tarifasHash[tarifas[i].id+""] = tarifas[i];
        }
      });
  }
  
  initWiz1Form() {
		this.wiz1Form = this.fb.group({
      cif: ['', Validators.compose([Validators.required])],
      licencia: [''],
      domicilio: ['', Validators.compose([Validators.required])],
      poblacion: ['', Validators.compose([Validators.required])],
      cp: ['', Validators.compose([Validators.required])],
      telefono: ['', Validators.compose([Validators.required, Validators.pattern("[0-9]{9,11}")])],
      telefono2: ['', [Validators.pattern("[0-9]{9,11}")]],
      pais: ['', Validators.compose([Validators.required])],
      provincia: ['', Validators.compose([Validators.required])],
      empresa: ['', Validators.compose([Validators.required])],
      actividad: ['', Validators.compose([Validators.required])],
      paginaweb: [''],
      notif_email: [false],
      notif_telegram: [false],
      email: ['', Validators.compose([
				Validators.required,
				Validators.email,
				Validators.minLength(3),
				Validators.maxLength(100)
			]),
			],
			password: ['', Validators.compose([
				Validators.required,
				Validators.minLength(6),
				Validators.maxLength(100)
			])
			],
			confirmPassword: ['', Validators.compose([
				Validators.required,
				Validators.minLength(6),
				Validators.maxLength(100)
			])
			],
		}, {
			validator: ConfirmPasswordValidator.MatchPassword
		});
  }

  error = {notificaciones: false, cif: false, sms: false, smsCode: false, telegram: false, telegramCode: false};

	ngAfterViewInit(): void {
		// Initialize form wizard
		const wizard = new KTWizard(this.el.nativeElement, {
			startStep: 1,
			clickableSteps: false
		});

		// Validation before going to next page
		wizard.on('beforeNext', (wizardObj) => {
      // validate the form and use below function to stop the wizard's step
      console.log("STEP: " + wizard.getStep());
      if(wizard.getStep() == 1) {
        console.log(this.wiz1Form.value);
        this.submittedWiz1 = true;
        if(!this.wiz1Form.value.notif_email && !this.wiz1Form.value.notif_telegram) {
          this.error.notificaciones = true;
        } else {
          this.error.notificaciones = false;
          this.cdr.detectChanges();
        }
        if(this.wiz1Form.value.pais == 207 && !validateSpanishId(this.wiz1Form.value.cif)) {
          this.error.cif = true;
        } else {
          this.error.cif = false;
          this.cdr.detectChanges();
        }
        if (this.wiz1Form.invalid || this.error.notificaciones || this.error.cif) {
          console.log(this.wiz1Form);
          const controls = this.wiz1Form.controls;
          Object.keys(controls).forEach(controlName =>
            controls[controlName].markAsTouched()
          );
          this.cdr.detectChanges();
          wizard.stop();
          return;
        } else {
          this.loading = true;
          wizard.stop();
          this.userService.check(this.wiz1Form.value.email)
          .pipe(first())
          .subscribe(
            data => {
              console.log(data);
              this.loading = false;
              wizard.start();
              wizard.goTo(2);
              wizard.goTo(2);
              this.cdr.detectChanges();
              var pais = this.paisesHash[this.wiz1Form.value.pais];
              this.userService.confirmacionSMS({email: this.wiz1Form.value.email, telefono: pais.phonecode + "" + this.wiz1Form.value.telefono})
                .pipe(first())
                .subscribe(
                  data => {
                    console.log(data);
                  },
                  error => {
                    console.log(error);
                });
            },
            error => {
              this.loading = false;
              console.log(error);
              this.errorSwal.fire();
              this.cdr.detectChanges();
          });
          this.cdr.detectChanges();
        }

        if(this.wiz1Form.value.actividad != 'Funerarias') {
          this.step2.ofertante = true;
          this.step2.proveedor = false;
        } else {
          this.step2.ofertante = false;
          this.step2.proveedor = true;
        }
        this.cdr.detectChanges();
      } else if(wizard.getStep() == 2){
        var valid = true;
        this.error.telegramCode = false;
        if(this.wiz1Form.value.notif_telegram && (!this.stepMovil.telegram || this.stepMovil.telegram == "")) {
          this.error.telegram = true;
          valid = false;
        } else {
          this.error.telegram = false;
          this.cdr.detectChanges();
        }
        this.error.smsCode = false;
        if(!this.stepMovil.sms || this.stepMovil.sms == "") {
          this.error.sms = true;
          valid = false;
        } else {
          this.error.sms = false;
          this.cdr.detectChanges();
        }
        this.cdr.detectChanges();
        if (!valid) {
          wizard.stop();
        } else {
          this.loading = true;
          wizard.stop();
          this.cdr.detectChanges();
          this.userService.checkSMS({email: this.wiz1Form.value.email, code: this.stepMovil.sms})
            .pipe(first())
            .subscribe(
              data => {
                console.log(data);

                if(this.wiz1Form.value.notif_telegram) {
                  this.userService.checkTelegram({code: this.stepMovil.telegram})
                  .pipe(first())
                  .subscribe(
                    data => {
                      console.log(data);
                      this.loading = false;
                      wizard.start();
                      wizard.goTo(3);
                      wizard.goTo(3);
                      this.cdr.detectChanges();
                    },
                    error => {
                      this.loading = false;
                      this.error.telegramCode = true;
                      console.log(error);
                      this.errorTelegramSwal.fire();
                      this.cdr.detectChanges();
                  });
                  this.cdr.detectChanges();
                } else {
                  this.loading = false;
                  this.cdr.detectChanges();
                  wizard.goTo(3);
                  wizard.goTo(3);
                }
              },
              error => {
                this.loading = false;
                this.error.smsCode = true;
                console.log(error);
                this.errorSMSSwal.fire();
                this.cdr.detectChanges();
            });
          

        }
      } else if(wizard.getStep() == 3){
        //step2 = {ofertante: false, ofertanteConfirm: false, proveedor: false, proveedorTarifa: null, proveedorPago: null};
        //errors2 = {option: false, ofertanteTos: false, proveedorTarifa: false, proveedorPago: false};
        if(!this.step2.ofertante && !this.step2.proveedor) {
          this.errors2.option = true;
          wizard.stop();this.cdr.detectChanges();
          return;
        } else this.errors2.option = false;

        if(this.step2.ofertante && !this.step2.ofertanteConfirm) {
          this.errors2.ofertanteTos = true;
          wizard.stop();this.cdr.detectChanges();
          return;
        } else this.errors2.ofertanteTos = false;

        if(this.step2.proveedor && this.step2.proveedorTarifa == null) {
          this.errors2.proveedorTarifa = true;
          wizard.stop();this.cdr.detectChanges();
          return;
        } else this.errors2.proveedorTarifa = false;

        if(this.step2.proveedor && this.step2.proveedorPago == null) {
          this.errors2.proveedorPago = true;
          wizard.stop();this.cdr.detectChanges();
          return;
        } else this.errors2.proveedorPago = false;

        /*for(var i = 0; i < this.tarifas.length; i++) {
          if(this.tarifas[i].id == this.step2.proveedorTarifa){
            this.tarifa = this.tarifas[i];
            console.log("TARIFA", this.tarifa);
            break;
          }
        }*/

        if(this.step2.proveedor == false) {
          //this.wiz3Oculto = true;
          wizard.goTo(4);
          wizard.goTo(4);
        } else {
          //this.wiz3Oculto = false;
          var valid = true;
          console.log(this.provinciasSel);
          for(var i = 0; i < this.provinciasSel.length; i++) {
            if(!this.provinciasSel[i].val) {
              valid = false;
              this.provinciasSel[i].error = true;
            } else {
              this.provinciasSel[i].error = false;
            }
          }
          for(var i = 0; i < this.paisesSel.length; i++) {
            if(!this.paisesSel[i].val) {
              valid = false;
              this.paisesSel[i].error = true;
            } else {
              this.paisesSel[i].error = false;
            }
          }
          this.cdr.detectChanges();
          if (!valid) {
            wizard.stop();
          } else {
            wizard.goTo(4);
            wizard.goTo(4);
          }
        }
        this.cdr.detectChanges();
      } else if(wizard.getStep() == 4) {
        //Validar IBAN si no es Funeraria
        if(this.wiz1Form.value.actividad != 'Funerarias') {
          if(!this.step3.titular) {
            this.errors3.titular = true;
            wizard.stop();this.cdr.detectChanges();
            return;
          } else this.errors3.titular = false;
          if(this.ibanForm.invalid) {
            wizard.stop();this.cdr.detectChanges();
            return;
          }
        }
      }
		});

		wizard.on('beforePrev', (wizardObj) => {
			// wizardObj.stop();
			/*if(wizard.getStep() == 4 && !this.step2.proveedor) {
				wizard.goTo(2);
			}*/
		});

		// Change event
		wizard.on('change', () => {
			setTimeout(() => {
				KTUtil.scrollTop();
      }, 500);
		});
  }
  
  onChangePaisTarifa(provincia){
    console.log("CHANGE PAIS");
    this.provinciasSel[provincia.num-1].lista = [];
    this.provinciasSel[provincia.num-1].val = "";
    this.cdr.detectChanges();
    var lista = [];
    for(let estado of this.estadosAll) {
      if(estado.countryId == provincia.pais) {
        lista.push(estado);
      }
    }
    this.provinciasSel[provincia.num-1].lista = lista;
    this.cdr.detectChanges();
  }

  onChangeTarifa(tarifa){
    console.log("onChangeTarifa()");
    this.tarifa = tarifa;
    this.provinciasSel = [];
    this.paisesSel = [];
    var lista = [];
    for(let estado of this.estadosAll) {
      if(estado.countryId == this.wiz1Form.value.pais) {
        lista.push(estado);
      }
    }
    for(var i = 0; i < this.tarifa.provincias; i++) {
      this.provinciasSel.push({num: i+1, val: "", pais: this.wiz1Form.value.pais, lista: lista});
    }
    for(var i = 0; i < this.tarifa.paises; i++) {
      this.paisesSel.push({num: i+1, val: ""});
    }
  }
  
  modelChange() {
    this.cdr.detectChanges();
  }

  //Imagen de funeraria
  files: File[] = [];
  image: any;

  onSelectFile(event) {
    console.log(event);
    if(!event.addedFiles[0].type.includes("image")){
      this.errorFileSwal.fire();
      return;
    }
    this.files = [];
    this.files.push(...event.addedFiles);

    
    console.log(event.addedFiles[0]);
  }
  
  onRemove(event) {
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }

	/**
	 * Form Submit
	 */
	onSubmit() {
		if(this.loading) return;

    this.loading = true;
    this.cdr.detectChanges();
		
    var form = this.wiz1Form.value;
    
    form.status = "PENDIENTE";

    form.ofertante = this.step2.ofertante;
    form.proveedor = this.step2.proveedor;
    if(this.step3.iban) form.iban = this.step3.iban;
    if(this.step3.titular) form.iban_titular = this.step3.titular;
    form.codigo_telegram = this.stepMovil.telegram;

    var user = {lang: this.translate.currentLang, status: "PENDIENTE", email: form.email, password: form.password, empresa: form.empresa, cif: form.cif, actividad: form.actividad};

		this.userService.register(user)
    .pipe(first())
    .subscribe(
      data => {
        console.log("OK", data);
        if (data && data["token"]) {
          localStorage.setItem('currentUser', JSON.stringify(data));
          this.authenticationService.currentUserSubject.next(data);

          self.io.sails.headers = {
            'Authorization': `Bearer ${data["token"]}`
          };
          self.io.socket = self.io.sails.connect();

          console.log(form);
          form.id = data["id"];

          delete form.password;
          delete form.email;
		
          this.userService.update(form)
            .pipe(first())
            .subscribe(
              data => {
                console.log("OK", data);
                var currentUser = this.authenticationService.currentUserValue;
                currentUser.cif = form.cif;
                currentUser.cp = form.cp;
                currentUser.pais = form.pais;
                currentUser.domicilio = form.domicilio;
                currentUser.licencia = form.licencia;
                currentUser.ofertante = form.ofertante;
                currentUser.proveedor = form.proveedor;
                currentUser.poblacion = form.poblacion;
                currentUser.provincia = form.provincia;
                currentUser.status = form.status;
                currentUser.telefono = form.telefono;
                currentUser.telefono2 = form.telefono2;

                if(form.proveedor) {
                  var now = moment();
                  var end = moment();
                  var precio = null
                  if(this.step2.proveedorPago == "anual") {
                    end.add(1, 'years');
                    precio = this.tarifasHash[this.step2.proveedorTarifa].precioAnual;
                  } else if(this.step2.proveedorPago == "mensual") {
                    end.add(1, 'months');
                    precio = this.tarifasHash[this.step2.proveedorTarifa].precioMensual;
                  }

                  var provincias = [];
                  var paises = [];

                  for(var i = 0; i < this.provinciasSel.length; i++) {
                    provincias.push(this.estadosHash[this.provinciasSel[i].val]);
                  }
                  for(var i = 0; i < this.paisesSel.length; i++) {
                    paises.push(this.paisesHash[this.paisesSel[i].val]);
                  }

                  var alta = "";
                  if(this.step2.ofertante) alta += "Ofertante";
                  if(this.step2.ofertante && this.step2.proveedor) alta += " y ";
                  if(this.step2.proveedor) alta += "Proveedor";
                  alta += " de traslados y servicios funerarios";

                  var tarifa = "";
                  var tarifaStripe = null;
                  if(this.step2.proveedor && this.step2.proveedorTarifa) {
                    tarifa += this.tarifasHash[this.step2.proveedorTarifa].nombre;
                    if(this.step2.proveedorPago == 'mensual') {
                      tarifa += " (" + this.tarifasHash[this.step2.proveedorTarifa].precioMensual + " €/" + this.step2.proveedorPago + ")";
                      tarifaStripe = this.tarifasHash[this.step2.proveedorTarifa].stripeMensual;
                    } else {
                      tarifa += " (" + this.tarifasHash[this.step2.proveedorTarifa].precioAnual + " €/" + this.step2.proveedorPago + ")";
                      tarifaStripe = this.tarifasHash[this.step2.proveedorTarifa].stripeAnual;
                    }
                  } else {
                    tarifa = "-";
                  }

                  var fecha = end.format("DD/MM/YYYY");

                  this.http.post(`${environment.apiUrl}/mailregistro`, {destinatario: currentUser.email, empresa: form.empresa, actividad: form.actividad, alta: alta, tarifa: tarifa, fecha: fecha, hash: currentUser.hashUser})
                  .pipe(first())
                  .subscribe(
                    data => {
                      console.log("OK MAIL", data);
                    },
                    error => {
                      console.error(error);
                      this.loading = false;
                    });

                  if(this.wiz1Form.value.notif_telegram) {
                    this.userService.confirmacionTelegram({code: this.stepMovil.telegram})
                      .pipe(first())
                      .subscribe(
                        data => {
                        },
                        error => {
                          console.log(error);
                      });
                  }

                  
                  // Crear Suscripción
                  var susc = {
                    startDate: now.toDate(),
                    facturacion: this.step2.proveedorPago,
                    renovable: true,
                    provincias: JSON.stringify(provincias),
                    paises: JSON.stringify(paises),
                    tarifa: this.step2.proveedorTarifa,
                    precio: precio,
                    user: this.authenticationService.currentUserValue.id,
                    pagado: false,
                    stripeProd: tarifaStripe
                  };
                  console.log(susc);
                  this.suscriptionService.post(susc)
                  .pipe(first())
                  .subscribe(
                    data => {
                      console.log("OK", data);
                      currentUser.tarifa = data;
                      this.authenticationService.changeUser(currentUser);
                      this.router.navigate(['/confirmacion']);
                    },
                    error => {
                      console.error(error);
                      this.loading = false;
                    });
                } else {
                  var alta = "";
                  if(this.step2.ofertante) alta += "Ofertante";
                  if(this.step2.ofertante && this.step2.proveedor) alta += " y ";
                  if(this.step2.proveedor) alta += "Proveedor";
                  alta += " de traslados y servicios funerarios";
                  this.http.post(`${environment.apiUrl}/mailregistro`, {destinatario: currentUser.email, empresa: form.empresa, actividad: form.actividad, alta: alta, tarifa: null, fecha: null, hash: currentUser.hashUser})
                  .pipe(first())
                  .subscribe(
                    data => {
                      console.log("OK MAIL", data);
                    },
                    error => {
                      console.error(error);
                      this.loading = false;
                    });
                  
                  if(this.wiz1Form.value.notif_telegram) {
                    this.userService.confirmacionTelegram({code: this.stepMovil.telegram})
                      .pipe(first())
                      .subscribe(
                        data => {
                        },
                        error => {
                          console.log(error);
                      });
                  }
                  this.authenticationService.changeUser(currentUser);
                  this.router.navigate(['/confirmacion']);
                }
              },
              error => {
                console.error(error);
                this.loading = false;
              });
          





        } else {
          console.error("ERROR", data);
        }
      },
      error => {
        console.error(error);
    });

    
		
	}

	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasErrorWiz1(controlName: string, validationType: string): boolean {
		const control = this.wiz1Form.controls[controlName];
		if (!control || !this.submittedWiz1) {
			return false;
		}

		if(!validationType) {
			const result = control.invalid && (control.dirty || control.touched);
			return result;
		} else {
			const result = control.hasError(validationType) && (control.dirty || control.touched);
			return result;
		}
  }
  
  //--------------- STEP VALIDACION MOVIL ---------------

  stepMovil = {sms: null, telegram: null};

  //--------------- STEP 2 ---------------

  step2 = {ofertante: false, ofertanteConfirm: true, proveedor: false, proveedorTarifa: null, proveedorPago: null};

  errors2 = {option: false, ofertanteTos: false, proveedorTarifa: false, proveedorPago: false};

  tarifa: any;

  //--------------- STEP 2b ---------------

  provinciasSel = [];
  paisesSel = [];

  //--------------- STEP 3 ----------------

  ibanForm = null;
  step3 = {titular: null, iban: null};
  errors3 = {titular: false, iban: false, ibanFormat: false};


  // ---------- AYUDA LLAMADA -------------

  ayudaEnviada = false;
  ayudaNombre = null;
  ayudaTelefono = null;

  enviarAyuda() {
    this.ayudaEnviada = true;
    this.cdr.detectChanges();
    this.notificacionService.sendAyuda({nombre: this.ayudaNombre, telefono: this.ayudaTelefono})
      .pipe(first())
      .subscribe(
        data => {
          console.log(data);
        },
        error => {
          console.error(error);
      });
  }

}
