import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-nuevalicitacion',
  templateUrl: './nuevalicitacion.component.html',
  styleUrls: ['./nuevalicitacion.component.scss']
})
export class NuevalicitacionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
