import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { first } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import { UserService, AuthenticationService, PaymentService } from '../../../_services';

declare var Stripe: any;

@Component({
  selector: 'kt-confirmacion',
  templateUrl: './confirmacion.component.html',
  styleUrls: ['./confirmacion.component.scss']
})
export class ConfirmacionComponent implements OnInit {

  //stripe;

  constructor(private route: ActivatedRoute,
    private paymentService: PaymentService,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private router: Router,
    private cdr: ChangeDetectorRef) { 
    }

  hash = null;
  email = null;
  validada = false;
  loading = true;
  errorHash = false;
  currentUser = null;
  pago = false;
    

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.hash = params["hash"];
      this.email = params["email"];

      this.currentUser = this.authenticationService.currentUserValue;
      var token = this.currentUser.token;

      this.userService.getById(this.currentUser.id)
      .subscribe(user => {
        this.currentUser = user;
        this.currentUser.token = token;
        this.cdr.detectChanges();
        this.authenticationService.changeUser(this.currentUser);

        
        this.loadValidacion();

      });

    })
  }

  loadValidacion = function() {
    this.paymentService.createCustomer(this.currentUser.email, this.currentUser.empresa, this.currentUser.telefono)
    .then(function(result) {
      this.currentUser.stripeCustomer = result.customerId;
      var updateObj = {id: this.currentUser.id, stripeCustomer: this.currentUser.stripeCustomer, status: "ACTIVO", hashUser: null, tarifaTemp: null};
      this.userService.update(updateObj)
      .pipe(first())
      .subscribe(
        data => {
          this.currentUser.status = data.status;
          this.currentUser.hashUser = data.hashUser;
          this.authenticationService.changeUser(this.currentUser);

          this.loading = false;
          if(this.currentUser.actividad == "Funerarias") {
            this.pago = true;
            this.cdr.detectChanges();
            setTimeout(()=>{
              this.router.navigate(['/pago']);
            }, 2000);
          } else {
            setTimeout(()=>{
              this.router.navigate(['/home']);
            }, 3000);
          }
          this.cdr.detectChanges();
        },
        error => {
          console.error(error);
          this.loading = false;
        });
    }.bind(this));

		
  }.bind(this);

  priceId = "price_1HrATdHnipIYubl16YaXkwoH";

}
