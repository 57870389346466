import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
 
@Injectable()
export class ServicioService {
    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<any[]>(`${environment.apiUrl}/servicio`);
    }

    getById(id: number) {
        return this.http.get(`${environment.apiUrl}/servicio/${id}`);
    }
}