// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// Components
import { BaseComponent } from './views/theme/base/base.component';
import { ErrorPageComponent } from './views/theme/content/error-page/error-page.component';
// Auth
//import { AuthGuard } from './core/auth';
import { AuthGuard, StartGuard } from './_helpers';
//WFN Imports
import { FirststepsComponent } from './views/pages/firststeps/firststeps.component';
import { HomeComponent } from './views/pages/home/home.component';
import { StartComponent } from './views/pages/start/start.component';
import { LicitacionesComponent } from './views/pages/licitaciones/licitaciones.component';
import { LicitacionNuevaComponent } from './views/pages/licitacion-nueva/licitacion-nueva.component';
import { LicitacionEditarComponent } from './views/pages/licitacion-editar/licitacion-editar.component';
import { MislicitacionesComponent } from './views/pages/mislicitaciones/mislicitaciones.component';
import { SuscripcionComponent } from './views/pages/suscripcion/suscripcion.component';
import { ConfirmacionComponent } from './views/pages/confirmacion/confirmacion.component';
import { ValidarComponent } from './views/pages/validar/validar.component';
import { PagoComponent } from './views/pages/pago/pago.component';
import { InstalacionesComponent } from './views/pages/instalaciones/instalaciones.component';
import { InstalacionComponent } from './views/pages/instalacion/instalacion.component';
import { TeleasistenciaComponent } from './views/pages/teleasistencia/teleasistencia.component';
import { NotificacionesComponent } from './views/pages/notificaciones/notificaciones.component';
import { ManualesComponent } from './views/pages/manuales/manuales.component';
import { AlarmasComponent } from './views/pages/alarmas/alarmas.component';

const routes: Routes = [
	{path: 'auth', loadChildren: () => import('./views/pages/auth/auth.module').then(m => m.AuthModule)},

	{
		path: '',
		component: BaseComponent,
		canActivate: [AuthGuard],
		children: [
			{ 
				path: 'firststeps', 
				component: FirststepsComponent,
				canActivate: [AuthGuard/*, TosGuard, StartGuard*/]
			},
			{ 
				path: 'start', 
				component: StartComponent,
				canActivate: [AuthGuard/*, TosGuard, StartGuard*/]
			},
			{ 
				path: 'start/:tarifa', 
				component: StartComponent,
				canActivate: [AuthGuard/*, TosGuard, StartGuard*/]
			},
			{ 
				path: 'home', 
				component: HomeComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'instalaciones', 
				component: InstalacionesComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'instalacion/:id/:seccion', 
				component: InstalacionComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'teleasistencia', 
				component: TeleasistenciaComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'alarmas/:id', 
				component: AlarmasComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'notificaciones/:id', 
				component: NotificacionesComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'manuales/:id', 
				component: ManualesComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'crear', 
				component:  LicitacionNuevaComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'editar/:id', 
				component: LicitacionEditarComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'mislicitaciones', 
				component:  LicitacionesComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'pendientes', 
				component:  LicitacionesComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'trazabilidad', 
				component:  LicitacionesComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'mislicitaciones2', 
				component:  MislicitacionesComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'perfil', 
				component: SuscripcionComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'perfil/:tab', 
				component: SuscripcionComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'confirmacion', 
				component: ConfirmacionComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'validar', 
				component: ValidarComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'pago', 
				component: PagoComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'pago/:estado', 
				component: PagoComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{
				path: 'dashboard',
				loadChildren: () => import('./views/pages/dashboard/dashboard.module').then(m => m.DashboardModule)
			},
			{
				path: 'mail',
				loadChildren: () => import('./views/pages/apps/mail/mail.module').then(m => m.MailModule)
			},
			{
				path: 'ecommerce',
				loadChildren: () => import('./views/pages/apps/e-commerce/e-commerce.module').then(m => m.ECommerceModule),
			},
			{
				path: 'ngbootstrap',
				loadChildren: () => import('./views/pages/ngbootstrap/ngbootstrap.module').then(m => m.NgbootstrapModule)
			},
			{
				path: 'material',
				loadChildren: () => import('./views/pages/material/material.module').then(m => m.MaterialModule)
			},
			{
				path: 'user-management',
				loadChildren: () => import('./views/pages/user-management/user-management.module').then(m => m.UserManagementModule)
			},
			{
				path: 'wizard',
				loadChildren: () => import('./views/pages/wizard/wizard.module').then(m => m.WizardModule)
			},
			{
				path: 'builder',
				loadChildren: () => import('./views/theme/content/builder/builder.module').then(m => m.BuilderModule)
			},
			{
				path: 'error/403',
				component: ErrorPageComponent,
				data: {
					type: 'error-v6',
					code: 403,
					title: '403... Access forbidden',
					desc: 'Looks like you don\'t have permission to access for requested page.<br> Please, contact administrator'
				}
			},
			{path: 'error/:type', component: ErrorPageComponent},
			{path: '', redirectTo: 'home', pathMatch: 'full'},
			{path: '**', redirectTo: 'home', pathMatch: 'full'}
		]
	},

	{path: '**', redirectTo: 'error/403', pathMatch: 'full'},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes)
	],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
