import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';

import { environment } from '../../environments/environment';
 
@Injectable()
export class NotificacionService {
    constructor(private http: HttpClient) { }

    sendAyuda(info: any) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json'
          })
        };
        return this.http.post(`${environment.apiUrl}/notificacion/ayuda`, info, httpOptions);
    }

    nueva_licitacion(info: any) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json'
          })
        };
        return this.http.post(`${environment.apiUrl}/notificacion/nueva_licitacion`, info, httpOptions);
    }

    licitacion_validada(info: any) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json'
          })
        };
        return this.http.post(`${environment.apiUrl}/notificacion/licitacion_validada`, info, httpOptions);
    }
 
    /*getAll() {
        return this.http.get<any[]>(this.api + '/usuarios');
    }

    getByGroup(grupo: number) {
        return this.http.get<any[]>(this.api + '/usuariosgrupo/' + grupo);
    }
 
    getById(id: number) {
        return this.http.get<any>(this.api + '/usuario/' + id);
    }
 
    

    tos(user: User) {
        return this.http.put(this.api + '/api/users/' + user.id, {tos: true});
    }
 
    update(user: any) {
        return this.http.put(this.api + '/api/users/' + user.id, user);
    }
 
    delete(id: number) {
        return this.http.delete(this.api + '/api/users/' + id);
    }*/
}