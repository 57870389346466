import { Component, Input, OnInit, ViewChild, ViewChildren, ElementRef, ChangeDetectorRef, NgZone, HostListener } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AuthenticationService, StateService, SeccionService, MenuService, CountryService, LicitaService, UserService, TrazabilidadService, NotificacionService, InstalacionService } from '../../../_services';

import{ GlobalVariables } from '../../../_common/global-variables';

@Component({
  selector: 'kt-instalaciones',
  templateUrl: './instalaciones.component.html',
  styleUrls: ['./instalaciones.component.scss']
})
export class InstalacionesComponent implements OnInit {

  currentUser = null;
  rows = [];
  loadingInit = true;
  txt = {};

  constructor(private router: Router,
    private modalService: NgbModal,
    private authenticationService: AuthenticationService,
    private cdr: ChangeDetectorRef,
    private seccionService: SeccionService,
    private menuService: MenuService,
    private instalacionService: InstalacionService,
    private ngZone: NgZone,
    private trazabilidadService: TrazabilidadService,
    private sanitizer: DomSanitizer) {    
      this.txt = GlobalVariables.textos;
      if(Object.keys(this.txt).length == 0) {
        setTimeout(function() {
          this.txt = GlobalVariables.textos;
          this.cdr.detectChanges();
          if(Object.keys(this.txt).length == 0) {
            setTimeout(function() {
            this.txt = GlobalVariables.textos;
            this.cdr.detectChanges();
            }.bind(this), 2000);
          }
        }.bind(this), 3000);
      }   
  }

  _arrayBufferToBase64( buffer ) {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
  }

  async ngOnInit() {
    this.currentUser = this.authenticationService.currentUserValue;
    
    this.menuService.changeMenu(null);

    if(this.currentUser.UsuarioGeinsa) {

      let data = await this.instalacionService.getAllAsync();

      for(let inst of data) {
        if(inst.Logotipo) {
          var img64 = this._arrayBufferToBase64(inst.Logotipo.data);
          inst.Logotipo64 = this.sanitizer.bypassSecurityTrustUrl('data:image/jpg;base64, ' + img64);
        }
        if(this.currentUser.IdUsuario == 3) inst.NombreInstalacion = inst.NombreInstalacion.substring(0, 4) + "_GEINSA_DEMO";
        if(inst.Completada) this.rows.push(inst);
      }

      var temp = [...this.rows];
      this.rows = [];
      this.rows = temp;
      this.cdr.detectChanges();
      
    } else {
      for(let inst of this.currentUser.instalaciones) {

        if(inst.Logotipo) {
          var img64 = this._arrayBufferToBase64(inst.Logotipo.data);
          inst.Logotipo64 = this.sanitizer.bypassSecurityTrustUrl('data:image/jpg;base64, ' + img64);
        }
        if(inst.Completada) this.rows.push(inst);
      }

      var temp = [...this.rows];
      this.rows = [];
      this.rows = temp;
      this.cdr.detectChanges();
    }

    this.loadingInit = false;
    this.cdr.detectChanges();

    /*for(let inst of this.currentUser.instalaciones) {
      if(inst.Completada) this.rows.push(inst);
    }*/


    //this.rows = this.currentUser.instalaciones;

    //console.warn(this.rows);
  }

  row: any;
  loadingRow = false;

  onSelect(event: any) {
    if(event.type == "click" && !this.loadingRow){
      event.cellElement.blur();
      this.row = event.row;
      this.row.loading = true;
      this.loadingRow = true;
      this.cdr.detectChanges();

      this.seccionService.getAllByInstalacion(this.row.ID)
      .subscribe(secciones => {
        this.row.loading = false;
        this.loadingRow = false;
        this.cdr.detectChanges();
        if(secciones.length > 0) {
          var menu = { titulo: this.row.NombreInstalacion, secciones: [] };
          for(let seccion of secciones) {
            let textoSec = seccion.Denominacion;
            for(let texto of seccion.textos) {
              if(texto.CodigoIsoIdioma == this.currentUser.CodigoIsoIdioma) {
                textoSec = texto.Traduccion;
                break;
              }
            }
            menu.secciones.push({nombre: textoSec, url: "/instalacion/" + this.row.ID + "/" + seccion.Id});
          }
          
          menu.secciones.push({nombre: GlobalVariables.textos["alarmas"].toUpperCase(), url: "/alarmas/" + this.row.ID});
          
          if(this.currentUser.UsuarioGeinsa) {
            menu.secciones.push({nombre: GlobalVariables.textos["notificaciones"].toUpperCase(), url: "/notificaciones/" + this.row.ID});
          }
          
          menu.secciones.push({nombre: GlobalVariables.textos["manuales"].toUpperCase(), url: "/manuales/" + this.row.ID})
          

          
          this.menuService.changeMenu(menu);
          this.router.navigateByUrl(menu.secciones[0].url)
        }
      });



    }
  }

}
