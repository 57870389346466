// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
// Partials
import { PartialsModule } from '../partials/partials.module';
// Pages
import { CoreModule } from '../../core/core.module';
import { MailModule } from './apps/mail/mail.module';
import { ECommerceModule } from './apps/e-commerce/e-commerce.module';
import { UserManagementModule } from './user-management/user-management.module';
import { MyPageComponent } from './my-page/my-page.component';
import { FirststepsComponent } from './firststeps/firststeps.component';
import { HomeComponent } from './home/home.component';
import { LicitacionesComponent } from './licitaciones/licitaciones.component';
// NGX DATATABLE
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbDate, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { AngularIbanModule } from 'angular-iban';
import '@stripe/stripe-js';
// Material
import {
	MatInputModule,
	MatPaginatorModule,
	MatSortModule,
	MatTableModule,
	MatSelectModule,
	MatMenuModule,
	MatProgressBarModule,
	MatButtonModule,
	MatCheckboxModule,
	MatDialogModule,
	MatTabsModule,
	MatNativeDateModule,
	MatCardModule,
	MatRadioModule,
	MatIconModule,
	MatDatepickerModule,
	MatAutocompleteModule,
	MAT_DIALOG_DEFAULT_OPTIONS,
	MatSnackBarModule,
	MatTooltipModule,
	MatProgressSpinnerModule
} from '@angular/material';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { StartComponent } from './start/start.component';
import { MislicitacionesComponent } from './mislicitaciones/mislicitaciones.component';
import { NuevalicitacionComponent } from './nuevalicitacion/nuevalicitacion.component';
import { RegistroComponent } from './registro/registro.component';

import {RouterModule} from '@angular/router';

import { TooltipModule } from 'ng2-tooltip-directive';
import { SuscripcionComponent } from './suscripcion/suscripcion.component';
import { ConfirmacionComponent } from './confirmacion/confirmacion.component';
import { ValidarComponent } from './validar/validar.component';
import { PagoComponent } from './pago/pago.component';
import { LicitacionNuevaComponent } from './licitacion-nueva/licitacion-nueva.component';
import { LicitacionEditarComponent } from './licitacion-editar/licitacion-editar.component';
import { InstalacionesComponent } from './instalaciones/instalaciones.component';
import { InstalacionComponent } from './instalacion/instalacion.component';

import { NgxChartsModule }from '@swimlane/ngx-charts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TeleasistenciaComponent } from './teleasistencia/teleasistencia.component';
import { NotificacionesComponent } from './notificaciones/notificaciones.component';
import { ManualesComponent } from './manuales/manuales.component';
import { DxChartModule, DxFileManagerModule, DxPieChartModule } from 'devextreme-angular';
import { AlarmasComponent } from './alarmas/alarmas.component';

@NgModule({
	declarations: [MyPageComponent, FirststepsComponent, HomeComponent, LicitacionesComponent, StartComponent, MislicitacionesComponent, NuevalicitacionComponent, RegistroComponent, SuscripcionComponent, ConfirmacionComponent, ValidarComponent, PagoComponent, LicitacionNuevaComponent, LicitacionEditarComponent, InstalacionesComponent, InstalacionComponent, TeleasistenciaComponent, NotificacionesComponent, ManualesComponent, AlarmasComponent],
	exports: [],
	imports: [
		CommonModule,
		HttpClientModule,
		FormsModule,
		ReactiveFormsModule,
		CoreModule,
		PartialsModule,
		MailModule,
		ECommerceModule,
		UserManagementModule,
		MatInputModule,
		MatPaginatorModule,
		MatSortModule,
		MatTableModule,
		MatSelectModule,
		MatMenuModule,
		MatProgressBarModule,
		MatButtonModule,
		MatCheckboxModule,
		MatDialogModule,
		MatTabsModule,
		MatNativeDateModule,
		MatCardModule,
		MatRadioModule,
		MatIconModule,
		MatDatepickerModule,
		MatAutocompleteModule,
		MatSnackBarModule,
		MatTooltipModule,
		MatProgressSpinnerModule,
		NgxDatatableModule,
		NgbModule,
		TranslateModule.forChild(),
		SweetAlert2Module,
		OwlDateTimeModule, 
		OwlNativeDateTimeModule,
		TooltipModule,
		RouterModule,
		NgxDropzoneModule,
		AngularIbanModule,
		NgxChartsModule,
		BrowserAnimationsModule,
		DxChartModule,
		DxFileManagerModule,
		DxPieChartModule
	],
	providers: []
})
export class PagesModule {
}
