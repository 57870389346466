import { Component, Input, OnInit, ViewChild, ViewChildren, ElementRef, ChangeDetectorRef, NgZone, HostListener } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { first } from 'rxjs/operators';

import { columnsTotalWidth, NgxDatatableModule } from '@swimlane/ngx-datatable';

import { AuthenticationService, StateService, CountryService, LicitaService, UserService, TrazabilidadService, NotificacionService } from '../../../_services';

import { DateTimeAdapter } from 'ng-pick-datetime';
import { HttpClient } from '@angular/common/http';

import { interval, Subscription, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

import * as moment from 'moment';
import{ GlobalVariables } from '../../../_common/global-variables';

@Component({
  selector: 'kt-licitaciones',
  templateUrl: './licitaciones.component.html',
  styleUrls: ['./licitaciones.component.scss'],
  animations: [
    trigger('cardFlip', [
      state('default', style({
        transform: 'none'
      })),
      state('flipped', style({
        transform: 'rotateY(180deg)',
        '-webkit-transform': 'translateZ(1000px)'
      })),
      transition('default => flipped', [
        animate('200ms')
      ]),
      transition('flipped => default', [
        animate('200ms')
      ])
    ])
  ]
})
export class LicitacionesComponent implements OnInit {

  @ViewChild('confirmationSwal', {static: false}) private confirmationSwal: SwalComponent
  @ViewChild('errorSwal', {static: false}) private errorSwal: SwalComponent
  @ViewChild('errorNegativeSwal', {static: false}) private errorNegativeSwal: SwalComponent
  @ViewChild('confirmaSwal', {static: false}) private confirmaSwal: SwalComponent
  @ViewChild('error2Swal', {static: false}) private error2Swal: SwalComponent
  @ViewChild('registerSwal', {static: false}) private registerSwal: SwalComponent
  @ViewChild('registerValidarSwal', {static: false}) private registerValidarSwal: SwalComponent
  @ViewChild('confirmationAddSwal', {static: false}) private confirmationAddSwal: SwalComponent
  @ViewChild('confirmationEditSwal', {static: false}) private confirmationEditSwal: SwalComponent
  @ViewChild('content8', {static: false}) private modalLicit: NgbModal
  @ViewChild('contentEditar', {static: false}) private modalEditar: NgbModal
  @ViewChild('confirmaElegidoSwal', {static: false}) private confirmaElegidoSwal: SwalComponent
  @ViewChild('confirmaElegidoSuccessSwal', {static: false}) private confirmaElegidoSuccessSwal: SwalComponent
  @ViewChild('confirmaSaltarSwal', {static: false}) private confirmaSaltarSwal: SwalComponent
  @ViewChild('confirmaSaltarSuccessSwal', {static: false}) private confirmaSaltarSuccessSwal: SwalComponent
  @ViewChild('suscriptionSwal', {static: false}) private suscriptionSwal: SwalComponent
  //

  @ViewChild('importe', {static: false}) private importeField: ElementRef

  @ViewChild('tableLicitaciones', {static: false}) private tableLicitaciones: any
  
  @ViewChildren('tooltip') tooltips;

  @ViewChild('panelCards', null) panelCards: ElementRef;
  
  vista = 'listado';

  eventSusc: any;
  userSusc: any;
  currentUser: any;
  servicios: any;
  serviciosHash = {};

  filtrosReducidos = false;
  columnas = null;

  participaciones = {};
  participacionesImporte = {};

  filtrosOpen = true;
  loadingInit = true;

  rows = [];
  originalRows = [];
  temp = [];

  subscription: Subscription;

  paises: any;
  estados: any;
  estadosOrigen = []
  estadosDestino = [];
  paisOrigen = null;
  paisDestino = null;
  trazabilidad = [];
  trazabilidadHash = {};
  pagosPendientes = false;

  constructor(private router: Router,
    private modalService: NgbModal,
    private authenticationService: AuthenticationService,
    private cdr: ChangeDetectorRef,
    private stateService: StateService,
    private countryService: CountryService,
    private licitaService: LicitaService,
    private userService: UserService,
    private notificacionService: NotificacionService,
    private http: HttpClient,
    private ngZone: NgZone,
    private trazabilidadService: TrazabilidadService,
    dateTimeAdapter: DateTimeAdapter<any>) { 
      dateTimeAdapter.setLocale('es-ES');
      
  }

  navigate(commands: any[]): void {
    this.ngZone.run(() => this.router.navigate(commands)).then();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    var size = window.innerHeight;
    this.filtrosReducidos = size < 850;
    this.cdr.detectChanges();
    this.calcularColumnas();
  }

  calcularColumnas() {
    var width = this.panelCards.nativeElement.offsetWidth;
    if(width > 1700) this.columnas = 5
    else if(width > 1250) this.columnas = 4
    else if(width > 870) this.columnas = 3
    else if(width > 540) this.columnas = 2
    else this.columnas = 1;
  }

  ngOnInit() {
    this.filtrosReducidos = window.innerHeight < 850;
    this.cdr.detectChanges();

    if(localStorage.getItem('filtros')) GlobalVariables.filters["licitaciones"] = JSON.parse(localStorage.getItem('filtros'));
    if(localStorage.getItem('orden')) GlobalVariables.orden = JSON.parse(localStorage.getItem('orden'));
    if(localStorage.getItem('ordenMostrar')) this.ordenMostrar = JSON.parse(localStorage.getItem('ordenMostrar'));

    this.ordenMostrar.estado = false;
    this.ordenMostrar.inicio = false;
    this.ordenMostrar.fin = false;

    this.userSusc = function(msg) {
      if (this.cdr['destroyed']) {
        return;
      }
      console.warn(msg);
      if (msg.verb == "destroyed") {
        if(msg.previous.bloqueado.id == this.authenticationService.currentUserValue.id) {
          for(let bloqueo of this.currentUser.bloqueadosMi) {
            if(bloqueo.id == msg.id) {
              const index = this.currentUser.bloqueadosMi.indexOf(bloqueo, 0);
              if (index > -1) {
                this.currentUser.bloqueadosMi.splice(index, 1);
              }
              this.filterDatatable();
              break;
            }
          }
        }
        
      } else if(msg.verb == "created"){
        if(msg.data.bloqueado.id == this.authenticationService.currentUserValue.id) {
          if(!this.currentUser.bloqueadosMi) this.currentUser.bloqueadosMi = [];
          msg.data.bloqueado = msg.data.bloqueado.id;
          msg.data.user = msg.data.user.id;
          this.currentUser.bloqueadosMi.push(msg.data);
          this.filterDatatable();
        } 
      }
    }

    self.io.socket.on('user_bloqueo', this.userSusc.bind(this));

    var loadedUser = function(user) {
    }
    self.io.socket.get('/user_bloqueo', loadedUser.bind(this));

    this.userService.getById(this.authenticationService.currentUserValue.id)
    .subscribe(user => {

      this.currentUser = user;

      if(GlobalVariables.filters["licitaciones"]) {
        this.filters = GlobalVariables.filters["licitaciones"].filters;
        /*this.filters.estadoMostrar = false,
        this.filters.estados = {
          pendienteInicio: false,
          abierta: false,
          pendienteAdjudicacion: false,
          adjudicacionTramite: false,
          adjudicadaPendiente: false,
          ejecucion: false,
          completada: false,
          cerrada: false,
          desierta: false
        }*/
        this.filtrosOpen = GlobalVariables.filters["licitaciones"].open;
        this.servicios = GlobalVariables.filters["licitaciones"].servicios;

        this.orden = GlobalVariables.orden;
      } else {
        var pais = "TODOS";
        /*if(this.currentUser.proveedor) {
          pais = this.currentUser.pais.id;
        }*/
        this.filters = {
          servicio: "TODOS", 
          paisOrigen: pais,
          provinciaOrigen: "TODOS", 
          paisDestino: pais, 
          provinciaDestino: "TODOS",
          fechaInicio: null,
          fechaFin: null,
          precioMin: null,
          precioMax: null,
          estado: "TODOS",
          //NUEVOS
          referenciaMostrar: false,
          referencia: null,
          servicioMostrar: false,
          servicios: [],
          servicioTraslado: false,
          comunidadOrigenMostrar: false,
          comunidadDestinoMostrar: false,
          paisOrigenMostrar: false,
          paisDestinoMostrar: false,
          fechaDesdeMostrar: false,
          fechaHastaMostrar: false,
          precioMinMostrar: false,
          precioMaxMostrar: false,
          estadoMostrar: false,
          estados: {
            pendienteInicio: false,
            abierta: false,
            pendienteAdjudicacion: false,
            adjudicacionTramite: false,
            adjudicadaPendiente: false,
            ejecucion: false,
            completada: false,
            cerrada: false,
            desierta: false
          }
        }
        GlobalVariables.filters["licitaciones"] = {
          filters: this.filters,
          open: this.filtrosOpen
        }
        localStorage.setItem('filtros', JSON.stringify(GlobalVariables.filters["licitaciones"]));
        if(this.currentUser.actividad == "Funerarias") {
          this.orden = {estado: "ABIERTA-PENDIENTE-ADJUDICADA", inicio: "", fin: ""};
        } else {
          this.orden = {estado: "PENDIENTE-ADJUDICADA-ABIERTA", inicio: "", fin: ""};
        }        
      }

      if(window.innerWidth < 576) this.filtrosOpen = false;
      this.cdr.detectChanges();

      if(this.currentUser.proveedor && this.currentUser.suscripciones.length > 0) {
        var suscrip = this.currentUser.suscripciones[this.currentUser.suscripciones.length-1];
        if(!suscrip.pagado) {
          this.pagosPendientes = true;
          this.currentUser.suscripcion = suscrip;
          this.currentUser.suscripcion.provincias = [{}];
          this.currentUser.suscripcion.paises = [{}];
          this.cdr.detectChanges();
        } else {
          suscrip.startDateStr = moment(suscrip.startDate).format("DD/MM/YYYY");
          this.currentUser.suscripcion = suscrip;
          try {
              if(this.currentUser.suscripcion.provincias) this.currentUser.suscripcion.provincias = JSON.parse(this.currentUser.suscripcion.provincias);
              if(this.currentUser.suscripcion.paises) this.currentUser.suscripcion.paises = JSON.parse(this.currentUser.suscripcion.paises);
            } catch (e) {
          }
        }
      }

      this.loadPaises();
      this.loadProvincias();
      this.loadTrazabilidad();
      this.getParticipaciones();
      
      var cbkServicios = function(res) {
        console.warn(res);
        for(var i = 0; i < res.length; i++){
          res[i].val = false;
          res[i].comentarios = null;
          res[i].importeSalida = null;
          res[i].error = {importeSalida: false};
          res[i].mostrar = false;
          this.serviciosHash[res[i].id+""] = res[i];
        }
        this.servicios = res;
      }

      var cargado = false;

      var connectSails = function(){
        console.log("CONNECT");
        if(!self.io.socket) {
          console.warn("ERROR: NO HAY SOCKET (3)!!!!!!")
          return;
        }
        if(self.io.socket._isConnecting) {
          console.warn("IS CONNECTING...")
          return;
        }

        if(!this.servicios) {
          self.io.socket.get('/servicio', cbkServicios.bind(this));
        } else {
          for(let serv of this.servicios) {
            this.serviciosHash[serv.id+""] = serv;
          }
        }

        this.loadLicitaciones();

        const source = interval(1000);
        this.subscription = source.subscribe(val => this.procesoCountdown());
      }

      if(!self.io.socket) {
        console.warn("ERROR: NO HAY SOCKET (2)!!!!!!");

        var reintentar = function(){
          console.warn("REINTENTAR...");
          if(!self.io.socket) {
            setTimeout(() => { 
              var fn2 = reintentar.bind(this);
              fn2();
            }, 1000);
          } else {
            console.warn("CONNECTED 2: " + self.io.socket.isConnected())
            if(self.io.socket.isConnected()) {
              var fn = connectSails.bind(this);
              fn();
            } else {
              console.warn("NOT CONNECTED -> WAITING");
              console.warn(self.io.socket);
      
              const socket = self.io.socket;
      
              self.io.socket.on('connect', function onConnect(){
                setTimeout(() => { 
                  if(self.io.socket) {
                    var fn = connectSails.bind(this);
                    fn();
                  } else {
                    console.warn("ERROR: NO HAY SOCKET (4)!!!!!!");
                    console.warn(socket);
                    self.io.socket = socket;
                    var fn = connectSails.bind(this);
                    fn();
                  }
                }, 500);
              }.bind(this));
              //self.io.socket.on('connect', connectSails.bind(this));
            }   
          }
        }

        setTimeout(() => { 
          var fn2 = reintentar.bind(this);
          fn2();
        }, 1000);


        return;
      }

      //console.log(self.io.socket);
      
      console.warn("CONNECTED: " + self.io.socket.isConnected())
      if(self.io.socket.isConnected()) {
        var fn = connectSails.bind(this);
        fn();
      } else {
        console.warn("NOT CONNECTED -> WAITING");
        console.warn(self.io.socket);

        const socket = self.io.socket;

        self.io.socket.on('connect', function onConnect(){
          setTimeout(() => { 
            if(self.io.socket) {
              var fn = connectSails.bind(this);
              fn();
            } else {
              console.warn("ERROR: NO HAY SOCKET!!!!!!");
              console.warn(socket);
              self.io.socket = socket;
              var fn = connectSails.bind(this);
              fn();
            }
          }, 500);
        }.bind(this));
        //self.io.socket.on('connect', connectSails.bind(this));
      }    
    
    
    });
  }
  
  ngAfterViewInit() {
    this.calcularColumnas();
  }

  getParticipaciones() {
    this.licitaService.getAllByUser(this.currentUser.id)
    .subscribe(licitas => {
      for(var i = 0; i < licitas.length; i++) {
        this.participaciones[licitas[i].licitacion+""] = true;
        if(!this.participacionesImporte[licitas[i].licitacion+""] || this.participacionesImporte[licitas[i].licitacion+""] > licitas[i].importe) this.participacionesImporte[licitas[i].licitacion+""] = licitas[i].importe;
      }
      this.filterDatatable();
			this.cdr.detectChanges();
		});
  }
  
  proceso = 0;
  procesoCountdown(){
    for(var i = 0; i < this.rows.length; i++) {
      var msStart = moment(this.rows[i].startDate).diff();
      

      if(msStart > 0){
        this.rows[i].estado = "ABRIENDO";
        var d = moment.duration(msStart);
        var days = Math.floor(d.asDays());
        var daysStr = days + "";    
        this.rows[i].days = daysStr + " días";
        var hours = Math.floor(d.asHours()) - (days*24);
        var hoursStr = "";
        if (hours < 10) hoursStr = "0" + hours
        else hoursStr = hours + "";
        var s = hoursStr + " h " + moment.utc(msStart).format("mm") + " min " + moment.utc(msStart).format("ss") + " s";
        this.rows[i].countdown = s;
      } else {
        var ms = moment(this.rows[i].endDate).diff();
      
        if(ms < 0){
          if(!this.rows[i].validada){
            this.rows[i].estado = "CERRADA_PEND";
          } else {
            this.rows[i].estado = "CERRADA";
          }
          if(this.rows[i].abierta) {
            if(this.currentRow && this.currentRow.id == this.rows[i].id) {
              this.currentRow = null;
              this.row = null; 
              this.scrollUp();
            }
            this.rows[i].abierta = false;
            this.rows[i].days = "0 días";
            this.rows[i].countdown = "00 h 00 min 00 s";
          }
          if(!this.rows[i].validada && !this.rows[i].ultimaLicitacion) {
            this.rows[i].validada = true; //La ponemos como desierta aunque no lo esté aún por el sistema.
          }
        } else {
          if(ms < 60000) this.rows[i].ultimoMinuto = true
          else this.rows[i].ultimoMinuto = false;
          this.rows[i].estado = "ABIERTA";
          var d = moment.duration(ms);
          var days = Math.floor(d.asDays());
          var daysStr = days + "";    
          this.rows[i].days = daysStr + " días";
          var hours = Math.floor(d.asHours()) - (days*24);
          var hoursStr = "";
          if (hours < 10) hoursStr = "0" + hours
          else hoursStr = hours + "";
          var s = hoursStr + " h " + moment.utc(ms).format("mm") + " min " + moment.utc(ms).format("ss") + " s";
          this.rows[i].countdown = s;
        }
      }
      if(!this.rows[i].state) this.rows[i].state = "default";


      
    }
    if(this.proceso == 0 || this.proceso == 2 || this.proceso % 5 == 0) {
      if(this.proceso == 0) {
        this.loadingInit = false;
        this.filterDatatable();
        this.calcularColumnas();
      } else if(this.proceso == 2) {
        this.filterDatatable();
      }
      this.orderDatatable();
      this.cdr.detectChanges();
    }
    this.proceso++;
    this.cdr.detectChanges();
  }

  loadLicitaciones() {
    // - EVENTOS -
    this.eventSusc = function(msg) {
      if (this.cdr['destroyed']) {
        return;
      }
      console.log(msg);
      if(msg.verb == "created"){
        msg.data.estado = "ABIERTA";
        msg.data.fecha = moment(msg.data.startDate).format("DD/MM/YYYY");
        msg.data.fechaFin = moment(msg.data.endDate).format("DD/MM/YYYY HH:mm");
        if(msg.data.ultimaLicitacion){
          msg.data.precio = msg.data.ultimaLicitacion.importe;
        } else {
          msg.data.precio = msg.data.importeSalida;
        }
        var ms = moment(msg.data.endDate).diff();
      
        if(ms < 0){
          msg.data.estado = "CERRADA";
          msg.data.days = "0 días";
          msg.data.countdown = "00 h 00 min 00 s";
        } else {
          var d = moment.duration(ms);
          var days = Math.floor(d.asDays());
          var daysStr = days + "";    
          msg.data.days = daysStr + " días";
          var hours = Math.floor(d.asHours()) - (days*24);
          var hoursStr = "";
          if (hours < 10) hoursStr = "0" + hours
          else hoursStr = hours + "";
          var s = hoursStr + " h " + moment.utc(ms).format("mm") + " min " + moment.utc(ms).format("ss") + " s";
          msg.data.countdown = s;
        }

        //Comprobamos si pertenece a nuestra suscripción
        if(this.currentUser.proveedor && this.router.url === '/licitaciones' && this.currentUser.id != msg.data.creador.id && this.currentUser.suscripcion) {
          var enc = false;
          if(this.currentUser.suscripcion.paises.length > 0) {
            for(let pais of this.currentUser.suscripcion.paises) {
              if(pais.id == msg.data.paisOrigen.id || (msg.data.paisDestino && pais.id == msg.data.paisDestino.id)) enc = true;
            }
          } else if(this.currentUser.suscripcion.provincias.length > 0) {
            for(let provincia of this.currentUser.suscripcion.provincias) {
              if(provincia.id == msg.data.provinciaOrigen.id || (msg.data.provinciaDestino && provincia.id == msg.data.provinciaDestino.id)) enc = true;
            }
          } else {
            enc = true;
          }
          if(enc) msg.data.suscripcion = true;
        } else {
          msg.data.suscripcion = true;
        }
        msg.state = "default";

        this.originalRows = [...this.originalRows, msg.data];
        this.filterDatatable();
        this.cdr.detectChanges();
      } else if(msg.verb == "updated"){
        if(msg.data.ultimaLicitacion){
          var loadLicit = function(res){
            for(var i = 0; i < this.originalRows.length; i++){
              if(this.originalRows[i].id == msg.data.id){
                this.originalRows[i].precio = res.importe;
                this.originalRows[i].ultimaLicitacion = res;
                this.originalRows[i].ultimaLicitacion.user = res.user.id;
                this.filterDatatable();
                break;
              }
            }
          }
          self.io.socket.get('/licita/'+msg.data.ultimaLicitacion, loadLicit.bind(this));
        } else if(msg.data.borrada) { //Licitación eliminada
          for(var i = 0; i < this.originalRows.length; i++){
            if(this.originalRows[i].id == msg.data.id){
              this.originalRows[i].borrada = true;
              this.filterDatatable();
              break;
            }
          }
        } else if(msg.data.validada || msg.data.trazas) {  //Si se ha validado una licitación. La cargamos de nuevo.
          for(var i = 0; i < this.originalRows.length; i++){
            if(this.originalRows[i].id == msg.data.id){
              self.io.socket.get('/licitacion/' + msg.data.id, function (resData) {
                if(msg.data.validada) {
                  if(msg.data.licitacionElegida) this.originalRows[i].licitacionElegida = resData.licitacionElegida;
                  if(msg.data.usuarioElegido) this.originalRows[i].usuarioElegido = resData.usuarioElegido;
                  this.originalRows[i].validada = true;
                }
                if(msg.data.trazas) this.originalRows[i].trazas = resData.trazas;

                if(this.originalRows[i].trazas) { //Esto es que ya está validado
                  var trazas = JSON.parse(this.originalRows[i].trazas);
                  this.originalRows[i].trazabilidad = [];
                  this.cdr.detectChanges();
                  var comp = 0;
                  if(this.row && this.row.id == msg.data.id) this.trazas = [];
                  for(let traza in trazas){
                    if(trazas[traza].completado) {
                      comp++;
                    }
                    trazas[traza].codigo = traza;
                    if(this.row && this.row.id == msg.data.id) this.trazas.push(trazas[traza]);
                    this.originalRows[i].trazabilidad.push(trazas[traza]);
                  }
                  this.originalRows[i].trazas_comp = comp;
                }
                this.filterDatatable();
              }.bind(this));


              break;
            }
          }
        } else if(msg.data.endDate && !msg.data.importeSalida) { //Si se ha alargado el tiempo
          for(var i = 0; i < this.originalRows.length; i++){
            if(this.originalRows[i].id == msg.data.id){
              this.originalRows[i].endDate = msg.data.endDate;


              this.filterDatatable();
              break;
            }
          }
        } else if(msg.data.id && msg.data.importeSalida) {
          self.io.socket.get('/licitacion/' + msg.data.id, function (resData) {
            resData.estado = "ABIERTA";
            resData.fecha = moment(resData.startDate).format("DD/MM/YYYY");
            resData.fechaFin = moment(resData.endDate).format("DD/MM/YYYY HH:mm");
            if(resData.ultimaLicitacion){
              resData.precio = resData.ultimaLicitacion.importe;
            } else {
              resData.precio = resData.importeSalida;
            }

            //Comprobamos si pertenece a nuestra suscripción
            if(this.currentUser.proveedor && this.router.url === '/licitaciones' && this.currentUser.id != resData.creador.id && this.currentUser.suscripcion) {
              var enc = false;
              if(this.currentUser.suscripcion.paises.length > 0) {
                for(let pais of this.currentUser.suscripcion.paises) {
                  if(pais.id == resData.paisOrigen.id || (resData.paisDestino && pais.id == resData.paisDestino.id)) enc = true;
                }
              } else if(this.currentUser.suscripcion.provincias.length > 0) {
                for(let provincia of this.currentUser.suscripcion.provincias) {
                  if(provincia.id == resData.provinciaOrigen.id || (resData.provinciaDestino && provincia.id == resData.provinciaDestino.id)) enc = true;
                }
              } else {
                enc = true;
              }
              if(enc) resData.suscripcion = true;
            } else {
              resData.suscripcion = true;
            }

            for(var i = 0; i < this.originalRows.length; i++){
              if(this.originalRows[i].id == msg.data.id){
                this.originalRows[i] = resData;
              }
            }
            this.filterDatatable();
          }.bind(this));
        }
      }
    }

    this.originalRows = [];

    var loadedLicitaciones = function(resData){
      //this.originalRows = resData;
      //this.originalRows.concat(resData);
      this.originalRows.push(...resData);
      console.log(resData);
      console.log(this.originalRows);
      
      for(var i = 0; i < this.originalRows.length; i++) {
        this.originalRows[i].estado = "ABIERTA";
        this.originalRows[i].fecha = moment(this.originalRows[i].startDate).format("DD/MM/YYYY");
        this.originalRows[i].fechaFin = moment(this.originalRows[i].endDate).format("DD/MM/YYYY HH:mm");
        if(this.originalRows[i].ultimaLicitacion){
          this.originalRows[i].precio = this.originalRows[i].ultimaLicitacion.importe;
        } else {
          this.originalRows[i].precio = this.originalRows[i].importeSalida;
        }
        var ms = moment(this.originalRows[i].endDate).diff();
      
        if(ms < 0){
          this.originalRows[i].estado = "CERRADA";
          this.originalRows[i].days = "0 días";
          this.originalRows[i].countdown = "00 h 00 min 00 s";
        } else {
          var d = moment.duration(ms);
          var hours = Math.floor(d.asHours());
          var hoursStr = "";
          if (hours < 10) hoursStr = "0" + hours
          else hoursStr = hours + "";
          var s = hoursStr + moment.utc(ms).format(":mm:ss");
          this.originalRows[i].countdown = s;
        }

        //Comprobamos si pertenece a nuestra suscripción
        if(this.currentUser.proveedor && this.router.url === '/licitaciones' && this.currentUser.id != this.originalRows[i].creador.id && this.currentUser.suscripcion) {
          var enc = false;
          if(this.currentUser.suscripcion.paises.length > 0) {
            for(let pais of this.currentUser.suscripcion.paises) {
              if(pais.id == this.originalRows[i].paisOrigen.id || (this.originalRows[i].paisDestino && pais.id == this.originalRows[i].paisDestino.id)) enc = true;
            }
          } else if(this.currentUser.suscripcion.provincias.length > 0) {
            for(let provincia of this.currentUser.suscripcion.provincias) {
              if(provincia.id == this.originalRows[i].provinciaOrigen.id || (this.originalRows[i].provinciaDestino && provincia.id == this.originalRows[i].provinciaDestino.id)) enc = true;
            }
          } else {
            enc = true;
          }
          if(enc) this.originalRows[i].suscripcion = true;
        } else {
          this.originalRows[i].suscripcion = true;
        }
        this.originalRows[i].state = "default";

        if(this.originalRows[i].trazas) { //Esto es que ya está validado
          var trazas = JSON.parse(this.originalRows[i].trazas);
          this.originalRows[i].trazabilidad = [];
          this.cdr.detectChanges();
          var comp = 0;
          for(let traza in trazas){
            if(trazas[traza].completado) {
              comp++;
            }
            trazas[traza].codigo = traza;
            this.originalRows[i].trazabilidad.push(trazas[traza]);
          } 
          this.originalRows[i].trazas_comp = comp;
        }

        if(this.originalRows[i].validada && this.originalRows[i].pujas && this.originalRows[i].licitacionElegida) {
          //Cogemos el precio del elegido, por si es superior a la última.
          this.originalRows[i].precio = this.originalRows[i].licitacionElegida.importe;
        }
      }
      this.filterDatatable();
      this.cdr.detectChanges();
    }

    self.io.socket.get('/licitacion', loadedLicitaciones.bind(this));

    /*if(this.router.url === '/licitaciones') {
      self.io.socket.get('/licitacion', {abierta: 1}, loadedLicitaciones.bind(this));
      self.io.socket.get('/licitacion', {abierta: 0, validada: 0}, loadedLicitaciones.bind(this));
      self.io.socket.get('/licitacion', {abierta: 0, validada: 1, licitacionElegida: { '!=' : null }}, loadedLicitaciones.bind(this));
    } else {
      self.io.socket.get('/licitacion', loadedLicitaciones.bind(this));
    }*/

    self.io.socket.on('licitacion', this.eventSusc.bind(this));
  }

  thousands_separators(num)
  {
    if(num){ 
      var num_parts = num.toString().split(".");
      num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      return num_parts.join(".");
    } else {
      return '';
    }
    
  }

  loadTrazabilidad(){
		this.trazabilidadService.getAll()
	    .subscribe(trazas => {
        this.trazabilidad = trazas;
        this.cdr.detectChanges();
        for(var i = 0; i < trazas.length; i++){
          this.trazabilidadHash[trazas[i].codigo+""] = trazas[i];
        }
      });
  }

  loadProvincias(){
		this.stateService.getAll()
	    .subscribe(estados => {
        this.estados = estados;
        this.cdr.detectChanges();

        this.countryChangedOrigen();
        this.countryChangedDestino();
      });
  }

  loadPaises() {
		this.countryService.getAll()
	    .subscribe(paises => {
			this.paises = paises;
      this.cdr.detectChanges();
		});
	}

  ngOnDestroy(){
    if(this.subscription) this.subscription.unsubscribe();
    if(self.io.socket) self.io.socket.off('licitacion', this.eventSusc);
    if(self.io.socket) self.io.socket.off('user', this.userSusc);
  }

  getRowClass = (row) => {
    if(row.creador.id == this.currentUser.id) return 'table-bck-blue'
    else if(row.ultimaLicitacion && row.ultimaLicitacion.user == this.currentUser.id) return 'table-bck-green';
    return '';
  }

  // ------- FILTROS ---------

  filters = null;

  countryChangedOrigen() {
    this.estadosOrigen = [];
    if(this.filters.paisOrigen != "TODOS") {
      for(var i = 0; i < this.estados.length; i++) {
        if(this.estados[i].countryId == this.filters.paisOrigen) this.estadosOrigen.push(this.estados[i]);
      }
    }
    this.cdr.detectChanges();
    this.filterDatatable();
  }

  countryChangedDestino() {
    this.estadosDestino = [];
    if(this.filters.paisDestino != "TODOS") {
      for(var i = 0; i < this.estados.length; i++) {
        if(this.estados[i].countryId == this.filters.paisDestino) this.estadosDestino.push(this.estados[i]);
      }
    }
    this.cdr.detectChanges();
    this.filterDatatable();
  }

  mostrarFiltros() {
    this.filtrosOpen = !this.filtrosOpen;
    GlobalVariables.filters["licitaciones"].open = this.filtrosOpen;
    localStorage.setItem('filtros', JSON.stringify(GlobalVariables.filters["licitaciones"]));
    this.cdr.detectChanges();
    if(this.currentUser.status == "INVITADO" && this.filtrosOpen){
      this.registerSwal.fire();
    } else if(this.currentUser.status == "PENDIENTE" && this.filtrosOpen){
      this.registerValidarSwal.fire();
    }
    this.calcularColumnas();
  }

  checkInvitado() {
    if(this.currentUser.status == "INVITADO" && this.filtrosOpen){
      this.registerSwal.fire();
    } else if(this.currentUser.status == "PENDIENTE" && this.filtrosOpen){
      this.registerValidarSwal.fire();
    }
  }

  filterDatatable(){
    this.rows = [];
    this.cdr.detectChanges();
    GlobalVariables.filters["licitaciones"].filters = this.filters;
    GlobalVariables.filters["licitaciones"].servicios = this.servicios;

    GlobalVariables.orden = this.orden;

    localStorage.setItem('filtros', JSON.stringify(GlobalVariables.filters["licitaciones"]));
    localStorage.setItem('orden', JSON.stringify(GlobalVariables.orden));
    localStorage.setItem('ordenMostrar', JSON.stringify(this.ordenMostrar));

    this.rows = this.originalRows.filter(function(item){
      var mostrar = true;
      if(item.borrada) mostrar = false;
      if(this.router.url === '/mislicitaciones'){
        if(item.creador.id != this.currentUser.id && !this.participaciones[item.id]) mostrar = false;
      } else if(this.router.url === '/trazabilidad'){
        if(item.creador.id == this.currentUser.id || (item.creador.id != this.currentUser.id && item.validada && item.usuarioElegido && item.usuarioElegido.id == this.currentUser.id)) {
          //mostrar = false;
        } else {
          mostrar = false
        }
        if(!item.trazabilidad) mostrar = false;
        if(item.estado != 'CERRADA') mostrar = false;
      } else if(this.router.url === '/pendientes'){
        if(item.abierta || item.validada || item.creador.id != this.currentUser.id) mostrar = false;
      } else if(this.router.url === '/licitaciones'){
        if(item.validada && !item.ultimaLicitacion) mostrar = false;
      }

      if(this.currentUser.bloqueadosMi)
        for(let bloqueo of this.currentUser.bloqueadosMi) {
          if(bloqueo.bloqueado == this.currentUser.id && item.creador.id == bloqueo.user && !this.participaciones[item.id]) {
            mostrar = false;
          }
        }

      //Servicios
      var sel = false; //Comprobamos si hay alguno seleccionado
      if(this.servicios) {
        for(let serv_orig of this.servicios) {
          if(serv_orig.mostrar) sel = true;
        }
        if(this.filters.servicioTraslado) sel = true;
        if(sel) {
          if(item.tipo == "TRASLADO" && !this.filters.servicioTraslado) mostrar = false;
          if(item.tipo == "SERVICIO") {
            var enc = false;
            for (let servicio of item.servicios) {
              for(let serv_orig of this.servicios) {
                if(serv_orig.mostrar && servicio.id == serv_orig.id) enc = true;
              }
            }
            if(!enc) mostrar = false;
          }
        }
      }
      
      if(this.filters.referencia != null && this.filters.referencia != "" && !item.referencia.toUpperCase().includes(this.filters.referencia.toUpperCase()) 
          && !item.referenciaCliente.toUpperCase().includes(this.filters.referencia.toUpperCase())) mostrar = false;

      if(item.tipo == "TRASLADO") {
        if(this.filters.paisOrigen != "TODOS" && item.paisOrigen.id != this.filters.paisOrigen) mostrar = false;
        if(this.filters.provinciaOrigen != "TODOS" && item.provinciaOrigen.id != this.filters.provinciaOrigen) mostrar = false;

        if(this.filters.paisDestino != "TODOS" && item.paisDestino && item.paisDestino.id != this.filters.paisDestino) mostrar = false;
        if(this.filters.provinciaDestino != "TODOS" && item.provinciaDestino && item.provinciaDestino.id != this.filters.provinciaDestino) mostrar = false;
      } else if(item.tipo == "SERVICIO") {
        //if(this.filters.paisOrigen != "TODOS") mostrar = false;

        if(this.filters.paisDestino != "TODOS" && item.paisOrigen && item.paisOrigen.id != this.filters.paisDestino) mostrar = false;
        if(this.filters.provinciaDestino != "TODOS" && item.provinciaOrigen && item.provinciaOrigen.id != this.filters.provinciaDestino) mostrar = false;
      }

      
      
      if(this.filters.fechaInicio && this.filters.fechaInicio != null) {
        var ini = moment(this.filters.fechaInicio).unix();
        var orig = moment(item.startDate).unix();
        if(orig < ini) mostrar = false;
      }

      if(this.filters.fechaFin && this.filters.fechaFin != null) {
        var fin = moment(this.filters.fechaFin).unix();
        var orig = moment(item.startDate).unix();
        if(orig > fin) mostrar = false;
      }

      if(this.filters.precioMin != null && this.filters.precioMin > -1 && item.precio < this.filters.precioMin) mostrar = false;
      if(this.filters.precioMax != null && this.filters.precioMax > 0 && item.precio > this.filters.precioMax) mostrar = false;

      /*
      pendienteInicio: false,
      abierta: false,
      pendienteAdjudicacion: false,
      adjudicacionTramite: false,
      adjudicadaPendiente: false,
      ejecucion: false,
      completada: false,
      cerrada: false,
      desierta: false
      */

      //Estados
      var sel = false; //Comprobamos si hay alguno seleccionado
      for(let estado in this.filters.estados) {
        if(this.filters.estados[estado]) sel = true;
      }
      if(sel) {
        var estados = this.filters.estados;
        var enc = false;
        if(estados.pendienteInicio && item.estado == 'ABRIENDO') enc = true;
        if(estados.abierta && item.estado == 'ABIERTA') enc = true;
        if(estados.pendienteAdjudicacion && !item.abierta && !item.validada && item.creador.id == this.currentUser.id) enc = true;
        if(estados.adjudicacionTramite && !item.abierta && !item.validada && item.creador.id != this.currentUser.id && this.participaciones[item.id]) enc = true;
        if(estados.adjudicadaPendiente && item.estado == 'CERRADA' && item.creador.id == this.currentUser.id && item.licitacionElegida && (!item.trazabilidad || item.trazas_comp != item.trazabilidad.length)) enc = true;
        if(estados.ejecucion && item.estado == 'CERRADA' && item.usuarioElegido && item.usuarioElegido.id == this.currentUser.id && item.licitacionElegida && (!item.trazabilidad || item.trazas_comp != item.trazabilidad.length)) enc = true;
        if(estados.completada && item.estado == 'CERRADA' && (item.creador.id == this.currentUser.id || (item.usuarioElegido && item.usuarioElegido.id == this.currentUser.id)) && item.licitacionElegida && item.trazabilidad && item.trazas_comp == item.trazabilidad.length) enc = true;
        if(estados.cerrada && !item.abierta && !item.validada && item.creador.id != this.currentUser.id && !this.participaciones[item.id]) enc = true;
        if(estados.cerrada && item.estado == 'CERRADA' && item.creador.id != this.currentUser.id && item.licitacionElegida && (!this.participaciones[item.id] || this.participaciones[item.id] && (!item.usuarioElegido || item.usuarioElegido.id != this.currentUser.id))) enc = true;
        if(estados.desierta && item.estado == 'CERRADA' && item.validada && !item.licitacionElegida) enc = true;
        if(!enc) mostrar = false;
      }

      // iterate through each row's column data
      return mostrar;
    }.bind(this));
    
    var temp = [...this.rows];
    this.rows = [];
    this.rows = temp;
    
    this.orderDatatable();
    this.cdr.detectChanges();
  }

  orderDatatable(){
    if(this.orden.estado == "PENDIENTE-ADJUDICADA-ABIERTA") {
      this.rows.sort(function (a, b) {
        /*pendienteInicio*/ if(a.estado == 'ABRIENDO') a.valor = 4;
        /*abierta*/ if(a.estado == 'ABIERTA') a.valor = 5;
        /*pendienteAdjudicacion*/ if(!a.abierta && !a.validada && a.creador.id == this.currentUser.id) a.valor = 7;
        /*adjudicacionTramite*/ if(!a.abierta && !a.validada && a.creador.id != this.currentUser.id && this.participaciones[a.id]) a.valor = 7;
        /*adjudicadaPendiente*/ if(a.estado == 'CERRADA' && a.creador.id == this.currentUser.id && a.licitacionElegida && (!a.trazabilidad || a.trazas_comp != a.trazabilidad.length)) a.valor = 6;
        /*ejecucion*/ if(a.estado == 'CERRADA' && a.usuarioElegido && a.usuarioElegido.id == this.currentUser.id && a.licitacionElegida && (!a.trazabilidad || a.trazas_comp != a.trazabilidad.length)) a.valor = 6;
        /*completada*/ if(a.estado == 'CERRADA' && (a.creador.id == this.currentUser.id || (a.usuarioElegido && a.usuarioElegido.id == this.currentUser.id)) && a.licitacionElegida && a.trazabilidad && a.trazas_comp == a.trazabilidad.length) a.valor = 6;
        /*cerrada*/ if(!a.abierta && !a.validada && a.creador.id != this.currentUser.id && !this.participaciones[a.id]) a.valor = 2;
        /*cerrada*/ if(a.estado == 'CERRADA' && a.creador.id != this.currentUser.id && a.licitacionElegida && (!this.participaciones[a.id] || this.participaciones[a.id] && (!a.usuarioElegido || a.usuarioElegido.id != this.currentUser.id))) a.valor = 2;
        /*desierta*/ if(a.estado == 'CERRADA' && a.validada && !a.licitacionElegida) a.valor = 1;
  
        /*pendienteInicio*/ if(b.estado == 'ABRIENDO') b.valor = 4;
        /*abierta*/ if(b.estado == 'ABIERTA') b.valor = 5;
        /*pendienteAdjudicacion*/ if(!b.abierta && !b.validada && b.creador.id == this.currentUser.id) b.valor = 7;
        /*adjudicacionTramite*/ if(!b.abierta && !b.validada && b.creador.id != this.currentUser.id && this.participaciones[b.id]) b.valor = 7;
        /*adjudicadaPendiente*/ if(b.estado == 'CERRADA' && b.creador.id == this.currentUser.id && b.licitacionElegida && (!b.trazabilidad || b.trazas_comp != b.trazabilidad.length)) b.valor = 6;
        /*ejecucion*/ if(b.estado == 'CERRADA' && b.usuarioElegido && b.usuarioElegido.id == this.currentUser.id && b.licitacionElegida && (!b.trazabilidad || b.trazas_comp != b.trazabilidad.length)) b.valor = 6;
        /*completada*/ if(b.estado == 'CERRADA' && (b.creador.id == this.currentUser.id || (b.usuarioElegido && b.usuarioElegido.id == this.currentUser.id)) && b.licitacionElegida && b.trazabilidad && b.trazas_comp == b.trazabilidad.length) b.valor = 6;
        /*cerrada*/ if(!b.abierta && !b.validada && b.creador.id != this.currentUser.id && !this.participaciones[b.id]) b.valor = 2;
        /*cerrada*/ if(b.estado == 'CERRADA' && b.creador.id != this.currentUser.id && b.licitacionElegida && (!this.participaciones[b.id] || this.participaciones[b.id] && (!b.usuarioElegido || b.usuarioElegido.id != this.currentUser.id))) b.valor = 2;
        /*desierta*/ if(b.estado == 'CERRADA' && a.validada && !b.licitacionElegida) b.valor = 1;
  
        if (a.valor > b.valor) {
          return -1;
        }
        if (a.valor < b.valor) {
          return 1;
        }
        // a must be equal to b
        var msA = moment(a.endDate).diff();
        var msB = moment(b.endDate).diff();
        if(msA > 0) {
          if (msA > msB) {
            return 1;
          } else if (msA < msB) {
            return -1;
          }
        } else {
          if (msA > msB) {
            return -1;
          } else if (msA < msB) {
            return 1;
          }
        }
        return ('' + a.referencia).localeCompare(b.referencia);
      }.bind(this));
    } else if(this.orden.estado == "ABIERTA-PENDIENTE-ADJUDICADA") {
      this.rows.sort(function (a, b) {
        /*pendienteInicio*/ if(a.estado == 'ABRIENDO') a.valor = 6;
        /*abierta*/ if(a.estado == 'ABIERTA') a.valor = 7;
        /*pendienteAdjudicacion*/ if(!a.abierta && !a.validada && a.creador.id == this.currentUser.id) a.valor = 5;
        /*adjudicacionTramite*/ if(!a.abierta && !a.validada && a.creador.id != this.currentUser.id && this.participaciones[a.id]) a.valor = 5;
        /*adjudicadaPendiente*/ if(a.estado == 'CERRADA' && a.creador.id == this.currentUser.id && a.licitacionElegida && (!a.trazabilidad || a.trazas_comp != a.trazabilidad.length)) a.valor = 4;
        /*ejecucion*/ if(a.estado == 'CERRADA' && a.usuarioElegido && a.usuarioElegido.id == this.currentUser.id && a.licitacionElegida && (!a.trazabilidad || a.trazas_comp != a.trazabilidad.length)) a.valor = 4;
        /*completada*/ if(a.estado == 'CERRADA' && (a.creador.id == this.currentUser.id || (a.usuarioElegido && a.usuarioElegido.id == this.currentUser.id)) && a.licitacionElegida && a.trazabilidad && a.trazas_comp == a.trazabilidad.length) a.valor = 4;
        /*cerrada*/ if(!a.abierta && !a.validada && a.creador.id != this.currentUser.id && !this.participaciones[a.id]) a.valor = 2;
        /*cerrada*/ if(a.estado == 'CERRADA' && a.creador.id != this.currentUser.id && a.licitacionElegida && (!this.participaciones[a.id] || this.participaciones[a.id] && (!a.usuarioElegido || a.usuarioElegido.id != this.currentUser.id))) a.valor = 2;
        /*desierta*/ if(a.estado == 'CERRADA' && a.validada && !a.licitacionElegida) a.valor = 1;
  
        /*pendienteInicio*/ if(b.estado == 'ABRIENDO') b.valor = 6;
        /*abierta*/ if(b.estado == 'ABIERTA') b.valor = 7;
        /*pendienteAdjudicacion*/ if(!b.abierta && !b.validada && b.creador.id == this.currentUser.id) b.valor = 5;
        /*adjudicacionTramite*/ if(!b.abierta && !b.validada && b.creador.id != this.currentUser.id && this.participaciones[b.id]) b.valor = 5;
        /*adjudicadaPendiente*/ if(b.estado == 'CERRADA' && b.creador.id == this.currentUser.id && b.licitacionElegida && (!b.trazabilidad || b.trazas_comp != b.trazabilidad.length)) b.valor = 4;
        /*ejecucion*/ if(b.estado == 'CERRADA' && b.usuarioElegido && b.usuarioElegido.id == this.currentUser.id && b.licitacionElegida && (!b.trazabilidad || b.trazas_comp != b.trazabilidad.length)) b.valor = 4;
        /*completada*/ if(b.estado == 'CERRADA' && (b.creador.id == this.currentUser.id || (b.usuarioElegido && b.usuarioElegido.id == this.currentUser.id)) && b.licitacionElegida && b.trazabilidad && b.trazas_comp == b.trazabilidad.length) b.valor = 4;
        /*cerrada*/ if(!b.abierta && !b.validada && b.creador.id != this.currentUser.id && !this.participaciones[b.id]) b.valor = 2;
        /*cerrada*/ if(b.estado == 'CERRADA' && b.creador.id != this.currentUser.id && b.licitacionElegida && (!this.participaciones[b.id] || this.participaciones[b.id] && (!b.usuarioElegido || b.usuarioElegido.id != this.currentUser.id))) b.valor = 2;
        /*desierta*/ if(b.estado == 'CERRADA' && a.validada && !b.licitacionElegida) b.valor = 1;
  
        if (a.valor > b.valor) {
          return -1;
        }
        if (a.valor < b.valor) {
          return 1;
        }
        // a must be equal to b
        var msA = moment(a.endDate).diff();
        var msB = moment(b.endDate).diff();
        if(msA > 0) {
          if (msA > msB) {
            return 1;
          } else if (msA < msB) {
            return -1;
          }
        } else {
          if (msA > msB) {
            return -1;
          } else if (msA < msB) {
            return 1;
          }
        }
        return ('' + a.referencia).localeCompare(b.referencia);
      }.bind(this));
    } else if(this.orden.estado == "ADJUDICADA-PENDIENTE-ABIERTA") {
      this.rows.sort(function (a, b) {
        /*pendienteInicio*/ if(a.estado == 'ABRIENDO') a.valor = 4;
        /*abierta*/ if(a.estado == 'ABIERTA') a.valor = 5;
        /*pendienteAdjudicacion*/ if(!a.abierta && !a.validada && a.creador.id == this.currentUser.id) a.valor = 6;
        /*adjudicacionTramite*/ if(!a.abierta && !a.validada && a.creador.id != this.currentUser.id && this.participaciones[a.id]) a.valor = 6;
        /*adjudicadaPendiente*/ if(!a.abierta && a.validada && a.creador.id == this.currentUser.id && a.licitacionElegida && (!a.trazabilidad || a.trazas_comp != a.trazabilidad.length)) a.valor = 7;
        /*ejecucion*/ if(!a.abierta && a.validada && a.usuarioElegido && a.usuarioElegido.id == this.currentUser.id && a.licitacionElegida && (!a.trazabilidad || a.trazas_comp != a.trazabilidad.length)) a.valor = 7;
        /*completada*/ if(!a.abierta && a.validada && (a.creador.id == this.currentUser.id || (a.usuarioElegido && a.usuarioElegido.id == this.currentUser.id)) && a.licitacionElegida && a.trazabilidad && a.trazas_comp == a.trazabilidad.length) a.valor = 7;
        /*cerrada*/ if(!a.abierta && !a.validada && a.creador.id != this.currentUser.id && !this.participaciones[a.id]) a.valor = 2;
        /*cerrada*/ if(!a.abierta && a.validada && a.creador.id != this.currentUser.id && a.licitacionElegida && (!this.participaciones[a.id] || this.participaciones[a.id] && (!a.usuarioElegido || a.usuarioElegido.id != this.currentUser.id))) a.valor = 2;
        /*desierta*/ if(!a.abierta && a.validada && a.validada && !a.licitacionElegida) a.valor = 1;
  
        /*pendienteInicio*/ if(b.estado == 'ABRIENDO') b.valor = 4;
        /*abierta*/ if(b.estado == 'ABIERTA') b.valor = 5;
        /*pendienteAdjudicacion*/ if(!b.abierta && !b.validada && b.creador.id == this.currentUser.id) b.valor = 6;
        /*adjudicacionTramite*/ if(!b.abierta && !b.validada && b.creador.id != this.currentUser.id && this.participaciones[b.id]) b.valor = 6;
        /*adjudicadaPendiente*/ if(!b.abierta && b.validada && b.creador.id == this.currentUser.id && b.licitacionElegida && (!b.trazabilidad || b.trazas_comp != b.trazabilidad.length)) b.valor = 7;
        /*ejecucion*/ if(!b.abierta && b.validada && b.usuarioElegido && b.usuarioElegido.id == this.currentUser.id && b.licitacionElegida && (!b.trazabilidad || b.trazas_comp != b.trazabilidad.length)) b.valor = 7;
        /*completada*/ if(!b.abierta && b.validada && (b.creador.id == this.currentUser.id || (b.usuarioElegido && b.usuarioElegido.id == this.currentUser.id)) && b.licitacionElegida && b.trazabilidad && b.trazas_comp == b.trazabilidad.length) b.valor = 7;
        /*cerrada*/ if(!b.abierta && !b.validada && b.creador.id != this.currentUser.id && !this.participaciones[b.id]) b.valor = 2;
        /*cerrada*/ if(!b.abierta && b.validada && b.creador.id != this.currentUser.id && b.licitacionElegida && (!this.participaciones[b.id] || this.participaciones[b.id] && (!b.usuarioElegido || b.usuarioElegido.id != this.currentUser.id))) b.valor = 2;
        /*desierta*/ if(!b.abierta && b.validada && a.validada && !b.licitacionElegida) b.valor = 1;
  
        if (a.valor > b.valor) {
          return -1;
        }
        if (a.valor < b.valor) {
          return 1;
        }
        // a must be equal to b
        var msA = moment(a.endDate).diff();
        var msB = moment(b.endDate).diff();
        if(msA > 0) {
          if (msA > msB) {
            return 1;
          } else if (msA < msB) {
            return -1;
          }
        } else {
          if (msA > msB) {
            return -1;
          } else if (msA < msB) {
            return 1;
          }
        }
        return ('' + a.referencia).localeCompare(b.referencia);
      }.bind(this));
    } else if(this.orden.inicio == "ASC") {
      this.rows.sort(function (a, b) {
        var msA = moment(a.startDate).diff();
        var msB = moment(b.startDate).diff();
        if (msA > msB) {
          return 1;
        } else if (msA < msB) {
          return -1;
        }
        return ('' + a.referencia).localeCompare(b.referencia);
      }.bind(this));
    } else if(this.orden.inicio == "DESC") {
      this.rows.sort(function (a, b) {
        var msA = moment(a.startDate).diff();
        var msB = moment(b.startDate).diff();
        if (msA < msB) {
          return 1;
        } else if (msA > msB) {
          return -1;
        }
        return ('' + a.referencia).localeCompare(b.referencia);
      }.bind(this));
    } else if(this.orden.fin == "ASC") {
      this.rows.sort(function (a, b) {
        var msA = moment(a.endDate).diff();
        var msB = moment(b.endDate).diff();
        if(msA < 0 && msB > 0) return 1;
        if(msB < 0 && msA > 0) return -1;
        if (msA > msB) {
          return 1;
        } else if (msA < msB) {
          return -1;
        }
        return ('' + a.referencia).localeCompare(b.referencia);
      }.bind(this));
    } else if(this.orden.fin == "DESC") {
      this.rows.sort(function (a, b) {
        var msA = moment(a.endDate).diff();
        var msB = moment(b.endDate).diff();
        if (msA < msB) {
          return 1;
        } else if (msA > msB) {
          return -1;
        }
        return ('' + a.referencia).localeCompare(b.referencia);
      }.bind(this));
    }
  }

  resetFilters(){
    var pais = "TODOS";
    /*if(this.currentUser.proveedor) {
      pais = this.currentUser.pais.id;
    }*/
    this.filters = {
      servicio: "TODOS", 
      paisOrigen: pais, 
      provinciaOrigen: "TODOS", 
      paisDestino: pais, 
      provinciaDestino: "TODOS",
      fechaInicio: null,
      fechaFin: null,
      precioMin: null,
      precioMax: null,
      estado: "TODOS",
      //NUEVOS
      referenciaMostrar: false,
      referencia: null,
      servicioMostrar: false,
      servicioTraslado: false,
      comunidadOrigenMostrar: false,
      comunidadDestinoMostrar: false,
      paisOrigenMostrar: false,
      paisDestinoMostrar: false,
      fechaDesdeMostrar: false,
      fechaHastaMostrar: false,
      precioMinMostrar: false,
      precioMaxMostrar: false,
      estadoMostrar: false,
      estados: {
        pendienteInicio: false,
        abierta: false,
        pendienteAdjudicacion: false,
        cerrada: false,
        adjudicacionTramite: false,
        adjudicadaPendiente: false,
        ejecucion: false,
        completada: false,
        desierta: false
      }
    }
    this.countryChangedOrigen();
    this.countryChangedDestino();

    for(let serv_orig of this.servicios) {
      serv_orig.mostrar = false;
    }
    this.filterDatatable();
  }

  // ------- ORDEN ---------

  orden = {estado: "", inicio: "", fin: ""};
  ordenMostrar = {estado: false, estadoSelect: true, inicio: false, inicioSelect: false, fin: false, finSelect: false}

  ordenarEstado(orden){
    this.orden = {estado: orden, inicio: "", fin: ""};
    this.ordenMostrar = {estado: true, estadoSelect: true, inicio: false, inicioSelect: false, fin: false, finSelect: false};
    this.cdr.detectChanges();
    this.filterDatatable();
  }

  ordenarInicio(orden){
    this.orden = {estado: "", inicio: orden, fin: ""};
    this.ordenMostrar = {estado: false, estadoSelect: false, inicio: true, inicioSelect: true, fin: false, finSelect: false};
    this.cdr.detectChanges();
    this.filterDatatable();
  }

  ordenarFin(orden){
    this.orden = {estado: "", inicio: "", fin: orden};
    this.ordenMostrar = {estado: false, estadoSelect: false, inicio: false, inicioSelect: false, fin: true, finSelect: true};
    this.cdr.detectChanges();
    this.filterDatatable();
  }

  registrarme() {
    //this.router.navigate(['/start']);
    this.ngZone.run(() => this.router.navigate(['/start'])).then();
  }

  perfil() {
    //this.router.navigate(['/perfil']);
    this.ngZone.run(() => this.router.navigate(['/perfil'])).then();
  }

  // ------- MODAL -------

  referenciaCliente = null;
  tipo = null;
  traslado = false;

  tipoTraslado = null;
  km = null;
  importeTraslado = null;
  comentariosTraslado = null;

  fechaInicio: any;
  fechaFin: any;
  error = {referencia: false, tipo: false, origen: false, destino: false, tipoTraslado: false, km: false, importeTraslado: false, comentariosTraslado: false, fechaInicio: false, fechaFin: false, fechaOrden: false, fechaHoy: false, servicios: false};

  provinciaOrigen = null;
  poblacionOrigen = null;

  provinciaDestino = null;
  poblacionDestino = null;

  estadosOrigenNew = []
  estadosDestinoNew = [];

  countryChangedOrigenNew() {
    this.estadosOrigenNew = [];
    for(var i = 0; i < this.estados.length; i++) {
      if(this.estados[i].countryId == this.paisOrigen) this.estadosOrigenNew.push(this.estados[i]);
    }
    this.cdr.detectChanges();
  }

  countryChangedDestinoNew() {
    this.estadosDestinoNew = [];
    for(var i = 0; i < this.estados.length; i++) {
      if(this.estados[i].countryId == this.paisDestino) this.estadosDestinoNew.push(this.estados[i]);
    }
    this.cdr.detectChanges();
  }
  
  page = 1;

  openDialog(content) {
    this.referenciaCliente = null;
    this.tipo = null;
    this.traslado = false;

    this.tipoTraslado = null;
    this.km = null;
    this.importeTraslado = null;
    this.comentariosTraslado = null;

    this.fechaInicio = null;
    this.fechaFin = null;
    this.error = {referencia: false, tipo: false, origen: false, destino: false, tipoTraslado: false, km: false, importeTraslado: false, comentariosTraslado: false, fechaInicio: false, fechaFin: false, fechaOrden: false, fechaHoy: false, servicios: false};

    this.paisOrigen = null;
    this.provinciaOrigen = null;
    this.poblacionOrigen = null;

    this.paisDestino = null;
    this.provinciaDestino = null;
    this.poblacionDestino = null;

    this.estadosOrigenNew = []
    this.estadosDestinoNew = [];

    for(let serv of this.servicios) {
      serv.error = {importeSalida: false};
      serv.comentarios = null;
      serv.importeSalida = null;
      serv.val = false;
    }
    window.scrollTo(0,1);
    this.modalService.open(this.modalLicit, { centered: true, size: 'xl' } );
    window.scrollTo(0,0);
    this.page = 1;
  }

  cambiarPagina(page) {
    if(this.page == 1){
      //COMPROBAR ERRORES
      var errors = false;
      //error = {referencia: false, origen: false, fechaInicio: false, fechaFin: false, fechaOrden: false, fechaHoy: false, servicios: false};

      if(!this.referenciaCliente || this.referenciaCliente == ""){
        this.error.referencia = true;
        errors = true;
      } else {
        this.error.referencia = false;
      }
      if(!this.tipo){
        this.error.tipo = true;
        errors = true;
      } else {
        this.error.tipo = false;
      }
      if(!this.paisOrigen || !this.provinciaOrigen || !this.poblacionOrigen){
        this.error.origen = true;
        errors = true;
      } else {
        this.error.origen = false;
      }
      if(this.traslado) {
        if(!this.paisDestino || !this.provinciaDestino || !this.poblacionDestino){
          this.error.destino = true;
          errors = true;
        } else {
          this.error.destino = false;
        }
      }
      if(!this.fechaInicio){
        this.error.fechaInicio = true;
        errors = true;
      } else {
        this.error.fechaInicio = false;
      }
      if(!this.fechaFin){
        this.error.fechaFin = true;
        errors = true;
      } else {
        this.error.fechaFin = false;
      }
      var servicioSel = false;
      for(var i = 0; i < this.servicios.length; i++){
        if(this.servicios[i].val) {
          servicioSel = true;
          break;
        }
      }
      if(!servicioSel && !this.traslado) {
        this.error.servicios = true;
        errors = true;
      } else {
        this.error.servicios = false;
      }
      if(moment(this.fechaFin).diff(this.fechaInicio) < 0){
        this.error.fechaOrden = true;
        errors = true;
      } else {
        this.error.fechaOrden = false;
      }

      if(errors) {
        this.cdr.detectChanges();
        return;
      }
    }
    this.page = page;
  }

  serviciosCompletados = 0;

  loading = false;

  submitLicit() {
    //COMPROBAR SERVICIOS
    //res[i].error = {importeSalida: false, pais: false, estado: false, poblacion: false, tipo: false, km: false}
    var errors = false;
    if(this.traslado){
      if(!this.tipoTraslado){
        this.error.tipoTraslado = true;
        errors = true;
      } else {
        this.error.tipoTraslado = false;
      }
      if(!this.km){
        this.error.km = true;
        errors = true;
      } else {
        this.error.km = false;
      }
      if(!this.importeTraslado){
        this.error.importeTraslado = true;
        errors = true;
      } else {
        this.error.importeTraslado = false;
      }
    }
    for(var i = 0; i < this.servicios.length; i++){
      if(this.servicios[i].val) {
        if(!this.servicios[i].importeSalida){
          this.servicios[i].error.importeSalida = true;
          errors = true;
        } else {
          this.servicios[i].error.importeSalida = false;
        }
      }
    }
    if(errors) {
      this.cdr.detectChanges();
      return;
    }

    this.loading = true;

    if(moment(this.fechaInicio).diff() < 0){
      this.fechaInicio = new Date();
    }

    if(this.tipo == "CON") {
      this.tipo = true;
    } else {
      this.tipo = false;
    }

    var continuarGuardado = function () {
      var total = 0;
      var servicios = [];
      for(var i = 0; i < this.servicios.length; i++){
        if(this.servicios[i].val) {
          total += this.servicios[i].importeSalida;
          delete this.servicios[i].val;
          delete this.servicios[i].error;
          servicios.push(this.servicios[i]);
        }
      }
      if(servicios.length == 0) {
        this.loading = false;
        this.modalService.dismissAll();
        this.confirmationAddSwal.fire();
        this.cdr.detectChanges();

        return;
      }
      var poblacion = "";
      var provincia = null;
      var pais = null;
      if(this.traslado) {
        poblacion = this.poblacionDestino;
        provincia = this.provinciaDestino;
        pais = this.paisDestino;
      } else {
        poblacion = this.poblacionOrigen;
        provincia = this.provinciaOrigen;
        pais = this.paisOrigen;
      }

      var licit = {
        referencia: this.currentUser.id + "" + moment().unix(),
        referenciaCliente: this.referenciaCliente || "",
        startDate: this.fechaInicio,
        endDate: this.fechaFin,
        abierta: true,
        pujas: this.tipo,
        importeSalida: total || 0,
        poblacionOrigen: poblacion,
        provinciaOrigen: provincia,
        paisOrigen: pais,
        comentarios: "",
        creador: this.currentUser.id,
        poblacionDestino: "",
        provinciaDestino: null,
        paisDestino: null,
        km: 0,
        tipoTraslado: "",
        servicios: servicios,
        tipo: "SERVICIO"
      }
      var completado = function (resData){
        //Añadir al listado activo
        resData.precio = resData.importeSalida;
        resData.estado = "ABIERTA";
        resData.fecha = moment(resData.startDate).format("DD/MM/YYYY");
        resData.suscripcion = true;
        this.originalRows = [...this.originalRows, resData];
        this.filterDatatable();
        this.cdr.detectChanges();

        for(var i = 0; i < this.servicios.length; i++){
          this.servicios[i].val = false;
          this.servicios[i].comentarios = null;
          this.servicios[i].importeSalida = null;
          this.servicios[i].error = {importeSalida: false};
        }

        var serviciosStr = "";
        for(var i = 0; i < resData.servicios.length; i++) {
          var servicio = resData.servicios[i];
          serviciosStr += servicio.nombre;
          if(i < resData.servicios.length-1) serviciosStr += ", ";
        }
        
        this.loading = false;
        this.modalService.dismissAll();
        this.confirmationAddSwal.fire();

        //Envio mail/telegram confirmación
        var mail = {
          usuario: this.currentUser.id, 
          referencia: resData.referencia, 
          importe: resData.importeSalida, 
          servicios: serviciosStr,
          origen: resData.poblacionOrigen + " (" + resData.provinciaOrigen.name + " - " + resData.paisOrigen.name + ")", 
          destino: null,
          fechaFin: moment(resData.endDate).format("DD/MM/YYYY HH:mm")
        }

        this.notificacionService.nueva_licitacion(mail)
        .pipe(first())
        .subscribe(
          data => {
            
          },
          error => {
            console.error(error);
        });
      }
      self.io.socket.post('/licitacion', licit, completado.bind(this));
    }
    
    //GUARDAR
    if(this.traslado) {
      var licit = {
        referencia: this.currentUser.id + ""+ moment().unix(),
        referenciaCliente: this.referenciaCliente || "",
        startDate: this.fechaInicio,
        endDate: this.fechaFin,
        abierta: true,
        pujas: this.tipo,
        importeSalida: this.importeTraslado || 0,
        poblacionOrigen: this.poblacionOrigen,
        provinciaOrigen: this.provinciaOrigen,
        paisOrigen: this.paisOrigen,
        comentarios: this.comentariosTraslado || "",
        creador: this.currentUser.id,
        poblacionDestino: this.poblacionDestino,
        provinciaDestino: this.provinciaDestino,
        paisDestino: this.paisDestino,
        km: this.km,
        tipoTraslado: this.tipoTraslado,
        tipo: "TRASLADO"
      }
      var completado = function (resData){
        //Añadir al listado activo
        resData.precio = resData.importeSalida;
        resData.estado = "ABIERTA";
        resData.fecha = moment(resData.startDate).format("DD/MM/YYYY");
        resData.fechaFin = moment(resData.endDate).format("DD/MM/YYYY HH:mm");
        resData.suscripcion = true;
        this.originalRows = [...this.originalRows, resData];
        this.filterDatatable();
        this.cdr.detectChanges();
        var fn = continuarGuardado.bind(this);
        fn();

        //Envio mail/telegram confirmación
        var mail = {
          usuario: this.currentUser.id, 
          referencia: resData.referencia, 
          importe: resData.importeSalida, 
          servicios: "TRASLADO - " + resData.km + " km",
          origen: resData.poblacionOrigen + " (" + resData.provinciaOrigen.name + " - " + resData.paisOrigen.name + ")", 
          destino: resData.poblacionDestino + " (" + resData.provinciaDestino.name + " - " + resData.paisDestino.name + ")",
          fechaFin: resData.fechaFin
        }

        this.notificacionService.nueva_licitacion(mail)
        .pipe(first())
        .subscribe(
          data => {
            
          },
          error => {
            console.error(error);
        });
      }
      self.io.socket.post('/licitacion', licit, completado.bind(this));

      
    } else {
      var fn = continuarGuardado.bind(this);
      fn();
    }    
  }

  // ----- LICITAR ------

  my_messages = {
    'emptyMessage': 'Licitación Desierta',
    'totalMessage': 'total'
  };

  msg_trazas = {
    'emptyMessage': 'No hay trazas',
    'totalMessage': 'total'
  };

  row: any;
  currentRow: any;
  rowsLicit = [];
  trazas = null;

  onSelect(event: any) {
    if(event.type == "click"){
      if(this.currentUser.status == "ACTIVO"){
        event.cellElement.blur();
        if(!event.row.suscripcion) {
          this.suscriptionSwal.fire();
          return;
        }
        this.row = event.row;
        if(this.row.trazas) {
          var trazas = JSON.parse(this.row.trazas);
          this.trazas = [];
          for(let traza in trazas){
            trazas[traza].codigo = traza;
            this.trazas.push(trazas[traza]);
          }
        } else {
          this.trazas = [];
        }
        this.currentRow = this.row;
        this.vista = 'detalle';
        this.cdr.detectChanges();
        KTUtil.scrollTop();
        this.loadLicita();
        this.importeLicitar = null;
        if(this.importeField) this.importeField.nativeElement.focus();
      } else if(this.currentUser.status == "INVITADO"){
        this.registerSwal.fire();
      } else if(this.currentUser.status == "PENDIENTE"){
        this.registerValidarSwal.fire();
      }
      
    }

    this.licitacionGanadora = null;
    this.licitacionesPasadas = 0;
    this.importeLicitar = null;
    this.ultima = false;
  }

  selectLicit(row: any) {
    var abierto = false;
    if(this.row) abierto = true;
    if(!row.suscripcion) {
      this.suscriptionSwal.fire();
      return;
    }    
    if(this.currentUser.status == "INVITADO") {
      this.registerSwal.fire();
      return;
    }
    this.row = row;
    if(this.row.trazas) {
      var trazas = JSON.parse(this.row.trazas);
      this.trazas = [];
      for(let traza in trazas){
        trazas[traza].codigo = traza;
        this.trazas.push(trazas[traza]);
      }     
    } else {
      this.trazas = [];
    }
    this.currentRow = this.row;
    //this.vista = 'detalle';
    this.cdr.detectChanges();
    //KTUtil.scrollTop();
    this.loadLicita();
    this.importeLicitar = null;
    if(this.importeField) this.importeField.nativeElement.focus();

    this.licitacionGanadora = null;
    this.licitacionesPasadas = 0;
    this.importeLicitar = null;
    this.ultima = false;
    this.cdr.detectChanges();

    if(!abierto) {
      window.scroll({
        top: window.scrollY + 300,
        behavior: 'smooth'
      });
    }
    
  }

  scrollUp() {
    window.scroll({
      top: window.scrollY - 300,
      behavior: 'smooth'
    });
  }

  importeLicitar = null;

  confirmarLicitacionRapida(cantidad: any){
    var importe = this.row.precio - cantidad;

    if(importe < 0) {
      this.errorNegativeSwal.fire();
      return;
    }

    this.importeLicitar = importe;

    if(this.currentUser.id == this.row.creador.id){
      this.confirmaSwal.text = "¿Confirmas el cambio de precio de la licitación por " + this.importeLicitar + " € ?";
    } else {
      this.confirmaSwal.text = "¿Confirmas la licitación por " + this.importeLicitar + " € ?";
    }

    this.confirmaSwal.fire();
  }

  confirmarLicitacion() {
    if(this.importeLicitar == null) return;
    if(this.importeLicitar >= this.row.precio && this.currentUser.id != this.row.creador.id){
      this.errorSwal.fire();
      return;
    }

    if(this.importeLicitar < 0) {
      this.errorNegativeSwal.fire();
      return;
    }

    if(this.currentUser.id == this.row.creador.id){
      this.confirmaSwal.text = "¿Confirmas el cambio de precio de la licitación por " + this.importeLicitar + " € ?";
    } else {
      this.confirmaSwal.text = "¿Confirmas la licitación por " + this.importeLicitar + " € ?";
    }

    this.confirmaSwal.fire();
  }

  confirmarLicitacionSinPuja() {
    if(this.currentUser.status == "INVITADO") {
      this.registerSwal.fire();
      return;
    }
    if(!this.row.suscripcion) {
      this.suscriptionSwal.fire();
      return;
    }
    this.confirmaSwal.text = "¿Confirmas la inscripción a la licitación? Recibirás una notificación si eres el elegido al finalizar.";

    this.confirmaSwal.fire();
  }

  loadingPuja = false;

  licitar() {
    if(this.row.pujas && this.importeLicitar >= this.row.precio && this.currentUser.id != this.row.creador.id){
      this.error2Swal.fire();
      return;
    }
    this.loadingPuja = true;

    /* SIN PUJA */
    if(!this.row.pujas) {
      this.importeLicitar = this.row.precio;

      var lic = {
        user: this.currentUser.id,
        importe: this.importeLicitar,
        licitacion: this.row.id,
        fecha: new Date(),
        nula: false
      }
      var completado = function(resData){
        var edit = {
          ultimaLicitacion: resData.id
        }
        var completadoLicit = function(resLicit){
          this.confirmationSwal.fire();
          this.importeLicitar = null;
          this.loadingPuja = false;
          this.row.precio = resLicit.importe;
          resData.user = resData.user.id;
          this.row.ultimaLicitacion = resData;
          this.participaciones[this.row.id+""] = true;
          if(!this.participacionesImporte[this.row.id+""] || this.participacionesImporte[this.row.id+""] > resData.importe) this.participacionesImporte[this.row.id+""] = resData.importe;
          //if(this.router.url === '/mislicitaciones') {
            this.row = null; this.currentRow = null;
          //}
          //else this.ngZone.run(() => this.router.navigate(['/mislicitaciones'])).then();//this.router.navigate(['/mislicitaciones']);
          this.cdr.detectChanges();
          for(var i = 0; i < this.originalRows.length; i++){
            if(this.originalRows[i].id == resLicit.id){
              this.originalRows[i].precio = resData.importe;
              this.originalRows[i].ultimaLicitacion = null;
              this.originalRows[i].ultimaLicitacion = resData;
              this.cdr.detectChanges();
              this.filterDatatable();
              break;
            }
          }
        }
        self.io.socket.patch('/licitacion/' + this.row.id, edit, completadoLicit.bind(this));
      }
      self.io.socket.post('/licita', lic, completado.bind(this));
    } else {/* CON PUJA */
      var licit = {
        user: this.currentUser.id,
        importe: this.importeLicitar,
        licitacion: this.row.id,
      }
      var completado = function(resData){
        console.warn(resData);
        if(resData.error && resData.error.message == "Importe incorrecto") {
          this.error2Swal.fire();
          this.loadingPuja = false;
          this.cdr.detectChanges();
        } else {
          this.confirmationSwal.fire();
          this.importeLicitar = null;
          this.loadingPuja = false;
          //this.row.precio = resData.importe;
          //this.row.ultimaLicitacion = resData.ultimaLicitacion;
          this.participaciones[this.row.id+""] = true;
          if(!this.participacionesImporte[this.row.id+""] || this.participacionesImporte[this.row.id+""] > resData.importe) this.participacionesImporte[this.row.id+""] = resData.importe;
          //if(this.router.url === '/mislicitaciones') {
            this.scrollUp(); this.row = null; this.currentRow = null;
          //}
          //else this.ngZone.run(() => this.router.navigate(['/mislicitaciones'])).then();//this.router.navigate(['/mislicitaciones']);
          this.cdr.detectChanges();
          /*for(var i = 0; i < this.originalRows.length; i++){
            if(this.originalRows[i].id == resData.licitacion){
              this.originalRows[i].precio = resData.importe;
              this.originalRows[i].ultimaLicitacion = null;
              this.originalRows[i].ultimaLicitacion = resData.ultimaLicitacion;
              this.cdr.detectChanges();
              this.filterDatatable();
              break;
            }
          }*/
        }
      }
      self.io.socket.post('/postLicitacion', {cola: licit}, completado.bind(this));
    }
    

  }

  licitacionGanadora = null;
  licitacionesPasadas = 0;
  rowsLicitCompleta = [];

  loadLicita() {
    this.rowsLicit = [];
    this.rowsLicitCompleta = [];
    this.licitaService.getAllByLicitacion(this.row.id)
    .subscribe(licitas => {
      this.rowsLicitCompleta = licitas;
      var hashUsers = {}
      if(!this.row.abierta) {
        for(let licita of licitas) {
          if(!hashUsers[licita.user.id+""]) {
            this.rowsLicit.push(licita);
            hashUsers[licita.user.id+""] = true;
          }
        }
      } else {
        this.rowsLicit = licitas;
      }
      
      var ms1 = moment(this.row.endDate).diff();
      if(ms1 < 0 && this.rowsLicit.length > 0){
        this.licitacionGanadora = this.rowsLicit[0];
        //if(this.rowsLicit.length == 1) this.licitacionesPasadas = 99;
      }

      for(var i = 0; i < this.rowsLicit.length; i++) {
        this.rowsLicit[i].fecha = moment(this.rowsLicit[i].fecha).format("DD/MM/YYYY");
        var ms = moment(this.rowsLicit[i].licitacion.endDate).diff();

        if(ms <= 0 && this.row.validada && this.row.licitacionElegida && this.row.licitacionElegida.id == this.rowsLicit[i].id && this.currentUser.id == this.row.creador.id){
          this.rowsLicit[i].empresa = this.rowsLicit[i].user.empresa;
          this.rowsLicit[i].elegida = true;
        } else {
          this.rowsLicit[i].empresa = this.rowsLicit[i].user.empresa.substring(0, 3) + "*****";
        }

        /*if(this.row.pujas) {
          if(i != 0 || (i == 0 && ms > 0) || (i == 0 && ms < 0 && this.currentUser.id != this.row.creador.id)){
            this.rowsLicit[i].empresa = this.rowsLicit[i].user.empresa.substring(0, 3) + "*****";
          } else if(i == 0 && ms < 0) {  //Si ha finalizado la licitación
            this.rowsLicit[i].empresa = this.rowsLicit[i].user.empresa;
            this.rowsLicit[i].fin = true;
          }
        } else {
          if(ms <= 0 && this.row.validada && this.row.licitacionElegida && this.row.licitacionElegida.id == this.rowsLicit[i].id){
            this.rowsLicit[i].empresa = this.rowsLicit[i].user.empresa;
            this.rowsLicit[i].elegida = true;
          }
        }*/
      
        
      }
			this.cdr.detectChanges();
		});
  }

  licitacionConfirmar = null;

  seleccionarLicitacion(licit: any) {
    this.confirmaElegidoSwal.text = "¿Confirmas a " + licit.user.empresa + " como adjudicatario de la licitación?";
    this.licitacionConfirmar = licit;
    this.confirmaElegidoSwal.fire();
  }

  seleccionarLicitacion1() {
    var trazas = {};

    for(let traza of this.trazabilidad) {
      if(this.row.tipo == "TRASLADO") {
        if(traza["TRASLADO"] == true){
          trazas[traza.codigo] = {
            completado: false,
            fecha: null,
            texto: "",
            fechaTxt: null
          }
        }
      } else {
        for(let serv of this.row.servicios) {
          if(serv.abreviatura == "SVFUN") {
            if(traza[serv.tipo] == true || traza["TANAT"] == true || traza["FLOR"] == true || traza["COCHE"] == true || traza["GEST"] == true){
              trazas[traza.codigo] = {
                completado: false,
                fecha: null,
                texto: "",
                fechaTxt: null
              }
              break;
            }
          } if(serv.abreviatura == "OTROS") {
            if(traza[serv.abreviatura] == true){
              trazas[traza.codigo] = {
                completado: false,
                fecha: null,
                texto: "",
                fechaTxt: null
              }
              break;
            } else {
              var enc = false;
              for(let otro of serv.detalleOtros) {
                if(otro.valor && otro.codigo == traza.codigo) {
                  trazas[traza.codigo] = {
                    completado: false,
                    fecha: null,
                    texto: "",
                    fechaTxt: null
                  }
                  break;
                }
              }
              if(enc) break;
            }
          } else {
            if(traza[serv.abreviatura] == true){
              trazas[traza.codigo] = {
                completado: false,
                fecha: null,
                texto: "",
                fechaTxt: null
              }
              break;
            }
          }
          
        }
      }
    }

    var edit = {
      licitacionElegida: this.licitacionConfirmar.id,
      validada: true,
      usuarioElegido: this.licitacionConfirmar.user.id,
      trazas: JSON.stringify(trazas)
    }
    var completadoLicit = function(resLicit){
      this.confirmaElegidoSuccessSwal.fire();
      
      this.row.validada = true;
      this.row.licitacionElegida = resLicit.licitacionElegida;
      this.row.usuarioElegido = resLicit.usuarioElegido;
      this.row.trazas = resLicit.trazas;
      var trazas = JSON.parse(resLicit.trazas);
      this.trazas = [];
      for(let traza in trazas){
        trazas[traza].codigo = traza;
        this.trazas.push(trazas[traza]);
      }       

      this.cdr.detectChanges();
      for(var i = 0; i < this.originalRows.length; i++){
        if(this.originalRows[i].id == resLicit.id){
          this.originalRows[i].licitacionElegida = resLicit.licitacionElegida;
          this.originalRows[i].validada = resLicit.validada;
          this.originalRows[i].usuarioElegido = resLicit.usuarioElegido;
          this.originalRows[i].trazas = resLicit.trazas;
          this.cdr.detectChanges();
          this.filterDatatable();
          break;
        }
      }

      if(this.row.trazas) { //Esto es que ya está validado
        var trazas = JSON.parse(this.row.trazas);
        this.row.trazabilidad = [];
        this.cdr.detectChanges();
        var comp = 0;
        for(let traza in trazas){
          if(trazas[traza].completado) {
            comp++;
          }
          trazas[traza].codigo = traza;
          this.row.trazabilidad.push(trazas[traza]);
        } 
        this.row.trazas_comp = comp;
      }

      this.scrollUp(); this.row = null; this.currentRow = null;
      this.cdr.detectChanges();

      //Envio mail/telegram confirmación
      this.notificacionService.licitacion_validada({licitacion: resLicit})
      .pipe(first())
      .subscribe(
        data => {
          
        },
        error => {
          console.error(error);
      });
    }
    self.io.socket.patch('/licitacion/' + this.licitacionConfirmar.licitacion.id, edit, completadoLicit.bind(this));
  }

  ultima = false;
  listanegra = null;
  fija = false;

  saltarLicitacion(listanegra: any) {
    this.fija = false;
    if(listanegra) {
      this.listanegra = this.licitacionGanadora.user;
      if(this.licitacionesPasadas + (this.row.pasadas || 0) > 2 || this.rowsLicit.length <= this.licitacionesPasadas+1) {
        this.confirmaSaltarSwal.text = "¿Confirmas excluir a " + this.licitacionGanadora.user.empresa + " como adjudicatario de la licitación y rechazarlo en futuras licitaciones? No hay más exlusiones, la licitación se quedará desierta. Este participante no volverá a ver tus licitaciones";
        this.ultima = true;
      } else {
        this.confirmaSaltarSwal.text = "¿Confirmas excluir a " + this.licitacionGanadora.user.empresa + " como adjudicatario de la licitación y rechazarlo en futuras licitaciones? Este participante no volverá a ver tus licitaciones. Exclusión (" + (this.licitacionesPasadas+ (this.row.pasadas || 1)) + "/2)";
      }
    } else {
      this.listanegra = null;
      if(this.licitacionesPasadas + (this.row.pasadas || 0) > 2 || this.rowsLicit.length <= this.licitacionesPasadas+1) {
        this.confirmaSaltarSwal.text = "¿Confirmas excluir a " + this.licitacionGanadora.user.empresa + " como adjudicatario de la licitación? No hay más exlusiones, la licitación se quedará desierta.";
        this.ultima = true;
      } else {
        this.confirmaSaltarSwal.text = "¿Confirmas excluir a " + this.licitacionGanadora.user.empresa + " como adjudicatario de la licitación? No podrás volver a este usuario. Exclusión (" + (this.licitacionesPasadas+ (this.row.pasadas || 1)) + "/2)";
      }
    }

    this.confirmaSaltarSwal.fire();
  }

  licitFijaSaltar = null;

  saltarLicitacionFija(licit) {
    this.fija = true;
    this.licitFijaSaltar = licit;
    this.listanegra = licit.user;
    if(this.rowsLicit.length <= 1) {
      this.confirmaSaltarSwal.text = "¿Confirmas excluir a " + licit.user.empresa + " como adjudicatario de la licitación y rechazarlo en futuras licitaciones? No hay más participantes, la licitación se quedará desierta. Este participante no volverá a ver tus licitaciones";
      this.ultima = true;
    } else {
      this.confirmaSaltarSwal.text = "¿Confirmas excluir a " + licit.user.empresa + " como adjudicatario de la licitación y rechazarlo en futuras licitaciones? Este participante no volverá a ver tus licitaciones.";
    }

    this.confirmaSaltarSwal.fire();
  }

  saltarLicitacionTodas() {
    this.listanegra = null;
    this.ultima = true;
    this.confirmaSaltarSwal.text = "¿Confirmas excluir a todos los participantes? La licitación se quedará desierta.";
    
    this.confirmaSaltarSwal.fire();
  }

  saltarLicitacion1() {
    if(!this.fija) {
      if(this.ultima == true) {
        var edit = {
          licitacionElegida: null,
          validada: true,
          usuarioElegido: null,
        }
        var completadoLicit = function(resLicit){
          this.row.validada = true;
          this.row.licitacionElegida = null;
          this.row.usuarioElegido = null;
          
          this.cdr.detectChanges();
          for(var i = 0; i < this.originalRows.length; i++){
            if(this.originalRows[i].id == resLicit.id){
              this.originalRows[i].validada = resLicit.validada;
              this.cdr.detectChanges();
              this.filterDatatable();
              break;
            }
          }
          this.confirmaSaltarSuccessSwal.title = "Participante excluido correctamente.";
          this.confirmaSaltarSuccessSwal.text = "Se ha excluido al participante. La licitación ha quedado desierta.";
          this.confirmaSaltarSuccessSwal.fire();
          this.scrollUp(); this.row = null; this.currentRow = null;
          this.cdr.detectChanges();
        }
        self.io.socket.patch('/licitacion/' + this.row.id, edit, completadoLicit.bind(this));

      } else {
        var licitacionActual = this.licitacionGanadora;
        this.licitacionesPasadas++;

        this.licitacionGanadora = this.rowsLicit[this.licitacionesPasadas];

        for(let licita of this.rowsLicitCompleta) {
          if(licita.user.id == licitacionActual.user.id) {
            var editLicita = {id: licita.id, nula: true};
            this.licitaService.update(editLicita)
              .pipe(first())
              .subscribe(
                data => {
                },
                error => {
                  console.error(error);
                });
          }
        }

        var completadoLicit = function(resLicit){

        }

        if(this.rowsLicit.length < this.licitacionesPasadas+1 + (this.row.pasadas || 0)) {
          this.licitacionesPasadas = 99;
        }

        this.row.ultimaLicitacion = this.licitacionGanadora;
        this.row.precio = this.licitacionGanadora.importe;
        var editContador = {
          pasadas: this.licitacionesPasadas + (this.row.pasadas || 0),
          ultimaLicitacion: this.licitacionGanadora.id
        }
        this.row.pasadas = this.licitacionesPasadas + (this.row.pasadas || 0);
        self.io.socket.patch('/licitacion/' + this.row.id, editContador, completadoLicit.bind(this));

        this.confirmaSaltarSuccessSwal.title = "Participante excluido correctamente.";
        this.confirmaSaltarSuccessSwal.text = "Se ha excluido al participante de la licitación.";
        this.confirmaSaltarSuccessSwal.fire();
        this.cdr.detectChanges();
      }
    } else {
      if(this.ultima == true) {
        var edit = {
          licitacionElegida: null,
          validada: true,
          usuarioElegido: null,
        }
        var completadoLicit = function(resLicit){
          this.row.validada = true;
          this.row.licitacionElegida = null;
          this.row.usuarioElegido = null;
          
          this.cdr.detectChanges();
          for(var i = 0; i < this.originalRows.length; i++){
            if(this.originalRows[i].id == resLicit.id){
              this.originalRows[i].validada = resLicit.validada;
              this.cdr.detectChanges();
              this.filterDatatable();
              break;
            }
          }
          this.confirmaSaltarSuccessSwal.title = "Participante bloqueado correctamente.";
          this.confirmaSaltarSuccessSwal.text = "Se ha excluído al participante y no volverá a ver tus licitaciones. La licitación ha quedado desierta.";
          this.confirmaSaltarSuccessSwal.fire();
          this.scrollUp(); this.row = null; this.currentRow = null;
          this.cdr.detectChanges();
        }
        self.io.socket.patch('/licitacion/' + this.row.id, edit, completadoLicit.bind(this));

      } else {
        var licitacionActual = this.licitFijaSaltar;

        for(let licita of this.rowsLicitCompleta) {
          if(licita.user.id == licitacionActual.user.id) {
            var editLicita = {id: licita.id, nula: true};
            this.licitaService.update(editLicita)
              .pipe(first())
              .subscribe(
                data => {
                  this.loadLicita();
                },
                error => {
                  console.error(error);
                });
          }
        }

        this.confirmaSaltarSuccessSwal.title = "Participante bloqueado correctamente.";
        this.confirmaSaltarSuccessSwal.text = "Se ha excluído al participante y no volverá a ver tus licitaciones.";
        this.confirmaSaltarSuccessSwal.fire();
        this.cdr.detectChanges();
      }
    }    

    //Lo añadimos a la lista negra del usuario - TODO, comprobar que no esté ya
    if(this.listanegra != null) {
      var bloqueo = {
        user: this.currentUser.id,
        bloqueado: this.listanegra.id,
        fecha: new Date()
      }
      this.userService.postBloqueo(bloqueo)
      .pipe(first())
      .subscribe(
        data => {
          
        },
        error => {
            console.error(error);
            this.loading = false;
        });
    }
    
  }

  

  traza = null;

  confirmarTraza(traza: any){
    this.traza = traza;
    this.confirmarTraza1();
    //this.confirmaTrazaSwal.fire();
  }

  cancelarTraza(traza: any){
    this.traza = traza;
    this.cancelarTraza1();
    //this.cancelaTrazaSwal.fire();
  }

  /*@ViewChild('confirmaTrazaSwal', {static: false}) private confirmaTrazaSwal: SwalComponent
  @ViewChild('confirmaTrazaSuccessSwal', {static: false}) private confirmaTrazaSuccessSwal: SwalComponent
  @ViewChild('cancelaTrazaSwal', {static: false}) private cancelaTrazaSwal: SwalComponent
  @ViewChild('cancelaTrazaSuccessSwal', {static: false}) private cancelaTrazaSuccessSwal: SwalComponent*/

  confirmarTraza1(){
    var trazas = JSON.parse(this.row.trazas);

    trazas[this.traza.codigo].completado = true;
    trazas[this.traza.codigo].fecha = moment().format("DD/MM/YYYY HH:mm");    
    trazas[this.traza.codigo].texto = this.traza.texto;
    trazas[this.traza.codigo].incidencia = this.traza.incidencia;

    var edit = {
      trazas: JSON.stringify(trazas)
    }
    var completadoLicit = function(resLicit){
      //this.confirmaTrazaSuccessSwal.fire();
      
      this.row.trazas = resLicit.trazas;
      var trazas = JSON.parse(resLicit.trazas);
      this.trazas = [];
      this.cdr.detectChanges();
      for(let traza in trazas){
        trazas[traza].codigo = traza;
        this.trazas.push(trazas[traza]);
      } 

      var temp = [...this.trazas];
      this.trazas = [];
      this.cdr.detectChanges();
      this.trazas = temp;
      
      this.cdr.detectChanges();
      for(var i = 0; i < this.originalRows.length; i++){
        if(this.originalRows[i].id == resLicit.id){
          this.originalRows[i].trazas = resLicit.trazas;
          if(this.originalRows[i].trazas) {
            var trazas = JSON.parse(this.originalRows[i].trazas);
            this.originalRows[i].trazabilidad = [];
            this.cdr.detectChanges();
            var comp = 0;
            for(let traza in trazas){
              if(trazas[traza].completado) {
                comp++;
              }
              trazas[traza].codigo = traza;
              this.originalRows[i].trazabilidad.push(trazas[traza]);
            } 
            this.originalRows[i].trazas_comp = comp;
          }

          this.cdr.detectChanges();
          this.filterDatatable();
          break;
        }
      }
      
    }
    self.io.socket.patch('/licitacion/' + this.row.id, edit, completadoLicit.bind(this));
  }

  cancelarTraza1(){
    var trazas = JSON.parse(this.row.trazas);

    trazas[this.traza.codigo].completado = false;
    trazas[this.traza.codigo].fecha = null;
    trazas[this.traza.codigo].texto = null;

    var edit = {
      trazas: JSON.stringify(trazas)
    }  
    var completadoLicit = function(resLicit){
      //this.cancelaTrazaSuccessSwal.fire();
      
      this.row.trazas = resLicit.trazas;
      var trazas = JSON.parse(resLicit.trazas);
      this.trazas = [];
      this.cdr.detectChanges();
      for(let traza in trazas){
        trazas[traza].codigo = traza;
        this.trazas.push(trazas[traza]);
      }
      var temp = [...this.trazas];
      this.trazas = [];
      this.cdr.detectChanges();
      this.trazas = temp;
      this.cdr.detectChanges();
      
      for(var i = 0; i < this.originalRows.length; i++){
        if(this.originalRows[i].id == resLicit.id){
          this.originalRows[i].trazas = resLicit.trazas;
          if(this.originalRows[i].trazas) {
            var trazas = JSON.parse(this.originalRows[i].trazas);
            this.originalRows[i].trazabilidad = [];
            this.cdr.detectChanges();
            var comp = 0;
            for(let traza in trazas){
              if(trazas[traza].completado) {
                comp++;
              }
              trazas[traza].codigo = traza;
              this.originalRows[i].trazabilidad.push(trazas[traza]);
            } 
            this.originalRows[i].trazas_comp = comp;
          }

          this.cdr.detectChanges();
          this.filterDatatable();
          break;
        }
      }
    }
    self.io.socket.patch('/licitacion/' + this.row.id, edit, completadoLicit.bind(this));
  }

  @ViewChild('borrarSwal', {static: false}) private borrarSwal: SwalComponent
  @ViewChild('borrarConfirmSwal', {static: false}) private borrarConfirmSwal: SwalComponent

  borrarLoading = false;

  borrar() {
    if(this.borrarLoading) return;
    this.borrarSwal.fire();
  }

  borrar1() {
    this.borrarLoading = true;
    this.cdr.detectChanges();
    var edit = {
      borrada: true,
      abierta: false
    }
    var completadoLicit = function(resLicit){
      //Mandamos mail a todos los participantes:

      var destino = null;
      if(this.row.tipo == "TRASLADO") destino = this.row.poblacionDestino + " (" + this.row.provinciaDestino.name + " - " + this.row.paisDestino.name + ")";
      var servicios = "";
      if(this.row.tipo == "TRASLADO") servicios = "TRASLADO"
      else {
        for(var i = 0; i < this.row.servicios.length; i++) {
          servicios += this.row.servicios[i].nombre; ""
          if(i < this.row.servicios.length-1) servicios += ", ";
        }
      }

      this.rowsLicit = [];
      this.licitaService.getAllByLicitacion(this.row.id)
      .subscribe(licitas => {
        this.rowsLicit = licitas;

        var emailsHash = {};
        for(let licit of this.rowsLicit) {
          if(licit.user != this.currentUser.id && !emailsHash[licit.user.email]) {
            //Enviar mail

            this.http.post(`${environment.apiUrl}/mailcancelar`, {
              destinatario: licit.user.email, 
              referencia: this.row.referencia, 
              importe: this.row.importeSalida, 
              servicios: servicios,
              origen: this.row.poblacionOrigen + " (" + this.row.provinciaOrigen.name + " - " + this.row.paisOrigen.name + ")", 
              destino: destino
            }).pipe(first())
              .subscribe(
                data => {
                  
                },
                error => {
                  console.error(error);
                  this.loading = false;
                });
            emailsHash[licit.user.email] = true;
          }
        }

      });

      this.borrarConfirmSwal.fire();
      this.borrarLoading = false;
      this.participaciones[this.row.id+""] = false;
      this.participacionesImporte[this.row.id+""] = null;
      //if(this.router.url === '/mislicitaciones') this.vista = 'listado'
      //else this.router.navigate(['/mislicitaciones']);
      this.cdr.detectChanges();
      for(var i = 0; i < this.originalRows.length; i++){
        if(this.originalRows[i].id == resLicit.id){
          this.originalRows[i].borrada = true;
          this.cdr.detectChanges();
          this.filterDatatable();
          break;
        }
      }
    }
    self.io.socket.patch('/licitacion/' + this.row.id, edit, completadoLicit.bind(this));
  }

  editar(row) {
    //this.router.navigate(['/editar/' + row.id]);
    //this.cdr.detectChanges();
    this.ngZone.run(() => this.router.navigate(['/editar/' + row.id])).then();
    return;

    this.referenciaCliente = this.row.referenciaCliente;
    if(this.row.pujas) this.tipo = "CON"
    else this.tipo = "SIN";

    this.fechaInicio = this.row.startDate;
    this.fechaFin = this.row.endDate;

    if(this.row.tipo == "TRASLADO") {
      this.traslado = true;
      this.tipoTraslado = this.row.tipoTraslado;
      this.km = this.row.km;
      this.importeTraslado = this.row.importeSalida;
      this.comentariosTraslado = this.row.comentarios;

      this.paisDestino = this.row.paisDestino.id;
      this.countryChangedDestinoNew();
      this.provinciaDestino = this.row.provinciaDestino.id;
      this.poblacionDestino = this.row.poblacionDestino;
    } else {
      this.traslado = false;
      this.tipoTraslado = null;
      this.km = null;
      this.importeTraslado = null;
      this.comentariosTraslado = null;
      this.provinciaDestino = null;
      this.poblacionDestino = null;
      
      for(let serv of this.servicios) {
        serv.error = {importeSalida: false};
        serv.comentarios = null;
        serv.importeSalida = null;
        serv.val = false;
        for(let servLic of this.row.servicios) {
          if(serv.id == servLic.id) {
            serv.comentarios = servLic.comentarios;
            serv.importeSalida = servLic.importeSalida;
            serv.val = true;
            break;
          }
        }
      }
    }

    this.error = {referencia: false, tipo: false, origen: false, destino: false, tipoTraslado: false, km: false, importeTraslado: false, comentariosTraslado: false, fechaInicio: false, fechaFin: false, fechaOrden: false, fechaHoy: false, servicios: false};

    this.paisOrigen = this.row.paisOrigen.id;
    this.countryChangedOrigenNew();
    this.provinciaOrigen = this.row.provinciaOrigen.id;
    this.poblacionOrigen = this.row.poblacionOrigen;

    window.scrollTo(0,1);
    this.modalService.open(this.modalEditar, { centered: true, size: 'xl' } );
    window.scrollTo(0,0);
    this.page = 1;

    this.cdr.detectChanges();
  }

  cambiarPaginaEdit(page) {
    if(this.page == 1){
      //COMPROBAR ERRORES
      var errors = false;
      //error = {referencia: false, origen: false, fechaInicio: false, fechaFin: false, fechaOrden: false, fechaHoy: false, servicios: false};

      if(!this.referenciaCliente || this.referenciaCliente == ""){
        this.error.referencia = true;
        errors = true;
      } else {
        this.error.referencia = false;
      }
      if(!this.tipo){
        this.error.tipo = true;
        errors = true;
      } else {
        this.error.tipo = false;
      }
      if(!this.paisOrigen || !this.provinciaOrigen || !this.poblacionOrigen){
        this.error.origen = true;
        errors = true;
      } else {
        this.error.origen = false;
      }
      if(this.traslado) {
        if(!this.paisDestino || !this.provinciaDestino || !this.poblacionDestino){
          this.error.destino = true;
          errors = true;
        } else {
          this.error.destino = false;
        }
      }
      if(!this.fechaInicio){
        this.error.fechaInicio = true;
        errors = true;
      } else {
        this.error.fechaInicio = false;
      }
      if(!this.fechaFin){
        this.error.fechaFin = true;
        errors = true;
      } else {
        this.error.fechaFin = false;
      }
      var servicioSel = false;
      for(var i = 0; i < this.servicios.length; i++){
        if(this.servicios[i].val) {
          servicioSel = true;
          break;
        }
      }
      if(!servicioSel && !this.traslado) {
        this.error.servicios = true;
        errors = true;
      } else {
        this.error.servicios = false;
      }
      if(moment(this.fechaFin).diff(this.fechaInicio) < 0){
        this.error.fechaOrden = true;
        errors = true;
      } else {
        this.error.fechaOrden = false;
      }

      if(errors) {
        this.cdr.detectChanges();
        return;
      }
      if(this.row.tipo != "TRASLADO")
        for(let serv of this.servicios) {
          serv.error = {importeSalida: false};
          for(let servLic of this.row.servicios) {
            if(serv.id == servLic.id && serv.importeSalida == null) {
              serv.comentarios = servLic.comentarios;
              serv.importeSalida = servLic.importeSalida;
              serv.val = true;
              break;
            }
          }
        }
    }
    this.page = page;
  }

  submitLicitEdit() {
    //COMPROBAR SERVICIOS
    //res[i].error = {importeSalida: false, pais: false, estado: false, poblacion: false, tipo: false, km: false}
    var errors = false;
    if(this.traslado){
      if(!this.tipoTraslado){
        this.error.tipoTraslado = true;
        errors = true;
      } else {
        this.error.tipoTraslado = false;
      }
      if(!this.km){
        this.error.km = true;
        errors = true;
      } else {
        this.error.km = false;
      }
      if(!this.importeTraslado){
        this.error.importeTraslado = true;
        errors = true;
      } else {
        this.error.importeTraslado = false;
      }
    }
    for(var i = 0; i < this.servicios.length; i++){
      if(this.servicios[i].val) {
        if(!this.servicios[i].importeSalida){
          this.servicios[i].error.importeSalida = true;
          errors = true;
        } else {
          this.servicios[i].error.importeSalida = false;
        }
      }
    }
    if(errors) {
      this.cdr.detectChanges();
      return;
    }

    this.loading = true;

    if(moment(this.fechaInicio).diff() < 0){
      this.fechaInicio = new Date();
    }

    if(this.tipo == "CON") {
      this.tipo = true;
    } else {
      this.tipo = false;
    }

    var continuarGuardado = function () {
      var total = 0;
      var servicios = [];
      for(var i = 0; i < this.servicios.length; i++){
        if(this.servicios[i].val) {
          total += this.servicios[i].importeSalida;
          delete this.servicios[i].val;
          delete this.servicios[i].error;
          servicios.push(this.servicios[i]);
        }
      }
      if(servicios.length == 0) {
        this.loading = false;
        this.modalService.dismissAll();
        this.confirmationEditSwal.fire();
        this.cdr.detectChanges();

        return;
      }
      var poblacion = "";
      var provincia = null;
      var pais = null;
      if(this.traslado) {
        poblacion = this.poblacionDestino;
        provincia = this.provinciaDestino;
        pais = this.paisDestino;
      } else {
        poblacion = this.poblacionOrigen;
        provincia = this.provinciaOrigen;
        pais = this.paisOrigen;
      }

      var licit = {
        referencia: this.currentUser.id + "" + moment().unix(),
        referenciaCliente: this.referenciaCliente || "",
        startDate: this.fechaInicio,
        endDate: this.fechaFin,
        abierta: true,
        pujas: this.tipo,
        importeSalida: total || 0,
        poblacionOrigen: poblacion,
        provinciaOrigen: provincia,
        paisOrigen: pais,
        comentarios: "",
        creador: this.currentUser.id,
        poblacionDestino: "",
        provinciaDestino: null,
        paisDestino: null,
        km: 0,
        tipoTraslado: "",
        servicios: servicios,
        tipo: "SERVICIO"
      }
      var completado = function (resData){
        //Añadir al listado activo
        resData.precio = resData.importeSalida;
        resData.estado = "ABIERTA";
        resData.fecha = moment(resData.startDate).format("DD/MM/YYYY");
        resData.suscripcion = true;
        this.originalRows = [...this.originalRows, resData];
        this.filterDatatable();
        this.cdr.detectChanges();

        for(var i = 0; i < this.servicios.length; i++){
          this.servicios[i].val = false;
          this.servicios[i].comentarios = null;
          this.servicios[i].importeSalida = null;
          this.servicios[i].error = {importeSalida: false};
        }

        this.loading = false;
        this.modalService.dismissAll();
        this.confirmationEditSwal.fire();
      }
      self.io.socket.post('/licitacion', licit, completado.bind(this));
    }
    
    //GUARDAR
    if(this.traslado) {
      var licit = {
        referenciaCliente: this.referenciaCliente || "",
        startDate: this.fechaInicio,
        endDate: this.fechaFin,
        abierta: true,
        pujas: this.tipo,
        importeSalida: this.importeTraslado || 0,
        poblacionOrigen: this.poblacionOrigen,
        provinciaOrigen: this.provinciaOrigen,
        paisOrigen: this.paisOrigen,
        comentarios: this.comentariosTraslado || "",
        poblacionDestino: this.poblacionDestino,
        provinciaDestino: this.provinciaDestino,
        paisDestino: this.paisDestino,
        km: this.km,
        tipoTraslado: this.tipoTraslado
      }
      var completado = function (resData){
        if(this.router.url === '/mislicitaciones') this.vista = 'listado'
        else this.ngZone.run(() => this.router.navigate(['/mislicitaciones'])).then();//this.router.navigate(['/mislicitaciones']);
        this.cdr.detectChanges();
        for(var i = 0; i < this.originalRows.length; i++){
          if(this.originalRows[i].id == resData.id){
            resData.precio = resData.importeSalida;
            resData.estado = "ABIERTA";
            resData.fecha = moment(resData.startDate).format("DD/MM/YYYY");
            resData.fechaFin = moment(resData.endDate).format("DD/MM/YYYY HH:mm");
            resData.suscripcion = true;
            this.originalRows[i] = resData;

            this.cdr.detectChanges();
            this.filterDatatable();
            break;
          }
        }

        this.loading = false;
        this.modalService.dismissAll();
        this.confirmationEditSwal.fire();
        this.cdr.detectChanges();
      }
      self.io.socket.patch('/licitacion/' + this.row.id, licit, completado.bind(this));

    } else {
      
      var total = 0;
      var servicios = [];
      for(var i = 0; i < this.servicios.length; i++){
        if(this.servicios[i].val) {
          total += this.servicios[i].importeSalida;
          delete this.servicios[i].val;
          delete this.servicios[i].error;
          servicios.push(this.servicios[i]);
        }
      }
      var poblacion = this.poblacionOrigen;
      var provincia = this.provinciaOrigen;
      var pais = this.paisOrigen;

      var licit2 = {
        referenciaCliente: this.referenciaCliente || "",
        startDate: this.fechaInicio,
        endDate: this.fechaFin,
        abierta: true,
        pujas: this.tipo,
        importeSalida: total || 0,
        poblacionOrigen: poblacion,
        provinciaOrigen: provincia,
        paisOrigen: pais,
        comentarios: "",
        poblacionDestino: "",
        provinciaDestino: null,
        paisDestino: null,
        km: 0,
        tipoTraslado: "",
        servicios: servicios,
        tipo: "SERVICIO"
      }
      var completado = function (resData){
        if(this.router.url === '/mislicitaciones') this.vista = 'listado'
        else this.ngZone.run(() => this.router.navigate(['/mislicitaciones'])).then();//this.router.navigate(['/mislicitaciones']);
        this.cdr.detectChanges();
        for(var i = 0; i < this.originalRows.length; i++){
          if(this.originalRows[i].id == resData.id){

            resData.precio = resData.importeSalida;
            resData.estado = "ABIERTA";
            resData.fecha = moment(resData.startDate).format("DD/MM/YYYY");
            resData.fechaFin = moment(resData.endDate).format("DD/MM/YYYY HH:mm");
            resData.suscripcion = true;
            this.originalRows[i] = resData;

            this.cdr.detectChanges();
            this.filterDatatable();
            break;
          }
        }

        this.loading = false;
        this.modalService.dismissAll();
        this.confirmationEditSwal.fire();
        this.cdr.detectChanges();
      }
      self.io.socket.patch('/licitacion/' + this.row.id, licit2, completado.bind(this));
    }    
  }

  cambiarImporte(content) {
    this.editar(content);
    this.cambiarPaginaEdit(2);
  }

  /*** NUEVO DISEÑO ***/

  detalleServicio(serv, licit) {

    if(serv) {  //SERVICIO
      licit.detalleServicio = {nombre: serv.nombre, abreviatura: serv.abreviatura, detalle: this.serviciosHash[serv.id].detalle, comentarios: serv.comentarios, tipo: serv.tipo, detalleOtros: serv.detalleOtros};
    } else {  //TRASLADO
      licit.detalleServicio = {nombre: "Traslado", detalle: "Ut ut quidenis id quia voluptatur maiorem quaspel ecerspe rspelique verum venes que doluptius, to cum ea num exeri deroribus, optation porum comnihicipsa velis quistrum rerrovit as volo et oditiunt eumquam ut voluptas ipsam eaquiamet fuga. Eruntorio et aliquibus ist doluptam, sae viti solore volum ut lit landent into odicid et etur.", comentarios: licit.comentarios};
    }

    console.log(licit.detalleServicio);

    licit.state = "flipped";
    this.cdr.detectChanges();

    setTimeout(() => { 
      licit.mostrarDetalleServicio = true;
      this.cdr.detectChanges();
    }, 100);

    
  }

}
