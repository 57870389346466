import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
 
@Injectable()
export class InstalacionService {
    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<any[]>(`${environment.apiUrl}/IndiceInstalaciones`);
    }

    getAllAsync() {
        return this.http.get<any[]>(`${environment.apiUrl}/IndiceInstalaciones`).toPromise();
    }
 
    getByIdAsync(id: any) {
        return this.http.get(`${environment.apiUrl}/IndiceInstalaciones/${id}`).toPromise();
    }

    getEsquema(etapa: any, db: any) {
        return this.http.get<any[]>(`${environment.apiUrl}/esquema?db=${db}&etapa=${etapa}`);
    }

    getTagsLast(tabla: any, db: any) {
        return this.http.get<any[]>(`${environment.apiUrl}/tags/last?db=${db}&tabla=${tabla}`);
    }

    getTags(tabla: any, db: any, inicio: any, fin: any, columnas: any) {
        return this.http.get<any[]>(`${environment.apiUrl}/tags?db=${db}&tabla=${tabla}&inicio=${inicio}&fin=${fin}&columnas=${columnas}`);
    }

    getAlarmas(db: any, alarma: any) {
        return this.http.get<any[]>(`${environment.apiUrl}/alarmas?db=${db}&alarma=${alarma}`);
    }

    getFiles(instalacion: any) {
        return this.http.get<any[]>(`${environment.apiUrl}/Ficheros?InstalacionId=${instalacion}`);
    }

    getFile(ruta: any) {
        return this.http.get<any[]>(`${environment.apiUrl}/file?ruta=${ruta}`);
    }

    /*getAllPublished(tipo: string) {
        return this.http.get<Challenge[]>(this.api + '/api/challenges?published=1&type=' + tipo);
    }

    getAllPublished1() {
        return this.http.get<Challenge[]>(this.api + '/api/challenges?published=1');
    }
 
    getById(id: number): Observable<any> {
        return this.http.get(this.api + '/api/challenges/' + id);
    }

    getByCreator(id: number): Observable<any> {
        return this.http.get(this.api + '/api/challenges?creator=' + id);
    }

    getByCreatorOtherPublic(id: number): Observable<any> {
        return this.http.get(this.api + '/api/challenges?creator=' + id + '&type=public&published=1');
    }

    getByCreatorOtherOfficial(id: number): Observable<any> {
        return this.http.get(this.api + '/api/challenges?creator=' + id + '&type=official&published=1');
    }
 
    post(challenge: Challenge): Observable<any> {
        return this.http.post(this.api + '/api/challenges', challenge);
    }
 
    update(challenge: Challenge) {
        return this.http.put(this.api + '/api/challenges/' + challenge.id, challenge);
    }
 
    delete(id: number) {
        return this.http.delete(this.api + '/api/challenges/' + id);
    }*/
}