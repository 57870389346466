import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { first } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import { UserService, AuthenticationService, PaymentService } from '../../../_services';

declare var Stripe: any;

@Component({
  selector: 'kt-validar',
  templateUrl: './validar.component.html',
  styleUrls: ['./validar.component.scss']
})
export class ValidarComponent implements OnInit {

  //stripe;

  constructor(private route: ActivatedRoute,
    private paymentService: PaymentService,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private router: Router,
    private cdr: ChangeDetectorRef) { 
    }

  hash = null;
  email = null;
  validada = false;
  loading = true;
  errorHash = false;
  currentUser = null;
  pago = false;
    

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.hash = params["hash"];
      this.email = params["email"];

      this.currentUser = this.authenticationService.currentUserValue;
      var token = this.currentUser.token;

      this.userService.getById(this.currentUser.id)
      .subscribe(user => {
        this.currentUser = user;
        this.currentUser.token = token;
        console.warn(this.currentUser);
        this.cdr.detectChanges();
        this.authenticationService.changeUser(this.currentUser);

        if(this.currentUser.email == this.email && this.currentUser.hashUser == this.hash) {
          this.loadValidacion();
        } else if(this.currentUser.email != this.email){
          localStorage.removeItem('currentUser');
          this.authenticationService.changeUser(null);
          this.router.navigate(['/auth/login'], { queryParams: { returnUrl: this.router.url } });
        } else if(this.currentUser.status == "ACTIVO"){
          this.loading = false;
          this.validada = true;
        } else {
          this.errorHash = true;
        }
      });

    })

    
  }

  loadValidacion = function() {
    this.paymentService.createCustomer(this.currentUser.email, this.currentUser.empresa, this.currentUser.telefono)
    .then(function(result) {
      this.currentUser.stripeCustomer = result.customerId;
      var updateObj = {id: this.currentUser.id, stripeCustomer: this.currentUser.stripeCustomer, status: "ACTIVO", hashUser: null};
      this.userService.update(updateObj)
      .pipe(first())
      .subscribe(
        data => {
          console.log("OK", data);
          this.currentUser.status = data.status;
          this.currentUser.hashUser = data.hashUser;
          this.authenticationService.changeUser(this.currentUser);

          this.loading = false;
          this.validada = true;
          if(this.currentUser.actividad == "Funerarias") {
            this.pago = true;
          }
          this.cdr.detectChanges();
        },
        error => {
          console.error(error);
          this.loading = false;
        });
    }.bind(this));

		
  }.bind(this);

  priceId = "price_1HrATdHnipIYubl16YaXkwoH";

}
