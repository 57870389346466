import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import {AuthenticationService, MenuService, UserService } from '../../../_services';
import{ GlobalVariables } from '../../../_common/global-variables';

@Component({
  selector: 'kt-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  currentUser: any;
  pagosPendientes = false;
  txt = {};

  constructor(private authenticationService: AuthenticationService,
    private userService: UserService,
    private menuService: MenuService,
    private cdr: ChangeDetectorRef) { 
      this.txt = GlobalVariables.textos;
      if(Object.keys(this.txt).length == 0) {
        setTimeout(function() {
          this.txt = GlobalVariables.textos;
          this.cdr.detectChanges();
          if(Object.keys(this.txt).length == 0) {
            setTimeout(function() {
            this.txt = GlobalVariables.textos;
            this.cdr.detectChanges();
            }.bind(this), 2000);
          }
          }.bind(this), 3000);
      }   
    }

  ngOnInit() {
    this.currentUser = this.authenticationService.currentUserValue;
    var token = this.currentUser.token;

    this.menuService.changeMenu(null);

    this.userService.getById(this.currentUser.IdUsuario)
    .subscribe(user => {
      this.currentUser = user;
      this.currentUser.token = token;
      this.authenticationService.changeUser(this.currentUser);
      this.cdr.detectChanges();
    });

  }
}
