import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

import { AuthenticationService } from './authentication.service';
 
@Injectable()
export class PaymentService {
    constructor(private http: HttpClient,
        private authenticationService: AuthenticationService) { }

    createCheckoutSession(priceId: any, customerId: any) {
        let currentUser = this.authenticationService.currentUserValue;
        return fetch(`${environment.apiUrl}/create-checkout-session`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${currentUser.token}`
            },
            body: JSON.stringify({
                priceId: priceId,
                customer: customerId
            })
        }).then(function(result) {
            return result.json();
        });
    }

    createCustomer(email: any, name: any, phone: any) {
        let currentUser = this.authenticationService.currentUserValue;
        return fetch(`${environment.apiUrl}/createCustomer`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${currentUser.token}`
            },
            body: JSON.stringify({
                email: email,
                name: name,
                phone: phone
            })
        }).then(function(result) {
            return result.json();
        });
    }

    getSession(session: any) {
        return this.http.get<any>(`${environment.apiUrl}/session?session=${session}`);
    }

    getSubscription(subscription: any) {
        return this.http.get<any>(`${environment.apiUrl}/subscription?subscription=${subscription}`);
    }

    updateSubscription(suscrip: any) {
        return this.http.patch<any>(`${environment.apiUrl}/subscription`, suscrip);
    }

    getPaymentMethod(payment: any) {
        return this.http.get<any>(`${environment.apiUrl}/paymentmethod?payment=${payment}`);
    }

    getInvoices(customerId: any) {
        return this.http.get<any>(`${environment.apiUrl}/invoices?customerId=${customerId}`);
    }

    //Cambio metodo pago
    createSetupCheckoutSession(customerId: any, subscriptionId: any) {
        let currentUser = this.authenticationService.currentUserValue;
        return fetch(`${environment.apiUrl}/create-setup-checkout-session`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${currentUser.token}`
            },
            body: JSON.stringify({
                subscription: subscriptionId,
                customer: customerId
            })
        }).then(function(result) {
            return result.json();
        });
    }

    setPaymentMethod(change: any) {
        return this.http.patch<any>(`${environment.apiUrl}/paymentmethod`, change);
    }

    //Cambio de tarifa
    getProrrationPreview(subscription: any, price: any, customer: any) {
        return this.http.get<any>(`${environment.apiUrl}/prorrationpreview?subscriptionId=${subscription}&priceId=${price}&customerId=${customer}`);
    }
}