// Spain
export const locale = {
	lang: 'es',
	data: {
		TRANSLATOR: {
			SELECT: 'Elige tu idioma',
		},
		MENU: {
			NEW: 'nuevo',
			ACTIONS: 'Comportamiento',
			CREATE_POST: 'Crear nueva publicación',
			PAGES: 'Pages',
			FEATURES: 'Caracteristicas',
			APPS: 'Aplicaciones',
			DASHBOARD: 'Tablero',

			START: 'Formulario inicial',
			HOME: 'Inicio'
		},
		BREADCRUMBS: {
			START: {
				TITLE: 'Formulario inicial',
				DESC: 'Algunos datos más sobre tu participación en Inspira STEAM'
			},
			HOME: {
				TITLE: "Inicio",
				DESC: "Bienvenid@ a la Intranet de Inspira STEAM"
			}
		},
		AUTH: {
			GENERAL: {
				OR: 'O',
				SUBMIT_BUTTON: 'Enviar',
				SIGNIN_BUTTON: 'Inicia Sesión',
				YES_ACCOUNT: '¿Ya tienes una cuenta?',
				NO_ACCOUNT: '¿Aún no tienes una cuenta?',
				SIGNUP_BUTTON: 'Regístrate',
				FORGOT_BUTTON: '¿Olvidaste tu contraseña?',
				BACK_BUTTON: 'Volver',
				PRIVACY: 'Privacidad',
				LEGAL: 'Legal',
				CONTACT: 'Contacto',
			},
			LOGIN: {
				TITLE: 'Iniciar sesión',
				BUTTON: 'Iniciar Sesión',
			},
			FORGOT: {
				TITLE: '¿Olvidaste tu contraseña?',
				DESC: 'Ingrese su correo electrónico para restablecer su contraseña',
				SUCCESS: 'Tu cuenta ha sido restablecida correctamente.'
			},
			REGISTER: {
				TITLE: 'Registro',
				DESC: 'Introduce tus detalles para crear la cuenta',
				SUCCESS: 'Tu cuenta ha sido creada correctamente.'
			},
			INPUT: {
				EMAIL: 'Email',
				EMPRESA: 'Nombre de la empresa/colectivo',
				CIF: 'CIF',
				LICENCIA: 'Nº de licencia',
				DOMICILIO: 'Domicilio',
				POBLACION: 'Población',
				CP: 'Código Postal',
				PAIS: 'País',
				TELEFONO: 'Teléfono',
				PASSWORD: 'Contraseña',
				CONFIRM_PASSWORD: 'Confirmar Contraseña',
				ACEPTO: 'Acepto los',
				LOPD: 'Términos y Condiciones'
			},
			VALIDATION: {
				INVALID: '{{name}} no es válido',
				REQUIRED: '{{name}} debe ser introducido',
				MIN_LENGTH: '{{name}} minimum length is {{min}}',
				AGREEMENT_REQUIRED: 'Es necesario aceptar los términos y condiciones',
				NOT_FOUND: 'The requested {{name}} is not found',
				INVALID_LOGIN: 'Los datos introducidos no son correctos. Inténtelo de nuevo.',
				REQUIRED_FIELD: 'Debes rellenar este campo',
				MIN_LENGTH_FIELD: 'El tamaño mínimo de este campo es:',
				MAX_LENGTH_FIELD: 'El tamaño máximo de este campo es:',
				INVALID_FIELD: 'Este campo no es válido',
				MATCH: 'La contraseña y su confirmación no coinciden',
				INVALID_REGISTER: 'El email introducido ya se encuentra registrado. Inténtalo de nuevo o inicia sesión.'
			}
		},
		ECOMMERCE: {
			COMMON: {
				SELECTED_RECORDS_COUNT: 'Selected records count: ',
				ALL: 'All',
				SUSPENDED: 'Suspended',
				ACTIVE: 'Active',
				FILTER: 'Filter',
				BY_STATUS: 'by Status',
				BY_TYPE: 'by Type',
				BUSINESS: 'Business',
				INDIVIDUAL: 'Individual',
				SEARCH: 'Search',
				IN_ALL_FIELDS: 'in all fields'
			},
			ECOMMERCE: 'eCommerce',
			CUSTOMERS: {
				CUSTOMERS: 'Customers',
				CUSTOMERS_LIST: 'Customers list',
				NEW_CUSTOMER: 'New Customer',
				DELETE_CUSTOMER_SIMPLE: {
					TITLE: 'Customer Delete',
					DESCRIPTION: 'Are you sure to permanently delete this customer?',
					WAIT_DESCRIPTION: 'Customer is deleting...',
					MESSAGE: 'Customer has been deleted'
				},
				DELETE_CUSTOMER_MULTY: {
					TITLE: 'Customers Delete',
					DESCRIPTION: 'Are you sure to permanently delete selected customers?',
					WAIT_DESCRIPTION: 'Customers are deleting...',
					MESSAGE: 'Selected customers have been deleted'
				},
				UPDATE_STATUS: {
					TITLE: 'Status has been updated for selected customers',
					MESSAGE: 'Selected customers status have successfully been updated'
				},
				EDIT: {
					UPDATE_MESSAGE: 'Customer has been updated',
					ADD_MESSAGE: 'Customer has been created'
				}
			}
		},
		TOPBAR: {
			SALUDO: 'Hola'
		},
		INPUT: {
			VALIDATION: {
				INVALID: '{{name}} no es válido',
				REQUIRED: '{{name}} debe ser introducido',
				MIN_LENGTH: '{{name}} minimum length is {{min}}',
				AGREEMENT_REQUIRED: 'Es necesario aceptar los términos y condiciones',
				NOT_FOUND: 'The requested {{name}} is not found',
				INVALID_LOGIN: 'Los datos introducidos no son correctos. Inténtelo de nuevo.',
				REQUIRED_FIELD: 'Debes rellenar este campo',
				MIN_LENGTH_FIELD: 'El tamaño mínimo de este campo es:',
				MAX_LENGTH_FIELD: 'El tamaño máximo de este campo es:',
				INVALID_FIELD: 'Este campo no es válido',
				MATCH: 'La contraseña y su confirmación no coinciden',
				INVALID_REGISTER: 'El email introducido ya se encuentra registrado. Inténtalo de nuevo o inicia sesión.'
			}
		}
	}
};
