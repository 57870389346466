import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
 
@Injectable()
export class TrazabilidadService {
    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<any[]>(`${environment.apiUrl}/trazabilidad`);
    }

    getById(id: number) {
        return this.http.get(`${environment.apiUrl}/trazabilidad/${id}`);
    }

    /*getAllPublished(tipo: string) {
        return this.http.get<Challenge[]>(this.api + '/api/challenges?published=1&type=' + tipo);
    }

    getAllPublished1() {
        return this.http.get<Challenge[]>(this.api + '/api/challenges?published=1');
    }
 
    getById(id: number): Observable<any> {
        return this.http.get(this.api + '/api/challenges/' + id);
    }

    getByCreator(id: number): Observable<any> {
        return this.http.get(this.api + '/api/challenges?creator=' + id);
    }

    getByCreatorOtherPublic(id: number): Observable<any> {
        return this.http.get(this.api + '/api/challenges?creator=' + id + '&type=public&published=1');
    }

    getByCreatorOtherOfficial(id: number): Observable<any> {
        return this.http.get(this.api + '/api/challenges?creator=' + id + '&type=official&published=1');
    }
 
    post(challenge: Challenge): Observable<any> {
        return this.http.post(this.api + '/api/challenges', challenge);
    }
 
    update(challenge: Challenge) {
        return this.http.put(this.api + '/api/challenges/' + challenge.id, challenge);
    }
 
    delete(id: number) {
        return this.http.delete(this.api + '/api/challenges/' + id);
    }*/
}