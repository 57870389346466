
import { Component, Input, OnInit, ViewChild, ViewChildren, ElementRef, ChangeDetectorRef, NgZone, HostListener } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { first } from 'rxjs/operators';

import { columnsTotalWidth, NgxDatatableModule } from '@swimlane/ngx-datatable';

import { AuthenticationService, StateService, SeccionService, MenuService, CountryService, LicitaService, UserService, TrazabilidadService, NotificacionService, EtapaService, InstalacionService } from '../../../_services';

import { DateTimeAdapter } from 'ng-pick-datetime';
import { HttpClient } from '@angular/common/http';

import { interval, Subscription, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

import * as moment from 'moment';
import{ GlobalVariables } from '../../../_common/global-variables';

import ObjectFileSystemProvider from 'devextreme/file_management/object_provider';
import CustomFileSystemProvider from 'devextreme/file_management/custom_provider';

@Component({
  selector: 'kt-manuales',
  templateUrl: './manuales.component.html',
  styleUrls: ['./manuales.component.scss']
})
export class ManualesComponent implements OnInit {

  objectFileProvider: ObjectFileSystemProvider;
  customFileProvider: CustomFileSystemProvider;
  fileItems = [];
  currentUser = null;
  instalacion = null;
  loading = true;
  txt = {};

  constructor(
    private instalacionService: InstalacionService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
  ) { 

    this.txt = GlobalVariables.textos;
    if(Object.keys(this.txt).length == 0) {
      setTimeout(function() {
        this.txt = GlobalVariables.textos;
        this.cdr.detectChanges();
        if(Object.keys(this.txt).length == 0) {
          setTimeout(function() {
          this.txt = GlobalVariables.textos;
          this.cdr.detectChanges();
          }.bind(this), 2000);
        }
      }.bind(this), 3000);
    }   
  }

  ngOnInit() {
    this.currentUser = this.authenticationService.currentUserValue;

    this.route.paramMap.subscribe(async params => {
      var instalacionId = params.get("id");
      this.instalacion = await this.instalacionService.getByIdAsync(instalacionId);
      this.loadFicheros();
      /*for(let inst of this.currentUser.instalaciones){
        if(inst.ID == instalacionId) {
          this.instalacion = inst;
          this.loadFicheros();
          break;
        }
      }*/
    })
    this.cdr.detectChanges();
  } 

  loadFicheros() {
    this.instalacionService.getFiles(this.instalacion.ID)
    .subscribe(files => {

      var estructura = {carpetas: {}, files: []};
      var pendientes = [];

      var loadPendientes = function () {
        procesarFiles();
      }.bind(this, estructura, pendientes)






      var procesarFiles = function() {
        for(let item of estructura.files) {
          this.fileItems.push(item);
        }
  
        for(let item in estructura.carpetas) {
          this.fileItems.push(estructura.carpetas[item]);
        }
  
        this.objectFileProvider = new ObjectFileSystemProvider({
          data: this.fileItems
        });
    
        this.objectFileProvider.downloadItems = function(pathInfo) {
          this.instalacionService.getFile(pathInfo[0].dataItem.ruta)
          .subscribe(file => {
            var a = document.createElement("a");
            a.href = file.file;
            a.download = pathInfo[0].name;
            a.click();
          });
        }.bind(this);
        this.cdr.detectChanges();
      }.bind(this, estructura, pendientes)


      var checkRecursivo = function(rutaActual, obj) {
        for(let file of files) {
          if(file.Ruta == rutaActual) {
            if(file.Carpeta) {
              var item = {
                name: file.Nombre,
                isDirectory: true,
                ruta: file.Ruta,
                items: []
              }
              obj.items.push(item);
              checkRecursivo(file.Ruta + file.Nombre + "/", item);
            } else {
              obj.items.push({
                name: file.Nombre,
                isDirectory: false,
                ruta: this.instalacion.ID + "/" + file.Ruta + "/" + file.Nombre
              });
            }




          }
        }
      }.bind(this)







      for(let file of files) {
        if(file.Ruta == "" && !file.Carpeta) {
          estructura.files.push({
            name: file.Nombre,
            isDirectory: false,
            ruta: this.instalacion.ID + "/" + file.Ruta + "/" + file.Nombre
          });
        } else if(file.Carpeta) {
          if(file.Ruta == "") {
            estructura.carpetas[file.Nombre] = {
              name: file.Nombre,
              isDirectory: true,
              ruta: file.Ruta,
              items: []
            };
            checkRecursivo(file.Ruta + file.Nombre + "/", estructura.carpetas[file.Nombre]);
          }
        } else {
          /*if(!estructura.carpetas[file.Ruta]) {
            estructura.carpetas[file.Ruta] = {
              name: file.Ruta,
              isDirectory: true,
              items: []
            };
          }*/
          if(estructura.carpetas[file.Ruta]) {
            /*estructura.carpetas[file.Ruta].items.push({
              name: file.Nombre,
              isDirectory: false,
              ruta: this.instalacion.ID + "/" + file.Ruta + "/" + file.Nombre
            });*/
          } else {
            //pendientes.push(file);
          }
        }
      }
      //if(pendientes.length > 0) loadPendientes();
      procesarFiles();

      
    });
  }

  customizeIcon(fileSystemItem) {
    if(fileSystemItem.isDirectory)
        return "https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/thumbnails/folder.svg";

    const fileExtension = fileSystemItem.getFileExtension();
    switch(fileExtension) {
        case ".txt":
            return "https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/thumbnails/doc-txt.svg";
        case ".rtf":
            return "https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/thumbnails/doc-rtf.svg";
        case ".xml":
            return "https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/thumbnails/doc-xml.svg";
    }
  }

  onSelectedFileOpened(event) {
    this.instalacionService.getFile(event.file.dataItem.ruta)
    .subscribe(file => {
      //console.warn(event);

      var element = document.createElement('a');
      element.setAttribute('href', file["file"]);
      element.setAttribute('download', "documento.pdf");

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);

      return;
      var win = window.open("_blank");
      win.document.write('<iframe src="' + file["file"]  + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
    });
  }

}